import { Injectable } from '@angular/core';
import { GLOBAL_CONSTANTS as CONST } from '@app/core/constants/global.constants';

@Injectable({
  providedIn: 'root',
})
export class CookiesService {
  public isConsented = false;

  constructor() {}

  public getCookie(name: string) {
    let allCookies: Array<string> = document.cookie.split(';');
    let allCookiesLength: number = allCookies.length;
    let cookieName = `${name}=`;
    let cookie: string;

    for (let i: number = 0; i < allCookiesLength; i += 1) {
      cookie = allCookies[i].replace(/^\s+/g, '');
      if (cookie.indexOf(cookieName) == 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return undefined;
  }

  public deleteCookie(name: string) {
    this.setCookie(name, '', -1);
  }

  public setCookie(
    name: string,
    value: string,
    expireDays: number,
    path: string = ''
  ) {
    const actualDate: Date = new Date();
    actualDate.setTime(actualDate.getTime() + expireDays * 24 * 60 * 60 * 1000);
    let expires: string = 'expires=' + actualDate.toUTCString();
    document.cookie = `${name}=${value}; ${expires}${
      path.length > 0 ? `; path=${path}` : ''
    }`;
  }

  public consent(
    isConsent: boolean,
    e: any,
    cookieName: string,
    expireDays: number
  ): boolean | undefined {
    if (!isConsent) return this.isConsented;

    if (isConsent) {
      this.setCookie(cookieName, '1', expireDays);
      this.isConsented = true;
      e.preventDefault();
    }
    return undefined;
  }

  public setConsentCookie(value: string) {
    const COOKIE_CONSENT_NAME: string = CONST.PROP_COOKIE_CONSENT;
    this.setCookie(COOKIE_CONSENT_NAME, value, 360);
  }

  public getCookieConsent() {
    const COOKIE_CONSENT_NAME: string = CONST.PROP_COOKIE_CONSENT;
    const value: string | undefined = this.getCookie(COOKIE_CONSENT_NAME);
    const exists: boolean = value ? true : false;

    return {
      exists,
      value,
    };
  }
}
