import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AUTH_CONSTANTS } from '@app/core/constants/auth.constants';
import { OnboardingFacade } from '@app/facades/onboarding.facade';
import { BUTTON_TEMPLATE, SIZES } from '@app/presentation/mo-forms/enums/fields.type';
import { IModalParams } from '@app/presentation/mo-layout/components/modal/modal-config.interface';
import { Modal } from '@app/presentation/mo-layout/components/modal/modal.base';

@Component({
  selector: 'app-authorization-credit-report',
  templateUrl: './authorization-credit-report.component.html',
  styleUrls: ['./authorization-credit-report.component.scss']
})
export class AuthorizationCreditReportComponent extends Modal {

  public BUTTON_TEMPLATES = BUTTON_TEMPLATE;
  public BUTTON_SIZE = SIZES;
  public override title: string;
  public override message: string;
  

  constructor(
    public override modalRef: MatDialogRef<AuthorizationCreditReportComponent>,
    @Inject(MAT_DIALOG_DATA) public override data: IModalParams,
    private _onboardingFacade: OnboardingFacade
    ) {
      super(modalRef, data);
    this.title = AUTH_CONSTANTS.TITLE_MODAL_CREDIT_REPORT;
    this.message = AUTH_CONSTANTS.MESSAGE_MODAL_CREDIT_REPORT ;
  }

  public override onOk(): void {
    this._onboardingFacade.acceptAndContinueCreditReport();
  }
}
