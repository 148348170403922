import { Injectable } from '@angular/core';

function gtag(type: string, value: any, details?: any) {
  (window as any).dataLayer.push(arguments);
}
declare let ga: Function;
@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  sendEvent(
    category: string,
    action: string,
    label?: string,
    value?: number
  ) {
    gtag('event', action, {
      'event_category': category,
      'event_label': label,
      'value': value
    });
  }
}
