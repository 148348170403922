import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moFilter',
})
export class MOFilterPipe implements PipeTransform {
  transform(value: any, arg: any): any {
    if (arg.length < 2) return value;
    const resultFilter = [];
    for (const faq of value) {
      if (faq.title.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
        resultFilter.push(faq);
      }
    }
    return resultFilter;
  }
}
