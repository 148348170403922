<div class="box-content-shadow rejected-container">
  <div class="message-container">
    <h3 class="title">¡Lo sentimos!</h3>
    <p>Tu solicitud de crédito no fue aprobada por alguna de las siguientes razones:</p>
    <ul>
      <li><p>Tienes un crédito activo con Pro Mujer.</p></li>
      <li><p>No cumples con los requisitos solicitados.</p></li>
      <li><p>Tienes algún problema con tu historial crediticio.</p></li>
    </ul>
    <p>
      No te preocupes, ingresa a <span class="title">"Quiero mejorar mi score"</span> donde tenemos
      información que te podrá ayudar a mejorar tu puntaje y acceder a un crédito con nosotros.
    </p>
  </div>

  <div class="mo-button-container">
    <mo-button text="Quiero mejorar mi score" [isLoading]="isLoading" type="link" [href]="urlTips" (click)="onRedirectTips()">
    </mo-button>
  </div>
  <p>
    Esperamos verte de nuevo,<br />
    <b>Equipo Pro Mujer.</b>
  </p>
  <p>
    Si tienes dudas escríbenos al WhatsApp

    <a class="phone-number" [href]="urlWhatsapp" target="_blank" rel="noreferrer noopener">
      {{ whatsAppNumber }}.
    </a>
  </p>
</div>
