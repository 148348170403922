<section class="message-container box-content-shadow">
    <layout-message-status
      title="¡Gracias por confiar en nosotros!"
      message="Durante las próximas 24 horas te contactáremos para validar tu identidad y explicarte sobre el crédito que estás próximo a recibir. 
      <br>
      <br>
      <p class='text-pending-call' >
        Mantente al pendiente de nuestra llamada.
      </p>"
    ></layout-message-status>
    <mo-button text="Cerrar sesión" (click)="onFinish()"></mo-button>
  </section>
  