export const CATEGORIES_EVENT = {
    NEW_USER: 'Registro' ,
    LOGIN: 'Iniciar Sesión' ,
    REQUEST_LOAN: 'SolicitudAceptada' ,
    REJECTED_LOAN: 'SolicitudRechazada' ,
    HELP: 'Ayuda' ,
    APP: 'EventoGeneral' ,
    FORGOT_PASSWORD: 'RestablecerContraseña',
    PAYMENT: 'Pagar'
}

export const ACTION_EVENT = {
    CLICK: 'clic',
    REDIRECT: 'redirección',
    VISIT: 'visita'
}