import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentAPI } from '@data/mo-api/payment.api';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  switchMap,
  map,
  catchError,
  of,
  withLatestFrom,
  delay,
  tap,
} from 'rxjs';
import { GLOBAL_CONSTANTS } from '@app/core/constants/global.constants';
import {
  CATEGORIES_EVENT,
  ACTION_EVENT,
} from '@app/core/constants/analytics.constants';
import { Store } from '@ngrx/store';
import { PAYMENT_STATUS, PAYMENT_STEP } from '@app/core/models/payment.model';
import { UserService } from '@app/data/mo-api/user.service';

import * as paymentActions from './payment.actions';
import * as paymentlectors from './payment.selectors';
import * as authSelectors from './../auth/auth.selectors';
import * as authActions from '../auth/auth.actions';
import * as loanSelectors from '@store/loan/loan.selectors';
import { GoogleAnalyticsService } from '@app/domain/services/google-analytics.service';
import {
  BAR_CODE_INFO_OXXO,
  PAYMENT_TRANSACTION_TYPE,
} from '@app/core/constants/payment.constants';
import { UIService } from '@app/core/services/ui.service';
import { MODAL_SIZE } from '@app/presentation/mo-layout/components/modal/modal-content.type';
import { LoanFacade } from '@app/facades/loan.facade';
import { ILoanLegalUrlDocuments } from '@app/core/models/loan.model';
import { Url } from '../../../core/models/loan.model';
import { LoanAPI } from '@app/data/mo-api/loan.api';
import { getCurrentLoan } from '../loan/loan.actions';
import { FacebookPixelService } from '@app/domain/services/facebook-pixel.service';
@Injectable()
export class PaymentEffects {
  constructor(
    private _actions$: Actions,
    private _router: Router,
    private _api: PaymentAPI,
    private _loanApi: LoanAPI,
    private _store: Store,
    private _userApi: UserService,
    private _gaService: GoogleAnalyticsService,
    private _uiService: UIService,
    private _loanFacade: LoanFacade,
    private _facebookPixelService: FacebookPixelService
  ) {}

  public requestCreatePayment$ = createEffect(() =>
    this._actions$.pipe(
      ofType(paymentActions.requestCreatePayment),
      switchMap(({ payment }) => {
        const { transactionType } = payment;
        return transactionType === PAYMENT_TRANSACTION_TYPE.ACH
          ? this._api.sentDataToCreatePayment(payment).pipe(
              delay(30000),
              map((wireTransfer) => {
                return paymentActions.successRequestCreatePayment({
                  wireTransfer,
                });
              }),
              catchError(() => of(paymentActions.failureRequestCreatePayment()))
            )
          : of(paymentActions.showBarcode());
      })
    )
  );

  public successRequestCreatePayment$ = createEffect(() =>
    this._actions$.pipe(
      ofType(paymentActions.successRequestCreatePayment),
      withLatestFrom(this._store.select(paymentlectors.methodPayment)),
      map((_) => {
        return paymentActions.setStep({ step: PAYMENT_STEP.STP_INFORMATION });
      })
    )
  );

  public showBarcode$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(paymentActions.showBarcode),
        tap(() => {
          this._uiService.showDialog({
            type: 'barcode',
            title: 'Código de barras',
            message:
              '<br>Presenta este código de barras en tu OXXO más cercano y realiza el pago de tu cuota: ',
            size: MODAL_SIZE.MD,
            infoButton: {
              showButtonClose: true,
              textOk: 'Descargar código de barras',
            },
            callbacks: {
              ok: () => {
                this._store.dispatch(paymentActions.downloadBarcode());
              },
            },
          });
        })
      ),
    { dispatch: false }
  );

  public downloanBarcode$ = createEffect(() =>
    this._actions$.pipe(
      ofType(paymentActions.downloadBarcode),
      withLatestFrom(this._store.select(loanSelectors.selectCurrentLoan)),
      map(([_, loan]) => {
        const url: string | any = loan?.urls[1].url;
        this._uiService.downloadBarcode(url);
        return paymentActions.successDownloadBarcode(url);
      })
    )
  );

  public goToPayment$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(paymentActions.goToPayment),
        map(() => {
          this._gaService.sendEvent(
            CATEGORIES_EVENT.REQUEST_LOAN,
            ACTION_EVENT.CLICK,
            'Pagar ahora'
          );
          this._facebookPixelService.sendEvent(
            CATEGORIES_EVENT.REQUEST_LOAN,
            ACTION_EVENT.CLICK,
            'Pagar ahora'
          );
          return this._router.navigateByUrl(GLOBAL_CONSTANTS.ROUTES.PAYMENT);
        })
      ),
    { dispatch: false }
  );

  public validateIfUserHAsPayment$ = createEffect(() =>
    this._actions$.pipe(
      ofType(paymentActions.validateHasPayment),
      withLatestFrom(
        this._store.select(authSelectors.selectUser),
        this._store.select(paymentlectors.currentDate)
      ),
      map(([_, user, currentDate]) => {
        if (user?.payment) {
          const exprirationDate = new Date(user?.payment?.expirationDate);

          if (
            (user.payment.status === PAYMENT_STATUS.PENDING ||
              user.payment.status === PAYMENT_STATUS.SENT) &&
            (user.payment.methodPayment === PAYMENT_TRANSACTION_TYPE.ACH ||
              currentDate <= exprirationDate)
          ) {
            return paymentActions.setStep({
              step: PAYMENT_STEP.STP_INFORMATION,
            });
          }
        }
        return paymentActions.hasPayment();
      })
    )
  );

  public getUserInfo$ = createEffect(() =>
    this._actions$.pipe(
      ofType(paymentActions.successRequestCreatePayment),
      switchMap(() =>
        this._userApi.getUserInfo().pipe(
          map((user) => {
            return authActions.responseUserLogin({ user });
          })
        )
      ),
      catchError(() => of(paymentActions.failureRequestCreatePayment()))
    )
  );

  public requestCancelTransaction$ = createEffect(() =>
    this._actions$.pipe(
      ofType(paymentActions.requestCancelTransaction),
      switchMap((_) =>
        this._api.cancelTransaction().pipe(
          map((_) => {
            return paymentActions.successRequestCancelTransaction();
          }),
          catchError(() => of(paymentActions.failureRequestCancelTransaction()))
        )
      )
    )
  );

  public successRequestCancelTransaction$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(paymentActions.successRequestCancelTransaction),
        tap((_) => this._router.navigateByUrl(GLOBAL_CONSTANTS.ROUTES.PAYMENT))
      ),
    {
      dispatch: false,
    }
  );
}
