import { Component, Input } from '@angular/core';
import {
  BUTTON_TEMPLATE,
  BUTTON_TYPE,
  COLOR_TEMPLATE,
  ICON_POSITION,
  SIZES,
} from '../../enums/fields.type';

@Component({
  selector: 'mo-button',
  templateUrl: './mo-button.component.html',
  styleUrls: ['./mo-button.component.scss'],
})
export class MoButtonComponent {
  @Input() text!: string;
  @Input() isLoading?: boolean | null;
  @Input() isDisabled?: boolean | null;
  @Input() icon!: string;
  @Input() iconPosition!: ICON_POSITION;
  @Input() template!: BUTTON_TEMPLATE;
  @Input() size!: SIZES;
  @Input() type: 'button' | 'submit' | 'reset' | 'link' = BUTTON_TYPE.BUTTON;
  @Input() displayBlock!: boolean;
  @Input() href?: string;

  public COLOR_TEMPLATES = COLOR_TEMPLATE;
  public ICON_POSITIONS = ICON_POSITION;

  constructor() {
    this.template = BUTTON_TEMPLATE.PRIMARY;
    this.size = SIZES.LG;
  }
}
