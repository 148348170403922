import { Component, OnInit } from '@angular/core';
import { GLOBAL_CONSTANTS } from '@app/core/constants/global.constants';

@Component({
  selector: 'desktop-screen',
  templateUrl: './desktop.page.html',
  styleUrls: ['./desktop.page.scss'],
})
export class DesktopPage implements OnInit {
  public urlPortal: string;
  constructor() {
    this.urlPortal = GLOBAL_CONSTANTS.URL_PORTAL;
  }

  ngOnInit(): void {}
}
