<div [class]="'mo-modal ' + type">
  <layout-icon
    *ngIf="infoButton.showButtonClose"
    class="icon-close"
    icon="cancel"
    (click)="onClose()"
  >
  </layout-icon>
  <ng-container *ngIf="type === 'text'">
    <h1 [innerHTML]="title" *ngIf="title.length > 0"></h1>
    <p class="description" [innerHTML]="message"></p>
  </ng-container>

  <ng-container *ngIf="type === 'barcode'">
    <ng-container *ngIf="loan$ | async as loan">
      <h1 [innerHTML]="title" *ngIf="title.length > 0"></h1>
      <p class="description" [innerHTML]="message"></p>
      <img class="icon-main-barcode" src="../../../../../app/presentation/assets/icons/logo-oxxo.png" alt="logo-oxxo">
      <img src="{{ !!loan ? loan.urls[0].url : '' }}" alt="barcode image" />
    </ng-container>
  </ng-container>

  <ng-container *ngIf="type === 'loading'">
    <layout-icon class="icon-main" icon="mo-loading-arrow" color="primary"></layout-icon>
    <h1 class="title" *ngIf="title.length > 0" [innerHTML]="title"></h1>
    <h2 class="description" [innerHTML]="message"></h2>
  </ng-container>

  <ng-container *ngIf="type === 'alert'">
    <layout-icon class="icon-main" icon="error_outline" color="primary"></layout-icon>
    <h3 class="title" *ngIf="title.length > 0" [innerHTML]="title"></h3>
    <p class="description" [innerHTML]="message"></p>
  </ng-container>

  <ng-container *ngIf="type === 'success'">
    <layout-icon class="icon-main" icon="image/icon-success.svg"></layout-icon>
    <h3 class="title" *ngIf="title.length > 0" [innerHTML]="title"></h3>
    <p class="description" [innerHTML]="message"></p>
  </ng-container>

  <ng-container *ngIf="type === 'warning'">
    <layout-icon class="icon-main" icon="image/icon-alert.svg"></layout-icon>
    <h3 class="title" *ngIf="title.length > 0" [innerHTML]="title"></h3>
    <p class="description" [innerHTML]="message"></p>
  </ng-container>

  <ng-container *ngIf="type === 'danger'">
    <layout-icon class="icon-main" icon="image/icon-danger.svg"></layout-icon>
    <h3 class="title" *ngIf="title.length > 0" [innerHTML]="title"></h3>
    <p class="description" [innerHTML]="message"></p>
  </ng-container>
  <ng-container *ngIf="type === 'notification-congratulations'">
    <layout-icon class="icon-main" [icon]="image"></layout-icon>
    <h3 class="title" *ngIf="title.length > 0" [innerHTML]="title"></h3>
    <p class="description" [innerHTML]="message"></p>
  </ng-container>
  <div
    class="dialog-actions modal-buttons"
    *ngIf="infoButton.showButtonCancel || infoButton.showButtonOk"
  >
    <mo-button
      *ngIf="infoButton.showButtonCancel"
      (click)="onCancel()"
      [size]="BUTTON_SIZE.LG"
      [template]="BUTTON_TEMPLATES.OUTLINE_PRIMARY"
      [text]="infoButton.textCancel ?? ''"
    >
    </mo-button>
    <mo-button
      *ngIf="infoButton.showButtonOk"
      cdkFocusInitial
      [mat-dialog-close]="true"
      (click)="onOk()"
      [ngClass]="{ 'button-center center': !infoButton.showButtonCancel }"
      [class]="'btn-primary-bg ' + infoButton?.classNameOk"
      [size]="BUTTON_SIZE.LG"
      [template]="BUTTON_TEMPLATES.PRIMARY"
      [text]="infoButton.textOk ?? ''"
    >
    </mo-button>
  </div>
</div>
