import { IRedirectByStep } from '../models/app.model';
import { AUTH_CONSTANTS } from './auth.constants';
import { GLOBAL_CONSTANTS as CONST } from './global.constants';

const STEPS = AUTH_CONSTANTS.ONBOARDING_STEPS;
export const REDIRECT_BY_STEP: IRedirectByStep = {
  REDIRECT_BY_STEP: {
    [STEPS.PERSONAL_INFO]: `${CONST.ROUTES.ONBOARDING}/${CONST.ROUTES.ONBOARDING_PERSONAL_INFO}`,
    [STEPS.ADDRESS]: `${CONST.ROUTES.ONBOARDING}/${CONST.ROUTES.ONBOARDING_ADDRESS}`,
    [STEPS.OTHER_INFO]: `${CONST.ROUTES.ONBOARDING}/${CONST.ROUTES.ONBOARDING_OTHER_INFO}`,
    [STEPS.PEP]: `${CONST.ROUTES.ONBOARDING}/${CONST.ROUTES.ONBOARDING_PEP}`,
    [STEPS.INCOME]: `${CONST.ROUTES.ONBOARDING}/${CONST.ROUTES.ONBOARDING_INCOME}`,
    [STEPS.MATI]: `${CONST.ROUTES.ONBOARDING}/${CONST.ROUTES.ONBOARDING_MATI}`,
  },
};

export const STEP_BY_NAME: { [key: string]: number } = {
  [CONST.ROUTES.ONBOARDING_PERSONAL_INFO]: STEPS.PERSONAL_INFO,
  [CONST.ROUTES.ONBOARDING_ADDRESS]: STEPS.ADDRESS,
  [CONST.ROUTES.ONBOARDING_OTHER_INFO]: STEPS.OTHER_INFO,
  [CONST.ROUTES.ONBOARDING_PEP]: STEPS.PEP,
  [CONST.ROUTES.ONBOARDING_INCOME]: STEPS.INCOME,
  [CONST.ROUTES.ONBOARDING_MATI]: STEPS.MATI,
};
