import { ModalConfig } from '@app/presentation/mo-layout/components/modal/modal-config.interface';
import { createAction, props } from '@ngrx/store';

export const collapseMenu = createAction('[Navbar] Click to collapse sidebar menu');
export const expandMenu = createAction('[Navbar] Click to expand sidebar menu');

export const setModalConfig = createAction(
  '[Modal] Click to open modal',
  props<{ modalConfig: ModalConfig }>()
);
export const closeModal = createAction('[Modal] Click to close modal');

export const goBackToPreviousPage = createAction('[Modal] Go to previous page');

export const chageRoute = createAction(
  '[Router] Change the route (URL)',
  props<{ route: string }>()
);

export const showGoBackButton = createAction(
  '[Router] Show or Hide Back Button',
  props<{ showGoBackButton: boolean }>()
);
export const showMenuIcon = createAction(
  '[Menu] Show or Hide Burger Menu',
  props<{ showMenuIcon: boolean }>()
);
export const isDisabled = createAction('[APP] Disable an Input', props<{ isDisabled: boolean }>());

export const confirmSeenModal = createAction('[Home] Confirm seen modal');
export const confirmSeenModalSuccess = createAction('[Home] Confirm seen modal success');
export const confirmSeenModalError = createAction('[Home] Confirm seen modal error');

export const showPageBlockFlowRegister = createAction('[App] Show Page Block Flow Register');
