import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, switchMap, map, catchError, withLatestFrom, tap, filter, zip, delay } from 'rxjs';
import { Router } from '@angular/router';

import { OnboardingAPI } from '@app/data/mo-api/onboarding.api';
import { REDIRECT_BY_STEP, STEP_BY_NAME } from '@app/core/constants/redirect-by-step.constants';
import { CATEGORIES_EVENT, ACTION_EVENT } from '@app/core/constants/analytics.constants';
import { OnboardingFactory } from '@app/domain/classes/onboarding.class';
import { GoogleAnalyticsService } from '@app/domain/services/google-analytics.service';
import { ONBOARDING_CONSTANTS } from '@app/core/constants/onboarding.constants';
import { OnboardingService } from '@app/domain/services/onboarding.service';
import { AppService } from '@app/domain/services/app.service';
import * as onboardingActions from './onboarding.actions';
import * as onboardingSelectors from './onboarding.selectors';
import * as appActions from './../app/app.actions';
import * as authSelectors from './../auth/auth.selectors';

import { UIService } from '@app/core/services/ui.service';
import { AuthorizationCreditReportComponent } from '@app/presentation/features/onboarding/components/authorization-credit-report/authorization-credit-report.component';
import { GLOBAL_CONSTANTS } from '@app/core/constants/global.constants';
import { USER_STATUS } from '@app/core/models/user.model';
import { UserService } from '@app/data/mo-api/user.service';

@Injectable()
export class OnboardingEffects {
  constructor(
    private actions$: Actions,
    private _store: Store,
    private _api: OnboardingAPI,
    private _router: Router,
    private _service: OnboardingService,
    private _appService: AppService,
    private _gaService: GoogleAnalyticsService,
    private _uiService: UIService,
    private _userApi: UserService,
  ) { }

  public getOnboardingByStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(onboardingActions.getOnboardingInfoByStep),
      filter(({ step }) => step < ONBOARDING_CONSTANTS.STEPS.MATI),
      switchMap(({ step }) => {
        return this._api.getOnboardingByStep(step).pipe(
          map((stepInfo) => {
            const newStepInfo = OnboardingFactory.createOnbordingStep(stepInfo, step);
            return onboardingActions.successGetOnboardingInfoByStep({ stepInfo: newStepInfo });
          }),
          catchError(() => of(onboardingActions.failureGetOnboardingInfoByStep()))
        );
      })
    )
  );

  public setOnboardingByStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(onboardingActions.saveOnboardingInfo),
      withLatestFrom(this._store.select(onboardingSelectors.selectOnboardingInfoStep),
                     this._store.select(onboardingSelectors.selectIsOnOtpView)),
      switchMap(([{ stepInfo }, currentStep, isOnOtpView]) => {
        const nameStep = this._service.getStepName(currentStep);
        this._gaService.sendEvent(CATEGORIES_EVENT.NEW_USER, ACTION_EVENT.CLICK, nameStep)
        return this._api.setOnboardingInfoByStep(currentStep, stepInfo).pipe(
          map((_) => { 
            if (currentStep === ONBOARDING_CONSTANTS.STEPS.INCOME && !isOnOtpView) {
              return onboardingActions.showOtpView();
            }
            const step = currentStep + 1;
            return onboardingActions.successSaveOnboardingInfo({ step: currentStep + 1 });
          }),
          catchError(() => of(onboardingActions.failureSaveOnboardingInfo()))
        )
      }
      )
    )
  );

  public successSetOnboardingByStep$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(onboardingActions.successSaveOnboardingInfo),
        tap(({ step }) => {
          const stepRoute = REDIRECT_BY_STEP.REDIRECT_BY_STEP[step];
          this._router.navigate([stepRoute]);
        })
      ),
    { dispatch: false }
  );

  public validateStateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        onboardingActions.getDataUserAfterStepTwo
      ),
      switchMap(() =>
        this._userApi.getUserInfo().pipe(
          map((user) => {
            if (user?.status === USER_STATUS.CUSTOMER_REJECTED) {
              this._router.navigateByUrl(GLOBAL_CONSTANTS.ROUTES.HOME);
            }
          }))
      ),
    ), {dispatch: false}
  );


  public getUserInformationAfterStepTwo$ = createEffect(
    () => this.actions$.pipe(
      ofType(onboardingActions.successSaveOnboardingInfo),
      filter(({step}) => (step === STEP_BY_NAME[GLOBAL_CONSTANTS.ROUTES.ONBOARDING_OTHER_INFO] || step === STEP_BY_NAME[GLOBAL_CONSTANTS.ROUTES.ONBOARDING_INCOME])),
      map(() => onboardingActions.getDataUserAfterStepTwo())
    )
  );

  public goBackToPreviousPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.goBackToPreviousPage),
        withLatestFrom(this._store.select(onboardingSelectors.selectOnboardingInfoStep)),
        tap(([_, step]) => {
          if (step > 1) {
            const stepRoute = REDIRECT_BY_STEP.REDIRECT_BY_STEP[step - 1];

            this._router.navigateByUrl(stepRoute);
          }
        })
      ),
    { dispatch: false }
  );

  public confirmFinishMati$ = createEffect(() => 
    this.actions$.pipe(
      ofType(onboardingActions.finishIdentityValidationSDK),
      switchMap(() => this._api.notifyMatiFinish())
    ), { dispatch: false }
  )

  public startPollingIdentityValidation = createEffect(
    () =>
      this.actions$.pipe(
        ofType(onboardingActions.finishIdentityValidationSDK, onboardingActions.userIsInKYC),
        withLatestFrom(this._store.select(onboardingSelectors.selectIsLoadingValidations)),
        filter(([{ }, isLoading]) => !isLoading),
        switchMap(() => {
          return this._appService.startPolling({
            apiCall: () => this._api.getValidationData(),
            validateFinished: (data: any) => this._service.validateKYCFinished(data),
            closeDialog: false,
            actionSuccess: onboardingActions.successFinishIdentityValidation,
            actionFailure: onboardingActions.failureFinishIdentityValidation,
          });
        })
      ),
    { dispatch: false }
  );

  public startPollingExtraValidations = createEffect(
    () =>
      this.actions$.pipe(
        ofType(onboardingActions.approveKYCValidation, onboardingActions.userIsValidating),
        withLatestFrom(this._store.select(onboardingSelectors.selectIsLoadingValidations)),
        filter(([{ }, isLoading]) => !isLoading),
        switchMap(() => {
          return this._appService.startPolling({
            apiCall: () => this._api.getCustomerValidationStatus(),
            validateFinished: (data: any) =>
              this._service.validateCustomerValidationsFinished(data),
            actionSuccess: onboardingActions.successCustomerValidations,
            actionFailure: onboardingActions.failureCustomerValidations,
          });
        })
      ),
    { dispatch: false }
  );

  public showLoader$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        onboardingActions.finishIdentityValidationSDK,
        onboardingActions.userIsInKYC,
        onboardingActions.userIsValidating
      ),
      withLatestFrom(this._store.select(onboardingSelectors.selectIsLoadingValidations)),
      filter(([{ }, isLoading]) => !isLoading),
      map(() => {
        this._service.showLoaderValidatingIdentity();
        return onboardingActions.showLoaderSuccess();
      })
    )
  );

  public startIdentityValidation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(onboardingActions.enterToIdentityValidation),
      switchMap(() =>
        this._api.getValidationData().pipe(
          map((metadata) => onboardingActions.successGetMetadataToIdentityValidation({ metadata })),
          catchError(() => of(onboardingActions.failureGetMetadataToIdentityValidation()))
        )
      )
    )
  );

  public getLocationByZipcode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(onboardingActions.changeZipCode),
      filter(({ zipCode }) => zipCode.length >= 5),
      switchMap(({ zipCode }) => {
        this._service.showLoaderValidatingZipCode();
        return this._api.getLocationByZipcode(zipCode).pipe(
          map((location) => {
            this._uiService.closeDialog();
            if (location && Object.keys(location as object).length > 0) {
              return onboardingActions.successGetLocationsByZipCode({ location });
            } else {
              return onboardingActions.successEmptyByZipCode();
            }
          }),
          catchError(() => {
            this._uiService.closeDialog();
            return of(onboardingActions.failureGetLocationsByZipCode()) 
          })
        )
      }
      )
    )
  );

  public viewModalCreditReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(onboardingActions.showModalAuthorizationCreditReport),
      tap(() => {
        this._uiService.showDialog({ message: '' }, AuthorizationCreditReportComponent)
      })
    ), { dispatch: false }
  );

  public acceptAndContinueModalCreditReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(onboardingActions.acceptAndContinueModalAuthorizationCreditReport),
      map(() => onboardingActions.hideModalAuthorizationCreditReport())
    )
  );

  public hideModalCreditReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(onboardingActions.hideModalAuthorizationCreditReport),
      tap(() => {
        this._uiService.closeDialog();
      })
    ), { dispatch: false }
  );

  public validateOtpCode$ = createEffect(() => 
    this.actions$.pipe(
      ofType(onboardingActions.validateOTP),
      withLatestFrom(this._store.select(authSelectors.selectUser)),
      filter(([_, user]) => !!user),
      switchMap(([props, user]) => {
        if (!user) {
          return of(onboardingActions.failureValidateOTP());
        }
        return this._api.validateOTP({ phone: user.phoneNumber, code: props.code }).pipe(
          map( () => {
            return onboardingActions.responseValidateOTP();
          }),
          catchError(() => of(onboardingActions.failureValidateOTP()))
        );
      })
    )
  );

  public successValidateOtp$ = createEffect(() => 
      this.actions$.pipe(
        ofType(onboardingActions.responseValidateOTP),
        map(() => onboardingActions.hideOtpViewNextMati()),
      )
  );

  public successAndNextViewMati$ = createEffect(() => 
      this.actions$.pipe(
        ofType(onboardingActions.hideOtpViewNextMati),
        map(() => onboardingActions.successFinishOnboarding())
      )
  );
  
  public resendOtpCode$ = createEffect(() => 
    this.actions$.pipe(
      ofType(onboardingActions.onboardingResendOTP),
      withLatestFrom(this._store.select(authSelectors.selectUser)),
      switchMap(([_, user]) => {
        if (!user) {
          return of(onboardingActions.failureOnboardingResendOTPCode());
        }
        return this._api.reSendOTP(user?.phoneNumber).pipe(
          map(() => onboardingActions.responseOnboardingResendOTPCode()),
          catchError(() => of(onboardingActions.failureOnboardingResendOTPCode()))
        );
      })
    )
  );

}
