import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { environment } from '@environment';
import { GLOBAL_CONSTANTS } from '../constants/global.constants';
import { StorageService } from '@app/data/browser/storage.service';
import { ErrorService } from '../../domain/services/error.service';
import { Store } from '@ngrx/store';
import * as authActions from '@store/auth/auth.actions';

const API_URL = environment.api_url;

@Injectable()
export class AppInterceptorService implements HttpInterceptor {
  constructor(
    private _storage: StorageService,
    private _errorService: ErrorService,
    private _store: Store
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this._sendRequest(req, next);
  }

  private _sendRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isRequestAmazon = req.url.indexOf('s3.amazon') > -1;
    const isRequestGCP = req.url.indexOf('project.cloudfunctions') > -1;
    if (isRequestAmazon || isRequestGCP) {
      return this._continueRequest(req, next);
    }
    const headers: any = {
      'Content-Type': 'application/json',
      'Accept-Language': GLOBAL_CONSTANTS.DEFAULT_LANGUAGE,
    };

    return this.getToken().pipe(
      mergeMap((token) => {
        if (token) {
          headers.Authorization = `Token: ${token}`;
        }
        const reqUpdated = req.clone({
          url: API_URL + req.url,
          setHeaders: headers,
        });
        return this._continueRequest(reqUpdated, next);
      })
    );
  }

  private _continueRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: any) => this._catchError(req, err)),
      map((response: any) => {
        this._checkTokenResponse(response);
        return response;
      })
    );
  }

  private _checkTokenResponse(response: any) {
    if (response.token) {
      this.setToken(response.token);
    }
    if (response.body && response.body.token) {
      this.setToken(response.body.token);
    }
    if (response.headers && response.headers.get('token')) {
      this.setToken(response.headers.get('token'));
    }
  }

  public setToken(token: string): void {
    this._storage.setItem(GLOBAL_CONSTANTS.PROP_STORAGE_TOKEN, token);
  }

  public getToken(): Observable<string> {
    return this._storage.getItem(GLOBAL_CONSTANTS.PROP_STORAGE_TOKEN).pipe(
      switchMap((globalToken: string) => {
        return this._storage
          .getItem(GLOBAL_CONSTANTS.PROP_STORAGE_PASSWORD_TOKEN)
          .pipe(map((passwordToken) => (globalToken ? globalToken : passwordToken)));
      })
    );
  }

  public removeToken() {
    this._storage.removeItem(GLOBAL_CONSTANTS.PROP_STORAGE_TOKEN);
  }

  private _catchError(req: HttpRequest<any>, error: any) {
    const isUnathorized = error.status === GLOBAL_CONSTANTS.HTTP_CODES.UNAUTHORIZED;

    if (isUnathorized) {
      this._store.dispatch(authActions.logoutAPI());
    }

    this._errorService.handleError(error, req);
    return throwError(() => error);
  }
}
