import { createAction, props } from '@ngrx/store';
import { Payment, PAYMENT_STEP, WireTransfer } from '@core/models/payment.model';

export const requestCreatePayment = createAction(
  '[Request Payment] Create Payment',
  props<{ payment: Payment }>()
);
export const successRequestCreatePayment = createAction(
  '[Request Payment] Successfully Create Payment',
  props<{ wireTransfer: WireTransfer }>()
);
export const failureRequestCreatePayment = createAction(
  '[Request Payment] Failure Loan Dynamic Documents'
);

export const cancelPayment = createAction('[Request Payment] Cancel Payment active');

export const setStep = createAction('[Request Payment] Set Step', props<{ step: PAYMENT_STEP }>());

export const hasPayment = createAction('[Payment] User Has Payment');

export const goToPayment = createAction('[Payment] Go To Payment From Home');

export const validateHasPayment = createAction('[Payment] Validate If User Has Payment');

export const showBarcode = createAction('[Payment] showBarcode');

export const downloadBarcode = createAction('[Payment] Download Bar code');

export const successDownloadBarcode = createAction(
  '[API] Success download Bar code',
  props<{ url: string }>()
);

export const failureDownloadBarcode = createAction('[API] Failure download Bar code');

// Cancel Transaction

export const requestCancelTransaction = createAction('[Request Payment] Cancel Transaction');

export const successRequestCancelTransaction = createAction(
  '[Request Payment] Successfully Cancel Transaction'
);

export const failureRequestCancelTransaction = createAction(
  '[Request Payment] Failure Cancel Transaction'
);
