import { ONBOARDING_CONSTANTS } from './onboarding.constants';

const STEPS = ONBOARDING_CONSTANTS.STEPS;
export const AUTH_CONSTANTS = {
  ONBOARDING_STEPS: {
    TOTAL: 9,
    // Signup
    PHONE_NUMBER: 0,
    OTP: 1,
    PASSWORD: 2,
    SUCCESS: 3,
    // Onboarding
    PERSONAL_INFO: 1,
    ADDRESS: 2,
    OTHER_INFO: 3,
    PEP: 4,
    INCOME: 5,
    MATI: 6,
  },
  FORGOT_PASSWORD_STEPS: {
    PHONE_NUMBER: 0,
    OTP: 1,
    PASSWORD: 2,
    SUCCESS: 3,
  },
  TITLE_MODAL_CREDIT_REPORT: 'Autorización para solicitar Reportes de Crédito',
  MESSAGE_MODAL_CREDIT_REPORT: `<p> Por este conducto autorizo expresamente a ASOCIACION PROMUJER DE MEXICO S.A. DE C.V. SOFOM E.N.R. Para que lleve a cabo investigaciones, sobre mi comportamiento e historial crediticio, así como de cualquier otra información de naturaleza análoga, en las sociedades de información crediticia que estime conveniente. </p> <br> <p> Así mismo, declaro que conozco la naturaleza y alcance de la información que solicitara, del uso que ASOCIACION PROMUJER DE MEXICO S.A. DE C.V. SOFOM E.N.R. hará de tal información y que podrá realizar consultas periódicas de mi historial crediticio, consintiendo que esta autorización se encuentre vigente por un periodo de 3 años contados a partir de la fecha de su expedición y en todo caso durante el tiempo que mantengamos una relación jurídica. </p> <br> <p> Acepto que este documento quede bajo propiedad de ASOCIACION PROMUJER DE MEXICO S.A. DE C.V. SOFOM E.N.R. para efectos de control y cumplimiento del artículo 28 de la LRSIC. </p>`,
};

export const GENDER_LIST_IMG: {[key: string]: string} = {
  'F': '/app/presentation/assets/images/avatar-woman.png',
  'M': '/app/presentation/assets/images/avatar-man.png',
  'B': '/app/presentation/assets/images/avatar-other.png',
  'O': '/app/presentation/assets/images/avatar-non-binary.png',
}