import { PaymentCreated } from './payment.model';

export interface Session {
  is_customer: boolean;
  step: number;
}

export interface Address {
  street: string;
  exteriorNumber: string;
  interiorNumber: string;
  zipCode: string;
  colony: string;
  state: string;
  city: string;
  country?: string;
}
export interface Payment {
  numericalReference: string;
  expirationDate: string;
  status: number;
  clabe: string;
  amount: number;
  methodPayment: number;
  barcodeUrl?: string;
  barcodePdf?: string;
}

export interface User {
  fullName: string;
  externalId: string;
  phoneNumber: string;
  email?: string;
  firstName: string;
  lastName: string;
  curp?: string;
  step?: number;
  status: UserStatus;
  address?: Address;
  score?: number;
  referralCode?: string;
  gender?: string;
  lastLogin: Date;
  thirdPerson?: boolean;
  avatarUrl: string;
  payment?: PaymentCreated;
  loans?: string[];
  isCustomerSoutheast?: boolean;
  sawPopup?: boolean;
}

export enum USER_STATUS {
  LOAD_CUSTOMER = 'LOAD_CUSTOMER',
  KYC_NOT_FOUND = 'KYC_NOT_FOUND',
  KYC_PENDING = 'KYC_PENDING',
  KYC_IN_PROCESS = 'KYC_IN_PROCESS',
  KYC_MANUAL = 'KYC_MANUAL',
  KYC_REJECTED = 'KYC_REJECTED',
  KYC_ACCEPTED = 'KYC_ACCEPTED',
  CREDIT_CIRCLE = 'CREDIT_CIRCLE',
  CUSTOMER_REJECTED = 'CUSTOMER_REJECTED',
  SEND_INFORMATION = 'SEND_INFORMATION',
  CUSTOMER_WITH_SCORE = 'CUSTOMER_WITH_SCORE',
  REVIEW_LOAN = 'REVIEW_LOAN',
  DISBURSE_LOAN = 'DISBURSE_LOAN',
  DISBURSE_IN_PROCESS = 'DISBURSE_IN_PROCESS',
  ACTIVATE_LOAN = 'ACTIVATE_LOAN',
  DISBURSE_ERROR = 'DISBURSE_ERROR',
  SCORE_REJECTED = 'SCORE_REJECTED',
}

export type UserStatus = USER_STATUS;

export enum HOME_TYPE {
  USER_WITH_SCORE = 'USER_WITH_SCORE',
  DISBURSEMENT_IN_PROCESS = 'DISBURSEMENT_IN_PROCESS',
  DISBURSEMENT_ERROR = 'DISBURSE_ERROR',
  REJECTED = 'REJECTED',
  LOAN_IN_REVIEW = 'LOAN_IN_REVIEW',
  ACTIVE_LOAN = 'ACTIVE_LOAN',
  EMPTY = 'EMPTY',
}

export type HomeType = HOME_TYPE;
