import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OnboardingState } from './onboarding.state';
import { ACTION_STATUS } from '../../../core/models/app.model';

const getOnboardingFeatureState = createFeatureSelector<OnboardingState>('onboarding');

export const selectOnboardingByStep = createSelector(
  getOnboardingFeatureState,
  (state: OnboardingState) => state.stepInfo
);

export const selectOnboardingInfoStep = createSelector(
  getOnboardingFeatureState,
  (state: OnboardingState) => state.step
);

export const selectStepInfoStatus = createSelector(
  getOnboardingFeatureState,
  (state: OnboardingState) => state.stepInfoStatus ?? ACTION_STATUS.INITIAL
);

export const selectSetStepInfoStatus = createSelector(
  getOnboardingFeatureState,
  (state: OnboardingState) => state.setStepInfoStatus ?? ACTION_STATUS.INITIAL
);

export const selectIsLoadingGetMetadata = createSelector(
  getOnboardingFeatureState,
  (state: OnboardingState) => state.isLoadingGetMetadata ?? false
);

export const selectIsLoadingOnboardingForms = createSelector(
  getOnboardingFeatureState,
  (state: OnboardingState) => state.setStepInfoStatus === ACTION_STATUS.PENDING
);

export const selectMetadataIdentityValidation = createSelector(
  getOnboardingFeatureState,
  (state: OnboardingState) => state.metadataToIdentityValidation
);

export const selectStates = createSelector(
  getOnboardingFeatureState,
  (state: OnboardingState) => state.states ?? []
);

export const selectLocations = createSelector(
  getOnboardingFeatureState,
  (state: OnboardingState) =>
    state.locations ?? {
      city: [],
      colony: [],
      municipality: [],
    }
);

export const selectIsLoadingGetLocationByZipCode = createSelector(
  getOnboardingFeatureState,
  (state: OnboardingState) => state.isLoadingGetLocationByZipCode ?? false
);

export const selectLocation = createSelector(
  getOnboardingFeatureState,
  (state: OnboardingState) => state.location
);

export const selectIsLoadingValidations = createSelector(
  getOnboardingFeatureState,
  (state: OnboardingState) => state.isLoadingValidations ?? false
);

export const selectIsOnOtpView = createSelector(
  getOnboardingFeatureState,
  (state: OnboardingState) => state.isStepOtp ?? false
);

export const selectIsEmptyDataByZipcode = createSelector(
  getOnboardingFeatureState,
  (state: OnboardingState) => state.emptyByZipcode ?? false
);
