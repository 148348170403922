<button
  *ngIf="type !== 'link'"
  [type]="type"
  [disabled]="isDisabled || isLoading"
  [class]="'mo-button ' + size + ' ' + template"
  [ngClass]="{
    reverse: iconPosition === ICON_POSITIONS.RIGHT ? true : false,
    disabled: isDisabled || isLoading,
    'is-block': displayBlock
  }"
>
  <i *ngIf="iconPosition" [class]="'uil ' + icon + ' ' + iconPosition"></i>
  {{ text }}
  <span class="loading-content" *ngIf="isLoading">
    <span [class]="'mo-loading size-md' + ' ' + COLOR_TEMPLATES.LIGHT"></span>
  </span>
</button>

<a
  *ngIf="type === 'link'"
  [href]="href"
  target="_blank"
  rel="noreferrer noopener"
  [class]="'mo-button ' + size + ' ' + template"
  [ngClass]="{
    reverse: iconPosition === ICON_POSITIONS.RIGHT ? true : false,
    disabled: isDisabled,
    'is-block': displayBlock
  }"
>
  <i *ngIf="iconPosition" [class]="'uil ' + icon + ' ' + iconPosition"></i>
  <span [ngClass]="{ 'is-loading': isLoading }">{{ text }}</span>
  <span class="loading-content" *ngIf="isLoading">
    <span [class]="'mo-loading size-md' + ' ' + COLOR_TEMPLATES.LIGHT"></span>
  </span>
</a>
