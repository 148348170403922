import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environment';
import { ServerMock } from '@app/data/mocks/app-serve.mock';
import { GLOBAL_CONSTANTS as CONSTANTS } from '../constants/global.constants';
import { ERROR_CONSTANTS } from '../constants/error.constans';
import { ErrorService } from '@app/domain/services/error.service';

@Injectable()
export class AppMockInterceptorService implements HttpInterceptor {
  private _localServer: ServerMock;

  constructor(private _errorService: ErrorService) {
    this._localServer = new ServerMock();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this._checkIsTest(req)) {
      return this._sendLocalServer(req);
    }
    return next.handle(req);
  }
  /**
   * Check if environment not is production and token is for test or email and password is given for test
   * @param req - Info request sended
   */
  private _checkIsTest(req: HttpRequest<any>): boolean {
    const hasTestToken = this.getToken() === CONSTANTS.TEST_INFO.TOKEN;
    const hasTestInfo = req.body && req.body.user == CONSTANTS.TEST_INFO.USER;

    return !environment.production && (environment.isTestServer || hasTestToken || hasTestInfo);
  }

  public getToken(): string | null {
    return localStorage.getItem(CONSTANTS.PROP_STORAGE_TOKEN_MOCK);
  }

  public setToken(token: string): void {
    localStorage.setItem(CONSTANTS.PROP_STORAGE_TOKEN, token);
  }

  private _sendLocalServer(req: any): Observable<HttpEvent<any>> {
    return this._localServer.request(req).pipe(
      map((response: any) => {
        this._checkTokenResponse(response);
        return response;
      }),
      catchError((err: HttpErrorResponse) => this._catchError(req, err))
    );
  }

  private _checkTokenResponse(response: any) {
    if (response.token) {
      this.setToken(response.token);
    }
    if (response.body?.token) {
      this.setToken(response.body?.token);
    }
    if (response.headers && response.headers.get('token')) {
      this.setToken(response.headers.get('token'));
    }
  }

  private _catchError(req: HttpRequest<any>, error: any) {
    let messageError = ERROR_CONSTANTS.MESSAGES.ERROR;
    if (error.status === CONSTANTS.HTTP_CODES.NOT_FOUND) {
      this._errorService.showMessageError(ERROR_CONSTANTS.MESSAGES.ERROR_NOT_FOUND_RESOURCE);
      return throwError(() => error);
    }
    if (error.error) {
      const err = error.error;
      messageError = this._message(err);
    }
    this._errorService.handleError(error, req);
    return throwError(() => error);
  }

  private _message(err: any): string {
    const firstError = Object.keys(err)[0];
    const typeError = typeof err[firstError];
    const isStringError = typeError === 'string';

    if (!isStringError && typeError === 'object') {
      return this._message(err[firstError][0]);
    }
    return isStringError ? err[firstError] : err[firstError][0];
  }
}
