import {
  CUSTOMER_VALIDATION_STATUS,
  IDENTITY_VALIDATION_STATUS,
} from '@app/core/models/onboarding.model';

export const KYC_VALIDATION = {
  status: IDENTITY_VALIDATION_STATUS.APPROVED,
  application_id: 'testing',
};

export const CUSTOMER_VALIDATION = {
  component: 'score',
  status: CUSTOMER_VALIDATION_STATUS.APPROVED,
  reason: 'Customer does not reach the minimal required score',
};
