import { GLOBAL_CONSTANTS } from '../constants/global.constants';

export const isMobileClient = () => {
  const userAgent = navigator.userAgent;
  return /iPhone|iPad|iPod|Android/i.test(userAgent);
};

export const getUrlWhatsapp = () => {
  const urlWhatsApp = isMobileClient() ? 'https://api.whatsapp.com' : 'https://web.whatsapp.com';
  return `${urlWhatsApp}/send?phone=${GLOBAL_CONSTANTS.WHATSAPP_NUMBER}`;
};
