import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { Store } from '@ngrx/store';

import * as onboardingSelectors from '../domain/store/onboarding/onboarding.selectors';
import * as onboardingActions from '../domain/store/onboarding/onboarding.actions';
import { ActionStatus } from '../core/models/app.model';
import {
  IOnboardingStep,
  Location,
  Locations,
  OnboardingValidationData,
} from '../core/models/onboarding.model';
import { MatiService } from '@app/domain/services/mati.service';

@Injectable({ providedIn: 'root' })
export class OnboardingFacade {
  public stepInfo$: Observable<any>;
  public step$: Observable<number>;
  public stepInfoStatus$: Observable<ActionStatus>;
  public setStepInfoStatus$: Observable<ActionStatus>;
  public isLoadingGetMetadata$: Observable<boolean>;
  public isEmptyDataByZipcode$: Observable<boolean>;
  public metadataIdentityValidation$: Observable<OnboardingValidationData | undefined>;
  public states$: Observable<string[]>;
  public location$: Observable<Location | undefined>;
  public isLoadingGetLocationByZipCode$: Observable<boolean>;
  public isLoadingOnboardingForms$: Observable<boolean>;
  public isOnOtpView$: Observable<boolean>;

  constructor(private _store: Store, private _matiService: MatiService) {
    this.stepInfo$ = this._store.select(onboardingSelectors.selectOnboardingByStep);
    this.step$ = this._store.select(onboardingSelectors.selectOnboardingInfoStep);
    this.stepInfoStatus$ = this._store.select(onboardingSelectors.selectStepInfoStatus);
    this.setStepInfoStatus$ = this._store.select(onboardingSelectors.selectSetStepInfoStatus);
    this.isLoadingGetMetadata$ = this._store.select(onboardingSelectors.selectIsLoadingGetMetadata);
    this.metadataIdentityValidation$ = this._store.select(
      onboardingSelectors.selectMetadataIdentityValidation
    );
    this.states$ = this._store.select(onboardingSelectors.selectStates);
    this.location$ = this._store.select(onboardingSelectors.selectLocation);
    this.isLoadingGetLocationByZipCode$ = this._store.select(
      onboardingSelectors.selectIsLoadingGetLocationByZipCode
    );
    this.isLoadingOnboardingForms$ = this._store.select(
      onboardingSelectors.selectIsLoadingOnboardingForms
    );
    this.isOnOtpView$ = this._store.select(onboardingSelectors.selectIsOnOtpView);
    this.isEmptyDataByZipcode$ = this._store.select(onboardingSelectors.selectIsEmptyDataByZipcode);
  }

  public getOnboardingInfoByStep(step: number) {
    this._store.dispatch(onboardingActions.getOnboardingInfoByStep({ step }));
  }

  public setOnboardingInfoByStep(stepInfo: IOnboardingStep) {
    this._store.dispatch(onboardingActions.saveOnboardingInfo({ stepInfo }));
  }

  public startIdentityValidation(matiButton: any) {
    this._matiService.startMati(matiButton);
  }

  public initIdentityValidation() {
    this._store.dispatch(onboardingActions.enterToIdentityValidation());
  }

  public changeZipCode(zipCode: string) {
    this._store.dispatch(onboardingActions.changeZipCode({ zipCode }));
  }

  public showModalCreditReport() {
    this._store.dispatch(onboardingActions.showModalAuthorizationCreditReport());
  }

  public acceptAndContinueCreditReport() {
    this._store.dispatch(onboardingActions.acceptAndContinueModalAuthorizationCreditReport());
  }

  public goBackToIncome() {
    this._store.dispatch(onboardingActions.hideOtpViewReturnIncome());
  }

  public validateOtpCode(code: string) {
    this._store.dispatch(onboardingActions.validateOTP({ code }));
  }

  public resendOtp() {
    this._store.dispatch(onboardingActions.onboardingResendOTP());
  }
}
