import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  CustomerValidationsStep,
  CUSTOMER_VALIDATION_STATUS,
  IDENTITY_VALIDATION_STATUS,
  OnboardingValidationData,
} from '@app/core/models/onboarding.model';
import { UIService } from '@app/core/services/ui.service';
import * as onboardingActions from '@store/onboarding/onboarding.actions';
import { ONBOARDING_CONSTANTS } from '@app/core/constants/onboarding.constants';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  constructor(private _uiService: UIService, private _store: Store) { }

  public validateKYCFinished({ status }: OnboardingValidationData) {
    switch (status) {
      case IDENTITY_VALIDATION_STATUS.APPROVED:
        this._store.dispatch(onboardingActions.approveKYCValidation());
        return true;
      case IDENTITY_VALIDATION_STATUS.DECLINED:
      case IDENTITY_VALIDATION_STATUS.MANUAL_REVIEW:
        this._store.dispatch(onboardingActions.finishKYCValidation());
        return true;
    }
    return false;
  }

  public validateCustomerValidationsFinished({ status, component }: CustomerValidationsStep) {
    const isRejected = status === CUSTOMER_VALIDATION_STATUS.REJECTED;
    const isApproved = status === CUSTOMER_VALIDATION_STATUS.APPROVED;
    if (isRejected || (component === 'score' && isApproved)) {
      return true;
    }
    return false;
  }

  public showLoaderValidatingIdentity() {
    this._uiService.showDialog({
      title: '¡No cierres la pantalla!',
      message: 'Estamos evaluando tu solicitud, esto podría tardar hasta 7 minutos.',
      type: 'loading',
      disableClose: true,
      infoButton: { showButtonOk: false, showButtonClose: false },
    });
  }

  public showLoaderValidatingZipCode() {
    this._uiService.showDialog({
      title: '',
      message: 'Validando tu código postal, en un instante podrás continuar con tu solicitud.',
      type: 'loading',
      disableClose: true,
      infoButton: { showButtonOk: false, showButtonClose: false },
    });
  }

  public getStepName(step: number) {
    switch (step) {
      case ONBOARDING_CONSTANTS.STEPS.PERSONALINFO:
        return 'Datos personales';
      case ONBOARDING_CONSTANTS.STEPS.ADDRESS:
        return 'Domicilio';
      case ONBOARDING_CONSTANTS.STEPS.OTHER:
        return 'Queremos conocerte más';
      case ONBOARDING_CONSTANTS.STEPS.PEP:
        return 'PEP';
      case ONBOARDING_CONSTANTS.STEPS.INCOME:
        return 'Ingresos';
    }
    return 'No especificado'
  }
}
