import { AUTH_CONSTANTS } from '@app/core/constants/auth.constants';
import { ACTION_STATUS } from '@app/core/models/app.model';
import { HOME_TYPE } from '@app/core/models/user.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './auth.state';

export const getAuthFeatureState = createFeatureSelector<AuthState>('auth');

export const selectIsLogged = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state?.isLogged
);

export const selectUser = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state?.userLogged
);

export const selectScore = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state?.userLogged?.score ?? 0
);

export const signupStep = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state?.signupStep ?? AUTH_CONSTANTS.ONBOARDING_STEPS.PHONE_NUMBER
);

export const signupSendOTP = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state?.signupSendOTP ?? ACTION_STATUS.INITIAL
);
export const signupReSendOTP = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state?.signupReSendOTP ?? ACTION_STATUS.INITIAL
);
export const signupValidateOTP = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state?.signupValidateOTP ?? ACTION_STATUS.INITIAL
);
export const signupValidatePassword = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state?.signupValidatePassword ?? ACTION_STATUS.INITIAL
);

export const signupData = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state?.signupData
);

// #region forgot data
export const forgotData = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state?.forgotData
);

export const forgotStep = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state?.forgotStep ?? AUTH_CONSTANTS.FORGOT_PASSWORD_STEPS.PHONE_NUMBER
);

export const forgotIsLoadingSendOTP = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state?.forgotIsLoadingSendOTP ?? false
);

export const forgotIsLoadingSavePassword = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state?.forgotIsLoadingSavePassword ?? false
);
// #endregion

export const loginIsLoading = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state?.loginIsLoading ?? false
);

export const isLoadingUpdateEmail = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state?.isLoadingUpdateProfile ?? false
);

export const selectHomeType = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state?.homeType ?? HOME_TYPE.EMPTY
);
