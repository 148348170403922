import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { GLOBAL_CONSTANTS } from '@app/core/constants/global.constants';
import { filter } from 'rxjs';
import { UIService } from '../../core/services/ui.service';
import { AppFacade } from '../../facades/app.facade';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public isLarge: boolean;
  title = 'promujer-digital';

  constructor(private _facade: AppFacade) {
    this.isLarge = false;
    this._facade.getUrlByChangeEvent().subscribe((event) => {
      const { url } = event as NavigationEnd;
      this._facade.chageRoute(url);
    });
  }
  @HostListener('window:resize', ['$event'])
  public _checkIsLarge() {
    this.isLarge = window.innerWidth > GLOBAL_CONSTANTS.SIZE_MOBILE_SCREEN;
  }
  ngOnInit() {
    this.isLarge = window.innerWidth > GLOBAL_CONSTANTS.SIZE_MOBILE_SCREEN;
  }
}
