<div class="box-content-shadow">
  <div *ngIf="user" class="box-logged-user">
    <div class="avatar">
      <img [src]="user?.avatarUrl" />
    </div>
    <div class="info-user">
      <h3>Hola, {{ user.firstName }}</h3>
      <p>Última conexión {{ user.lastLogin | MODate: "dd/MM/YYYY HH:mm" }}</p>
    </div>
  </div>
</div>
