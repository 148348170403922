import { createAction, props } from '@ngrx/store';
import { HomeType, User } from '@app/core/models/user.model';
import { IIntentionRegister, ISignUpRequest } from '@app/core/models/signup.model';
import { LoginData } from '@app/core/models/auth.model';

export const responseUserGuard = createAction('[Logged guard] Response user', props<{ user: User }>());
export const logoutGuard = createAction('[Logged guard] Logout token invalid');
export const logoutAPI = createAction('[API] Logout Token invalid');
export const logoutUserStatus = createAction('[Store] Validate user status and determine logout');

// #region signup
export const initSignup = createAction('[Signup] Init process', props<{ phone: string }>());
export const goBackPage = createAction('[Signup] Action of go back to previous page');
export const setNewStep = createAction('[Signup] Set a new step ', props<{ signupStep: number }>());

export const responseSendOTPCode = createAction('[Signup] Backend has confirmed to send the OTP code');
export const failureSendOTPCode = createAction('[Signup] Backend has failed to send the OTP code');

export const validateOTP = createAction('[Signup] validate OTP code', props<{ code: string }>());
export const responseValidateOTPCode = createAction('[Signup] Backend validate the OTP code');
export const failureValidateOTPCode = createAction('[Signup] Backend failed when try to validate the OTP code');
export const resendOTP = createAction('[Signup] resend OTP code');
export const responseReSendOTPCode = createAction('[Signup] Backend resend the OTP code');
export const failureReSendOTPCode = createAction('[Signup] Backend failed when try to resend the OTP code');

export const validatePassword = createAction('[Signup] Validate Password', props<{ signUpPayload: ISignUpRequest }>());
export const responseValidatePassword = createAction('[Signup] Backend validate the password its ok', props<LoginData>());
export const responseLoginSignup = createAction('[Signup] Backend validate the user and has access');
export const responseUserSignup = createAction('[Singup]Backend return user', props<{ user: User }>());
export const failureValidatePassword = createAction('[Signup] Backend failed when try to validaet the Password');

export const startOnboardingFromSignup = createAction('[Signup] start the onboarding flow (redirect)');

// #region forgot password actions
export const forgotGoBackPage = createAction('[Forgot] Click to go to the previous page');
export const forgotSendOTP = createAction('[Forgot] Send OTP to phone', props<{ phone: string }>());
export const responseForgotSendOTP = createAction('[Forgot] Backend sent the OTP');
export const failureForgotSendOTP = createAction('[Forgot] Backend faield when try to send the OTP');

export const forgotResendOTP = createAction('[Forgot] Click in resend OTP');
export const responseForgotResendOTP = createAction('[Forgot] Backend resent the OTP to phone');
export const failureForgotResendOTP = createAction('[Forgot] Backend faield when try to resend the OTP');

export const forgotValidateOTP = createAction('[Forgot] validate OTP code', props<{ code: string }>());
export const responseForgotValidateOTPCode = createAction('[Forgot] Backend validated the OTP code');
export const failureForgotValidateOTPCode = createAction('[Forgot] Backend failed when try to validate the OTP code');

export const forgotSaveNewPassword = createAction('[Forgot] Save new password', props<{ password: string }>());
export const responseForgotSavePassword = createAction('[Forgot] Backend saved the new password');
export const failureForgotSavePassword = createAction('[Forgot] Backend failed when try to saved the new password');

export const goToHomeAfterChangePassword = createAction('[Forgot] Go To Home After Change Passwrod')
// #endregion

// #region login actions

export const login = createAction('[Login] log in', props<LoginData>());
export const responseLogin = createAction('[Login] Backend validate the user and has access');
export const failureLogin = createAction('[Login] Backend do not identify the user');
export const responseUserLogin = createAction('[Login] Response user', props<{ user: User }>());
export const failureUserLogin = createAction('[Login] Backend failed when try to get basic info user');
export const reloadSession = createAction('[App] Reload session data to validate the status and user data');
export const saveHomeType = createAction('[Store] Save home type', props<{ homeType: HomeType }>());
// #endregion

//profile
export const changeEmail = createAction('[Profile] Change Email', props<{ email: string }>());
export const successChangeEmail = createAction('[Profile] Success Change Email', props<{ email: string }>());
export const failureChangeEmail = createAction('[Profile] Failure Change Email');

// #region intention signup

export const saveItentionSignup = createAction('[Signup] Intention Signup', props<{intentionData: IIntentionRegister}>());
export const reponseSaveItentionSignup = createAction('[Signup] Intention Signup Response');
export const failureSaveItentionSignup = createAction('[Signup] Intention Signup Failure');

// #end region