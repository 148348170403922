import { environment } from '@environment';

export function AppInitializer() {
  const _gaKey = environment.googleAnalyticsKey;
  const _hotjarId = environment.hotjarId;
  const _gtm = environment.gtm;
  const _pixel_id = environment.pixel_id;
  return async () => {
    // #region Google Analytics
    (function () {
      (window as any).dataLayer = (window as any).dataLayer || [];

      const previousScript = document.getElementsByTagName('script')[0];
      const gtagScript = document.createElement('script');
      gtagScript['async'] = true;
      gtagScript['src'] =
        'https://www.googletagmanager.com/gtag/js?id=' + _gaKey;
      previousScript.parentNode?.insertBefore(gtagScript, previousScript);
    })();

    (window as any).dataLayer = (window as any).dataLayer || [];

    function gtag(type: string, value: any, details?: any) {
      (window as any).dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', environment.googleAnalyticsKey);
    // #endregion

    // #region Hotjar
    if (_hotjarId) {
      const _hotjarVersion = 6;
      (function (h: any, o: any, t: any, j: any, a?: any, r?: any) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: _hotjarId, hjsv: _hotjarVersion };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    } else {
      console.warn('SIN INFORMACIÓN EN HOTJAR');
    }
    // #endregion

    // #region GTM
    if (_gtm) {
      (function (w: any, d: any, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', _gtm);
    }
    // #pixel implementation

    if (_pixel_id) {
      (function (f: any, b: any, e: any, v: any, n?: any, t?: any, s?: any) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js'
      );
      (window as any).fbq('init', _pixel_id);
      (window as any).fbq('track', 'PageView');

      const img = document.createElement('img');
      img.setAttribute(
        'src',
        'https://www.facebook.com/tr?id=' +
          _pixel_id +
          '&ev=PageView&noscript=1'
      );
      img.style.width = '1';
      img.style.height = '1';
      img.style.display = 'none';

      const noscript = document.createElement('noscript');
      noscript.id = 'FBPixeliframe';
      noscript.appendChild(img);

      document.body.insertBefore(noscript, document.body.firstChild);
    }
  };
}
