import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';

import { ActionStatus } from '@app/core/models/app.model';
import { IIntentionRegister, ISignUpRequest } from '@app/core/models/signup.model';
import * as authActions from '../domain/store/auth/auth.actions';
import * as authSelectors from '../domain/store/auth/auth.selectors';
import { LoginData } from '@app/core/models/auth.model';
import { HomeType, User } from '@app/core/models/user.model';

@Injectable({ providedIn: 'root' })
export class AuthFacade {
  public signupStep$: Observable<number>;
  public signupSendOTP$: Observable<ActionStatus>;
  public signupReSendOTP$: Observable<ActionStatus>;
  public signupValidateOTP$: Observable<ActionStatus>;
  public signupValidatePassword$: Observable<ActionStatus>;
  public signupData$: Observable<{ phone: string } | undefined>;

  public forgotPhone$: Observable<string>;
  public forgotStep$: Observable<number>;
  public forgotIsLoadingSendOTP$: Observable<boolean>;
  public forgotIsLoadingSavePassword$: Observable<boolean>;
  public loginIsLoading$: Observable<boolean>;
  public isLoadingUpdateEmail$: Observable<boolean>;
  public user$: Observable<User | undefined>;
  public homeType$: Observable<HomeType>;

  constructor(private _store: Store) {
    this.signupStep$ = this._store.select(authSelectors.signupStep);
    this.signupData$ = this._store.select(authSelectors.signupData);
    this.signupSendOTP$ = this._store.select(authSelectors.signupSendOTP);
    this.signupValidateOTP$ = this._store.select(authSelectors.signupValidateOTP);
    this.signupValidatePassword$ = this._store.select(authSelectors.signupValidatePassword);
    this.signupReSendOTP$ = this._store.select(authSelectors.signupValidatePassword);
    this.user$ = this._store.select(authSelectors.selectUser);

    this.forgotPhone$ = this._store
      .select(authSelectors.forgotData)
      .pipe(map((data) => data?.phone?.substring(6, 10) ?? ''));
    this.forgotStep$ = this._store.select(authSelectors.forgotStep);
    this.forgotIsLoadingSendOTP$ = this._store.select(authSelectors.forgotIsLoadingSendOTP);
    this.forgotIsLoadingSavePassword$ = this._store.select(
      authSelectors.forgotIsLoadingSavePassword
    );
    this.loginIsLoading$ = this._store.select(authSelectors.loginIsLoading);
    this.isLoadingUpdateEmail$ = this._store.select(authSelectors.isLoadingUpdateEmail)
    this.homeType$ = this._store.select(authSelectors.selectHomeType);
  }

  public initSignup({ phone }: { phone: string }) {
    this._store.dispatch(authActions.initSignup({ phone }));
  }

  public reSendOtp() {
    this._store.dispatch(authActions.resendOTP());
  }

  public validateOTP({ code }: { code: string }) {
    this._store.dispatch(authActions.validateOTP({ code }));
  }

  public validatePassword(signUpPayload: ISignUpRequest) {
    this._store.dispatch(authActions.validatePassword({ signUpPayload }));
  }

  public goBackPage() {
    this._store.dispatch(authActions.goBackPage());
  }

  public startOnboardingSignup() {
    this._store.dispatch(authActions.startOnboardingFromSignup());
  }

  public forgotGoBack() {
    this._store.dispatch(authActions.forgotGoBackPage());
  }

  public forgotSendOTP({ phone }: { phone: string }) {
    this._store.dispatch(authActions.forgotSendOTP({ phone }));
  }

  public forgotResendOTP() {
    this._store.dispatch(authActions.forgotResendOTP());
  }

  public forgotValidateOTP({ code }: { code: string }) {
    this._store.dispatch(authActions.forgotValidateOTP({ code }));
  }

  public forgotSaveNewPassword({ password }: { password: string }) {
    this._store.dispatch(authActions.forgotSaveNewPassword({ password }));
  }

  public login(loginData: LoginData) {
    this._store.dispatch(authActions.login(loginData));
  }
  public logOut() {
    this._store.dispatch(authActions.logoutGuard());
  }

  public goToHomeAfterChangePassword() {
    this._store.dispatch(authActions.goToHomeAfterChangePassword());
  }

  public reloadSession() {
    this._store.dispatch(authActions.reloadSession())
  }

  public saveIntention(intentionData: IIntentionRegister) {
    this._store.dispatch(authActions.saveItentionSignup({intentionData}));
  }
}
