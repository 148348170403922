import { createAction, props } from '@ngrx/store';
import {
  IOnboardingStep,
  Locations,
  Location,
  OnboardingValidationData,
} from '../../../core/models/onboarding.model';

export const getOnboardingInfoByStep = createAction('[User Info] Get user information by step', props<{ step: number }>());

export const successGetOnboardingInfoByStep = createAction('[User Info] Success the get user information by step', props<{ stepInfo?: IOnboardingStep }>());

export const failureGetOnboardingInfoByStep = createAction('[User Info] Failure the get user information by step');

export const saveOnboardingInfo = createAction('[User Info] Set user information by step', props<{ stepInfo: IOnboardingStep }>());

export const successSaveOnboardingInfo = createAction('[User Info] Success the set user information by step', props<{ step: number }>());

export const successFinishOnboarding = createAction('[User Info] Success saving the last step in the onboarding flow');

export const failureSaveOnboardingInfo = createAction('[User Info] Failure the set user information by step');

export const enterToIdentityValidation = createAction('[Mati Page] Enter to the mati page');
export const successGetMetadataToIdentityValidation = createAction('[API] Success the get metadata to identity validation', props<{ metadata: OnboardingValidationData }>());
export const failureGetMetadataToIdentityValidation = createAction('[API] Failure the get metadata to identity validation');
export const showLoaderSuccess = createAction('[Onboarding effects] Start showing the loader');
export const approveKYCValidation = createAction('[Onboarding effects] Finish the KYC validation with status approved');
export const finishKYCValidation = createAction('[Onboarding effects] Finish the KYC validation');
export const successCustomerValidations = createAction('[API] Success the extra validations');
export const failureCustomerValidations = createAction('[API] Failure the extra validations');

export const userIsInKYC = createAction('[Auth] User is in KYC status');
export const userIsValidating = createAction('[Auth] Validating user data (score, circle_credit)');
export const finishIdentityValidationSDK = createAction('[Mati SDK] Mati event: userFinishedSdk');
export const successFinishIdentityValidation = createAction('[API] Success identity validation');
export const failureFinishIdentityValidation = createAction('[API] Failure identity validation');

export const changeZipCode = createAction('[Onboarding] Change zip code', props<{ zipCode: string }>());
export const successGetLocationsByZipCode = createAction('[API] Success get location by zip code', props<{ location: Location | undefined }>());
export const successEmptyByZipCode = createAction('[API] Success not found by zip code');
export const failureGetLocationsByZipCode = createAction('[API] Failure get location by zip code');

// credit report
export const showModalAuthorizationCreditReport = createAction('[Onboarding] View Modal Authorization Credit Report');
export const acceptAndContinueModalAuthorizationCreditReport = createAction('[Onboarding] Accept And Continue View Modal Authorization Credit Report');
export const hideModalAuthorizationCreditReport = createAction('[Onboarding] Hide Modal Authorization Credit Report');
// #endregion

//////////////// OTP
export const showOtpView = createAction('[Onboarding] show view otp');
export const hideOtpViewReturnIncome = createAction('[Onboarding] hide view otp, return income view');
export const hideOtpViewNextMati = createAction('[Onboarding] hide view otp, next to mati view');

//send code
export const validateOTP = createAction('[Onboarding] Validate OTP Code', props<{ code: string }>());
export const responseValidateOTP = createAction('[Onboarding] Success Validate OTP Code');
export const failureValidateOTP = createAction('[Onboarding] Failure Validate OTP Code');

export const onboardingResendOTP = createAction('[Onboarding] Resend OTP Code');
export const responseOnboardingResendOTPCode = createAction('[Onboarding] Success Resend OTP Code');
export const failureOnboardingResendOTPCode = createAction('[Onboarding] Failure Trying Resend Otp Code');
//////////////// #endregion

export const getDataUserAfterStepTwo = createAction('[Onboarding] Get Data User After Step 1');
