export const environment = {
  production: true,
  testServeWait: 1000,
  test: false,
  isEnablePutRecord: false,
  isTestServer: false,
  googleAnalyticsKey: 'G-GSM3F6YRV1',
  hotjarId: '2929720',
  gtm: 'GTM-P92LSGT',
  pixel_id: '616677303840263',
  api_url: 'https://mo-services-promujer-production.moprestamo.com',
  documentsFolder: '/app/presentation/assets/documents',
  matiClientID: '61dddc54f71fdb001b405aa8',
  matiFlowID: '61dddc53f71fdb001b405aa7',
  blockGeneralFlow: false,
};
