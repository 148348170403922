export interface IMovements {
  paymentDate: string;
  movement: string;
  amount: string;
  type: string;
}
export interface ILoanInfo {
  totalDebth: string;
  contractNumber: string;
  takenAt: string;
  annualInterest: string;
  expirationDate: string;
  amountPayable: string;
}
export interface Bank {
  value: string;
  text: string;
}

export enum LOAN_DOCUMENTS {
  CONTRACT = 'CREDIT_CONTRACT',
  PROMISSORY_NOTE = 'BORROWER_NOTE_CONTRACT',
  PAYMENTS_PLAN = 'PAYMENTS_PLAN',
  PAYMENTS_PLAN_SIMULATOR = 'PAYMENTS_PLAN_SIMULATOR',
  ACCOUNT_STATUS = 'ACCOUNT_STATUS',
}

export enum PAYMENT_FREQUENCY {
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'FORTNIGHTLY',
  MONTHLY = 'MONTHLY',
}
export type PaymentFrequency = PAYMENT_FREQUENCY;

export enum DISBURSEMENT_STATUS {
  PROCESSED = 1,
  // FAIED AND RETRAY
  FAILED = 2,
  RETURNED = 3,
  CANCELLED = 4,
  BAD_REQUEST = 5,
  CANCEL_FAILED = 6,
  WRONG_PROCESSED = 7,
  // PENDING STATUS
  PENDING = -1,
  SENT = -2,
  PENDING_PROVIDER = -3,
}
export type DisbursementStatus = DISBURSEMENT_STATUS;

export type DisbursementStatusResponse = {
  disburseStatus: DisbursementStatus;
};
export interface LoanParams {
  amount: string;
  paymentFrequency: PaymentFrequency;
  loanDuration: string;
  firstPaymentDate: string;
}

export interface LoanMath {
  amount: number;
  taxPercentage: number;
  interestPercentage?: number;
  quotas: number;
  // TODO: Al parece backend cambió la definición del endpoint y ahora es sin el plural, verificar cual dejar
  quota: number;
  quotaAmount: number;
  taxesAmount: number;
  totalAmount: number;
  urlPaymentPlan: any;
}

export interface ILoanLegalDocumentAgreement {
  contract: boolean;
  promissoryNotes: boolean;
}

export interface ILoanLegalUrlDocuments {
  url: string;
}

export interface ILoanLegalDocuments {
  [key: string]: ILoanLegalUrlDocuments;
}

export interface BankInfo {
  nameBank: string;
  clabeOrDebitCard: string;
  paymentMethod: string;
}

export enum MOVEMENT_TYPE {
  PAYMENT = 'PAYMENT_ACH',
  DISBURSEMENT = 'DISBURSEMENT',
  REFUND = 'REFUND',
}

export type MovementType = MOVEMENT_TYPE;

export interface Movement {
  name?: string;
  className?: string;
  type: MovementType;
  date: string;
  amount: number;
}
export interface Url {
  type: string;
  url: string;
}

export interface LoanDetails {
  id: string;
  quotaAmount: number;
  installmentDate: string;
  contractNumber: string;
  loanDate: string;
  taxesAnnualAmount: number;
  totalAmount: number;
  paidAmount: number;
  pendingAmount: number;
  collection: number;
  loanDuration: string;
  isFrozen: boolean;
  movements: Movement[];
  urls: Url[];
}
