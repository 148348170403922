import { Component } from '@angular/core';
import { GLOBAL_CONSTANTS as CONST } from '@app/core/constants/global.constants';
import { getUrlWhatsapp } from '@app/core/services/util';

@Component({
  selector: 'error-request-manual-disbursement',
  templateUrl: './error-request-manual-disbursement.component.html',
  styleUrls: ['./error-request-manual-disbursement.component.scss']
})
export class ErrorRequestManualDisbursementComponent {

  public urlWhatsapp: string;
  public whatsappNumber: string;
  public type: 'success' | 'alert' | 'danger';

  constructor() {
    this.whatsappNumber = CONST.WHATSAPP_NUMBER_LABEL;
    this.type = 'danger';
    this.urlWhatsapp = getUrlWhatsapp();
  }
}
