import { MODAL_SIZE } from '@app/presentation/mo-layout/components/modal/modal-content.type';
import { DISBURSEMENT_TYPE } from './loan.constants';

export const PAYMENT_CONSTANTS = {
  STP_INFO: {
    PAYMENT_SYSTEM: 'STP',
    BENEFICIARY: 'Pro Mujer',
    CLABE: '646180299300000002',
    PAYMENT_CONCEPT: '12345',
  },
};
export const PAYMENTS_STATES = {
  SUCCESS: 1,
  PENDING: -1,
  FAILED: 2,
  RETURNED: 3,
  BAD_REQUEST: 4,
  CANCEL_FAILED: 6,
  WRONG_PROCESSED: 7,
  SENT: -2,
  PENDING_PROVIDER: -3,
};
export const PAYMENT_TRANSACTION_TYPE = {
  ACH: 2,
  CASH: 4,
};
export const PAYMENT_METHOD_TYPE = DISBURSEMENT_TYPE;

export const BAR_CODE_INFO_OXXO = {
  type: 'barcode',
  message:
    ' <b>Código de barras</b> <br><br>Presenta este código de barras en tu OXXO más cercano y realiza el pago de tu cuota: <br><br><br> <img class="logo" src="/app/presentation/assets/logo/logo-oxxo.png"  /> <br><br><img class="logo" src="/app/presentation/assets/images/barcode_img.png"/> ',
  size: MODAL_SIZE.MD,
  infoButton: { showButtonClose: true, textOk: 'Descargar código de barras' },
};
