import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import {
  Bank,
  BankInfo,
  ILoanLegalDocuments,
  LoanDetails,
  LoanMath,
  LoanParams,
  LOAN_DOCUMENTS,
} from '../core/models/loan.model';

import * as loanSelectors from '../domain/store/loan/loan.selectors';
import * as authSelectors from '../domain/store/auth/auth.selectors';
import * as loanActions from '../domain/store/loan/loan.actions';
import * as authActions from '../domain/store/auth/auth.actions';

@Injectable({ providedIn: 'root' })
export class LoanFacade {
  public step$: Observable<number>;
  public score$: Observable<number>;
  public dynamicDocuments$: Observable<ILoanLegalDocuments>;
  public isLoadingCalcLoanMath$: Observable<boolean>;
  public loanMath$: Observable<LoanMath | undefined>;
  public bankList$: Observable<Bank[] | undefined>;
  public isLoadingRequestLoan$: Observable<boolean>;
  public isLoadingDynamicDocuments$: Observable<boolean>;
  public isLoadingDisburseLoan$: Observable<boolean>;
  public isLoadingGetCurrentLoan$: Observable<boolean>;
  public currentLoan$: Observable<LoanDetails | undefined>;

  constructor(private _store: Store) {
    this.bankList$ = this._store.select(loanSelectors.selectBankList);
    this.step$ = this._store.select(loanSelectors.selectLoanStep);
    this.dynamicDocuments$ = this._store.select(loanSelectors.selectLoanDynamicDocuments);
    this.score$ = this._store.select(authSelectors.selectScore);
    this.isLoadingCalcLoanMath$ = this._store.select(
      loanSelectors.selectIsLoadingCalculateLoanMath
    );
    this.loanMath$ = this._store.select(loanSelectors.selectLoanMath);
    this.isLoadingRequestLoan$ = this._store.select(loanSelectors.selectIsLoadingRequestLoan);
    this.isLoadingDynamicDocuments$ = this._store.select(
      loanSelectors.selectIsLoadingDynamicDocuments
    );
    this.isLoadingDisburseLoan$ = this._store.select(loanSelectors.selectIsLoadingDisburseLoan);
    this.isLoadingGetCurrentLoan$ = this._store.select(loanSelectors.selectIsLoadingGetCurrentLoan);
    this.currentLoan$ = this._store.select(loanSelectors.selectCurrentLoan);
  }

  public getDynamicDocuments(documentName: Array<string>) {
    this._store.dispatch(loanActions.getDynamicDocuments({ documentName }));
  }

  public acceptLoan() {
    this._store.dispatch(loanActions.acceptLoan());
  }

  public calculateLoanMath(loan: LoanParams) {
    this._store.dispatch(loanActions.calculateLoanMath({ loan }));
  }

  public getBankList() {
    this._store.dispatch(loanActions.getBankList());
  }

  public agreeTermsConditions() {
    this._store.dispatch(
      loanActions.getDynamicDocuments({
        documentName: [LOAN_DOCUMENTS.CONTRACT, LOAN_DOCUMENTS.PROMISSORY_NOTE],
      })
    );
  }

  public requestLoan() {
    this._store.dispatch(loanActions.requestLoan());
  }

  public disburseLoan(bankInfo: BankInfo) {
    this._store.dispatch(loanActions.disburseLoan({ bankInfo }));
  }

  public getCurrentLoan() {
    this._store.dispatch(loanActions.getCurrentLoan());
  }

  public downloadLegalDocument(documentName: string) {
    this._store.dispatch(loanActions.downloadLegalDocument({ documentName }));
  }

  public downloadPaymentPlanSimulator() {
    this._store.dispatch(loanActions.downloadPaymentPlanSimulator());
  }

  public downloadPaymentPlan() {
    this._store.dispatch(loanActions.downloadPaymentPlan());
  }

  public changeStateUserToRetryDisbursement() {
    this._store.dispatch(loanActions.restartDisbursementError());
  }

  public goToRequestLoan() {
    this._store.dispatch(loanActions.goToRequestLoan());
  }

  public logoutUser() {
    this._store.dispatch(authActions.logoutUserStatus());
  }

  public downloadAccountStatus() {
    this._store.dispatch(loanActions.downloadAccountStatus());
  }
}
