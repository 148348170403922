import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, first, map, Observable } from 'rxjs';
import { AuthService } from '@app/domain/services/auth.service';
import * as authSelectors from '@store/auth/auth.selectors';
import { USER_STATUS } from '../models/user.model';
import { GLOBAL_CONSTANTS } from '../constants/global.constants';
import { REDIRECT_BY_STEP, STEP_BY_NAME } from '../constants/redirect-by-step.constants';

@Injectable({
  providedIn: 'root',
})
export class OnboardingGuard implements CanActivate, CanActivateChild {
  constructor(private _router: Router, private _store: Store, private _authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._validateIsInOnboarding(route);
  }
  canActivateChild(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._validateIsInOnboarding(route);
  }

  private _validateIsInOnboarding(route: ActivatedRouteSnapshot) {
    return this._store.select(authSelectors.selectUser).pipe(
      filter(Boolean),
      first(),
      map((user) => {
        if (user?.status === USER_STATUS.KYC_MANUAL) {
          this._router.navigateByUrl(GLOBAL_CONSTANTS.ROUTES.HOME);
          return false;
        }
        const isOnboarding = this._authService.validateUserIsInOnboarding(user);
        if (!isOnboarding) {
          this._router.navigateByUrl(GLOBAL_CONSTANTS.ROUTES.HOME);
          return false;
        }

        const stepURL = STEP_BY_NAME[route.params['name-step']] ?? 1;
        const step = (user?.step ?? 0) + 1;
        if (stepURL > step) {
          const stepRoute = REDIRECT_BY_STEP.REDIRECT_BY_STEP[step];
          this._router.navigateByUrl(stepRoute);
        }
        return true;
      })
    );
  }
}
