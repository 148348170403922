import { Component, Input, OnInit } from '@angular/core';
import { getUrlWhatsapp } from '@app/core/services/util';
import { GoogleAnalyticsService } from '@app/domain/services/google-analytics.service';
import { INPUT_TYPE } from '@app/presentation/mo-forms/enums/fields.type';
import { GLOBAL_CONSTANTS as CONST } from '@app/core/constants/global.constants';
import {
  CATEGORIES_EVENT,
  ACTION_EVENT,
} from '@app/core/constants/analytics.constants';
import { User } from '@app/core/models/user.model';
import { Observable } from 'rxjs';
import { AuthFacade } from '@app/facades/auth.facade';

@Component({
  selector: 'message-loan-rejected',
  templateUrl: './message-loan-rejected.component.html',
  styleUrls: ['./message-loan-rejected.component.scss'],
})
export class RejectedComponent implements OnInit {
  @Input() isLoading!: boolean | null;
  public INPUT_TYPE = INPUT_TYPE;
  public whatsAppNumber = CONST.WHATSAPP_NUMBER_LABEL;
  public urlWhatsapp: string;
  public urlTips: string;
  public userId: string | undefined;

  constructor(
    private _gaService: GoogleAnalyticsService,
    private _authFacade: AuthFacade
  ) {
    this.isLoading = false;
    this.urlWhatsapp = getUrlWhatsapp();
    this.urlTips = CONST.URL_TIPS_IMPROVE_SCORE;
  }

  ngOnInit(): void {
    this._authFacade.user$.subscribe((user) => {
      return (this.userId = user?.externalId);
    });
    this._gaService.sendEvent(
      CATEGORIES_EVENT.REJECTED_LOAN,
      ACTION_EVENT.VISIT,
      `Lo sentimos Solicitud Rechazada , ID: ${this.userId}`
    );
  }

  public onRedirectTips() {
    this._gaService.sendEvent(
      CATEGORIES_EVENT.REJECTED_LOAN,
      ACTION_EVENT.CLICK,
      'Quiero mejorar mi score'
    );
  }
}
