import { HttpErrorResponse, HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '@environment';
import { Observable, throwError } from 'rxjs';
import { URLS } from '../mo-api/url.constants';

import OnboardingStepOne from './data/onboarding_step-one.json';
import OnboardingStepTwo from './data/onboarding_step-two.json';
import OnboardingStepThree from './data/onboarding_step-three.json';
import OnboardingStepFour from './data/onboarding_step-four.json';
import OnboardingStepFive from './data/onboarding_step-five.json';
import BasicInfo from './data/basic-information.json';
import { GLOBAL_CONSTANTS } from '@app/core/constants/global.constants';
import { LOCATION_BY_ZIPCODE } from './data/location.mock';
import {
  DISBURSEMENT_LOAN_RESPONSE,
  LOAN_DETAILS,
  LOAN_DOCUMENTS,
  LOAN_MATH,
} from './data/loan.mock';
import { CUSTOMER_VALIDATION, KYC_VALIDATION } from './data/onboarding.mock';

type RouteMock = {
  url: RegExp | string;
  data: any;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
};

const ROUTES_MOCK: RouteMock[] = [
  { url: URLS.SEND_OTP_CODE, data: {} },
  { url: URLS.VALIDATE_OTP_CODE, data: {} },
  { url: URLS.SIGN_UP, data: {} },
  { url: URLS.ONBOARDING_STEP_1, data: OnboardingStepOne },
  { url: URLS.ONBOARDING_STEP_2, data: OnboardingStepTwo },
  { url: URLS.ONBOARDING_STEP_3, data: OnboardingStepThree },
  { url: URLS.ONBOARDING_STEP_4, data: OnboardingStepFour },
  { url: URLS.ONBOARDING_STEP_5, data: OnboardingStepFive },
  { url: URLS.ONBOARDING_STEP_6, data: {} },
  { url: URLS.FORGOT_SEND_OTP_CODE, data: {} },
  { url: URLS.SEND_OTP_CODE_ONBOARDING, data: {} },
  { url: URLS.FORGOT_SAVE_NEW_PASSWORD, data: {} },
  { url: URLS.LOGIN, data: { token: GLOBAL_CONSTANTS.TEST_INFO.TOKEN } },
  { url: URLS.GET_USER, data: BasicInfo },
  { url: URLS.ONBOARDING_IDENTITY_VALIDATION_STATUS, data: KYC_VALIDATION },
  { url: URLS.ONBOARDING_CUSTOMER_VALIDATIONS_STATUS, data: CUSTOMER_VALIDATION },
  { url: `${URLS.ONBOARDING_GET_LOCATION_BY_ZIPCODE}.+/`, data: LOCATION_BY_ZIPCODE },
  { url: `${URLS.PAYMENT}`, data: {} },
  { url: URLS.LOAN_GET_DOCUMENTS, data: LOAN_DOCUMENTS },
  { url: URLS.LOAN_SIMULATOR, data: LOAN_MATH },
  { url: URLS.CHANGE_EMAIL, data: {} },
  { url: URLS.LOAN_REQUEST, data: {} },
  {
    url: URLS.LOAN_DISBURSE,
    data: {},
    method: 'POST',
  },
  {
    url: URLS.LOAN_DISBURSE,
    method: 'GET',
    data: DISBURSEMENT_LOAN_RESPONSE,
  },
  { url: `${URLS.LOAN_DETAILS}.+/`, data: LOAN_DETAILS },
  { url: URLS.INTENTION_SIGNUP, data: {} },
];

export class ServerMock {
  public request(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    const statusResponse = 200;
    const response = ROUTES_MOCK.find((route) => {
      const isSameRoute = new RegExp(`(^${route.url}$)`, 'g').test(req.url);
      const isSameMethod = !route?.method || route?.method === req.method;
      return isSameRoute && isSameMethod;
    });

    if (!response) {
      return throwError(
        () =>
          new HttpErrorResponse({
            status: 404,
            statusText: `The route was not found ${req.url}`,
          })
      );
    }

    return new Observable((obs: any) => {
      setTimeout(() => {
        obs.next(new HttpResponse({ status: statusResponse, body: response.data }));
        obs.complete();
      }, environment.testServeWait);
    });
  }
}
