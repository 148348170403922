import { Component, Input } from '@angular/core';

@Component({
  selector: 'layout-message-status',
  templateUrl: './message-status.component.html',
  styleUrls: ['./message-status.component.scss'],
})
export class MessageStatusComponent {
  @Input() title!: string;
  @Input() message!: string;
  @Input() type!: 'success' | 'alert' | 'danger';

  constructor() {
    this.type = 'success';
  }
}
