import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { IModalParams } from '@app/presentation/mo-layout/components/modal/modal-config.interface';
import { Modal } from '@app/presentation/mo-layout/components/modal/modal.base';
import { ModalComponent } from '@app/presentation/mo-layout/components/modal/modal.component';
import { Router, NavigationEnd } from '@angular/router';
import { filter, of, Observable } from 'rxjs';
import { APP_CONSTANTS as CONST } from '../constants/app.constants';

@Injectable({
  providedIn: 'root',
})
export class UIService {
  public dialogRef?: MatDialogRef<Modal>;
  private _snackRef?: MatSnackBarRef<any>;
  constructor(
    private _dialog: MatDialog,
    private _router: Router,
    private _snackBar: MatSnackBar
  ) {}

  public showDialog(
    data: IModalParams | string,
    component: ComponentType<Modal> = ModalComponent
  ) {
    this._closeDialogBeforeShow();
    const config: IModalParams =
      typeof data === 'string' ? { message: data } : data;
    const SIZE_DIALOG = '30rem';
    const className = config.className ?? 'mat-modal';

    const confDialog = {
      width: SIZE_DIALOG,
      data: config,
      disableClose: config.disableClose ?? true,
      panelClass: className,
    };
    this.dialogRef = this._dialog.open(component, confDialog);
    return this.dialogRef;
  }

  private _closeDialogBeforeShow() {
    if (this.dialogRef) {
      this.dialogRef.close('New content');
      this.dialogRef = undefined;
    }
  }

  public closeDialog() {
    if (this.dialogRef) {
      this.dialogRef.close('Close dialog');
      this.dialogRef = undefined;
    }
  }

  public getUrlByChangeEvent() {
    return this._router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );
  }

  public isRouteAllowToBackButton(url: string): boolean {
    const allowedRoutes = CONST.ALLOWED_ROUTES_TO_GOBACK;
    const hasRoute = allowedRoutes.find(
      (route) => route === url || url.match(route)
    );
    return !!hasRoute;
  }
  public isRouteAllowToBurgerMenu(url: string): boolean {
    const allowedRoutes = CONST.ALLOWED_ROUTES_TO_BURGER_MENU;
    const hasRoute = allowedRoutes.find(
      (route) => route === url || route.match(url)
    );
    return !!hasRoute;
  }

  public onDownloadFiles(url: string) {
    if (!url || typeof url !== 'string') return;
    window.open(url, '_blank', 'noopener noreferrer');
  }

  public copyText(text: string) {
    const el = document.createElement('input');
    el.value = text;
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    el.setSelectionRange(0, 99999);
    document.execCommand('copy');
    el.remove();

    this.showSnackbar('Copiado', 3000, 'mat-simple-snackbar-success');
  }

  public showSnackbar(msg: string, time = 5000, className = 'snackbar-error') {
    this._snackRef = this._snackBar.open(msg, 'x', {
      duration: time,
      panelClass: [className],
    });
    this._snackRef.onAction().subscribe(() => {
      this._snackRef?.dismiss();
    });
  }

  public _scrollSimulator = () => {
    document.getElementById('top-view')?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  public downloadAccountStatus(url: string) {
    var link = document.createElement('a');
    link.download = 'Estado de cuenta';
    link.href = url;
    link.click();
  }

  public downloadPaymentPlan(url: string) {
    var link = document.createElement('a');
    link.target = '_blank';
    link.download = 'Plan de Pagos';
    link.href = url;
    link.click();
  }

  public downloadBarcode(url: string) {
    var link = document.createElement('a');
    link.download = 'Código de barras';
    link.href = url;
    link.click();
  }
  public downloadPaymentPlanSimulator(url: string) {
    var link = document.createElement('a');
    link.download = 'Plan de pagos simulador';
    link.href = url;
    link.click();
  }
}
