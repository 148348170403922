import { ComponentType } from '@angular/cdk/portal';
import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GLOBAL_CONSTANTS } from '@app/core/constants/global.constants';
import { URLS } from '@app/data/mo-api/url.constants';
import { ModalSignupErrorComponent } from '@app/presentation/mo-layout/components/modal/modal-signup-error/modal-signup-error.component';
import { Modal } from '@app/presentation/mo-layout/components/modal/modal.base';
import { environment } from '@environment';

import { ERROR_CONSTANTS } from '../../core/constants/error.constans';
import { UIService } from '../../core/services/ui.service';

const txtErrorExistUserInSignup = 'There is a customer with this username';
const API_URL = environment.api_url;
interface ErrorData {
  url: string;
  showGlobalError?: boolean;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  errorMessage?: string | ((error: HttpErrorResponse) => string);
  modal?: ComponentType<Modal>;
}

export const CONFIG_ERRORS: ErrorData[] = [
  { url: URLS.SEND_OTP_CODE, modal: ModalSignupErrorComponent },
  { url: '/auth/signup/', showGlobalError: false }
];

export const BACKEND_MESSAGES: { [key: string]: string } = {
  'The username and password you entered did not match our records. Please double-check and try again.':
    'El número de celular o contraseña ingresados son incorrectos, inténtalo nuevamente',
  'User is inactive.':
    'Tu usuario está inactivo por el momento, para desbloquear tu cuenta contáctate con uno de nuestros asesores',
  'There is a customer with this username':
    'Tu número celular ya se encuentra registrado, por favor inicia sesión o contáctanos',
  'the user code type "sign_up" does not exist':
    ' El código ingresado no es válido, solicita uno nuevo dando clic en reenviar',
  'create_code_otp_signup :: code is expired':
    'El código ingresado expiró, solicita uno nuevo dando clic en reenviar',
  'The code is invalid.':
    'El código ingresado no es válido, solicita uno nuevo dando clic en reenviar',
  'The code is expired.': 'El código ingresado expiró, solicita uno nuevo dando clic en reenviar',
  'The code is already used.':
    'El código ingresado fue utilizado anteriormente, solicita uno nuevo dando clic en reenviar',
  'No exist a code for this customer':
    'Tuvimos un problema con tu código, solicita uno nuevo dando clic en reenviar',
  'The passwords are not equal.':
    'Las contraseñas que ingresaste son diferentes, por favor digítalas nuevamente. ',
  'This password has already been used':
    'La contraseña que ingresaste ya la habías utilizado anteriormente, por favor digita una nueva',
  'The password can be changed after 24 h':
    '¡Algo anda mal! Estás intentando cambiar tu contraseña en menos de 24 horas, si no has sido tú contáctate con uno de nuestros asesores',
  'The password is too similar to the %(verbose_name)s.':
    'La contraseña que ingresaste es poco segura, por favor digítala nuevamente',
  'This password is too common.':
    ' La contraseña que ingresaste es poco segura, por favor digítala nuevamente',
  'User does not exist.': 'No encontramos tu número de celular, por favor regístrate o contáctanos',
  'the user code type "account_activation" does not exist':
    'Tuvimos un problema con tu código, solicita uno nuevo dando clic en reenviar',
  'get_or_create_reset_password_code :: code is expired':
    'El código ingresado expiró, solicita uno nuevo dando clic en reenviar',
  'Document name incorrect': '¡Lo sentimos! Tuvimos un problema, por favor inténtalo nuevamente',
  'error on generate presigned url to s3':
    '¡Lo sentimos! Tuvimos un problema, por favor inténtalo nuevamente',
  'No exist extra info': '',
  'There is no a loan':
    '¡Algo anda mal! En este momento no tienes una solicitud de crédito pendiente de desembolso. Si tienes algún problema contáctanos',
  'There is a transaction pending':
    'Tu desembolso ya está en proceso. Si tienes algún problema para ver el dinero en tu cuenta contáctanos',
  'The status change is incorrect: {previous_status.name}':
    '¡Lo sentimos! Tuvimos un problema, por favor inténtalo nuevamente',
  'There is no a loan active':
    'En este momento no tienes una deuda pendiente con nosotros. Si tienes algún problema contáctanos',
  'error on generate_transaction :: {err}':
    '¡Lo sentimos! Tuvimos un problema, por favor inténtalo nuevamente',
  'No exist state':
    '¡Lo sentimos! No encontramos el estado que estás buscando, por favor inténtalo nuevamente',
  'There is no a data state in dynamo': '',
  'This email is in use':
    'El correo ingresado ya se encuentra registrado, por favor verifícalo o contáctanos por WhatsApp',
  'There are loans for the customer':
    '¡Algo anda mal! En este momento no tienes una solicitud de crédito pendiente de desembolso. Si tienes algún problema contáctanos',
  'There is no a transaction with that numerical reference': '',
  'There is a loan pending':
    '¡Algo anda mal! En este momento no tienes una solicitud de crédito pendiente. Si tienes algún problema contáctanos',
  'The config id does not match the customer':
    'El plazo seleccionado no es válido, por favor verifícalo o contáctanos”',
  'The customer is waiting for loan info':
    'Estamos preparando la información de tu crédito, si tienes algún problema contáctanos',
  'The customer has loans that are not confirmed yet':
    ' Estamos preparando tu crédito, si tienes algún problema contáctanos',
  'The loan value is higher than available preapproved amount':
    'El monto seleccionado es mayor al saldo disponible, por favor verifícalo o contáctanos',
  'There is no a contract': '¡Lo sentimos! Tuvimos un problema, por favor inténtalo nuevamente',
  'No exist loan extra info': 'El préstamo no tiene una información adicional',
  'No exist a loan fee': '¡Lo sentimos! Tuvimos un problema, por favor inténtalo nuevamente',
  'There is no a loan fee':
    'En este momento no tienes una cuota pendiente con nosotros. Si tienes algún problema contáctanos',
  'Transaction does not exist':
    '¡Lo sentimos! No logramos identificar tu pago, inténtalo nuevamente o contáctanos',
  'User does not exists.': 'La cliente no existe',
  'This curp is in use': 'El CURP ingresado ya ha sido usado',
  'No exist the code': '¡Algo anda mal! Por favor ingresa un código de referido válido!',
  'There is a transaction still pending':
    'Hay  una transacción en proceso, si tienes algún problema comunicate con nosotros. ',
};

@Injectable({ providedIn: 'root' })
export class ErrorService {
  constructor(private _ui: UIService) {}

  public handleError(error: HttpErrorResponse, req?: HttpRequest<any>) {
    const url = error.url ?? '';
    const isUnathorized = error.status === GLOBAL_CONSTANTS.HTTP_CODES.UNAUTHORIZED;
    const isUncontrolledError =
      error.status === GLOBAL_CONSTANTS.HTTP_CODES.INTERNAL_SERVER_ERROR ||
      error.status === GLOBAL_CONSTANTS.HTTP_CODES.ERROR;

    if (isUnathorized) {
      this.showMessageError('La sesión es inválida o ha finalizado! Por favor ingrese nuevamente');
      return;
    }

    if (isUncontrolledError) {
      this.showMessageError('Ha ocurrido un error vuelve a intentar de nuevo');
      return;
    }

    let errorMessage = ERROR_CONSTANTS.MESSAGES.ERROR;
    const errorConfig = CONFIG_ERRORS.find((route) => {
      const urlRoute = `(^${environment.isTestServer ? '' : API_URL}${route.url}$)`;
      let isSameRoute = new RegExp(urlRoute, 'g').test(url);
      const isSameMethod = !route?.method || route?.method === req?.method;
      const isErrorUser = txtErrorExistUserInSignup === this._getErrorMessage(error) ? true: false;

      return isSameRoute && isSameMethod && isErrorUser;
    });

    if (!errorConfig) {
      const backendMessage = this._getErrorMessage(error);
      if (backendMessage && BACKEND_MESSAGES[backendMessage]) {
        errorMessage = BACKEND_MESSAGES[backendMessage];
      } else {
        return;
      }
    } else if (
      typeof errorConfig?.showGlobalError !== 'undefined' &&
      !errorConfig?.showGlobalError
    ) {
      return;
    }

    if (errorConfig?.errorMessage) {
      const msg = errorConfig.errorMessage;
      errorMessage = typeof msg === 'function' ? msg(error) : msg;
    } else {
    }

    this._ui.showDialog(errorMessage, errorConfig?.modal);
  }

  public showMessageError(message: string) {
    this._ui.showDialog({ message, type: 'alert' });
  }

  private _getErrorMessage(error: HttpErrorResponse): string {
    if (!error.error) return '';

    const err = error.error;
    const firstError = Object.keys(err)[0];
    const typeError = typeof err[firstError];
    const isStringError = typeError === 'string';

    if (!isStringError && typeError === 'object') {
      return this._getErrorMessage(err[firstError][0]);
    }
    return err[firstError];
  }
}
