export interface IOnboardingStep {}

export interface IOnboardingPersonalInfo extends IOnboardingStep {
  name: string;
  lastName: string;
  dateOfBirth: string;
  gender: string;
  rfc: string;
  curp: string;
  fiel: string;//Firma Electrónica Avanzada
}

export interface IOnboardingAddress extends IOnboardingStep {
  street: string;
  exteriorNumber: string;
  interiorNumber: string;
  zipCode: string;
  colony: string;
  country: string;
  state: string;
  city: string;
  municipality: string;
}

export interface IOnboardingOther extends IOnboardingStep {
  profession: string;
  dependentPersons: number;
  email: string;
  loanFor: string;
  codeRefer: string;
}

export interface IOnboardingPEP extends IOnboardingStep {
  exposedPerson: boolean;
  institutionPosition: string;
  kinship: string;
  creditIsThird: boolean;
  nameCreditHolder: string;
  reason: string;
  selfIdentity: string;
}

export interface IOnboardingIncome extends IOnboardingStep {
  civilStatus: string;
  studyLevel: string;
  ownHome: string;
  checkTycElectronicMedia: boolean;
  monthlyIncome: number;
  monthlyExpenses: string;
  hasGovSupport: boolean;
}

export enum IDENTITY_VALIDATION_STATUS {
  PENDING = 'PENDING',
  UNDER_REVIEW = 'UNDER_REVIEW',
  MANUAL_REVIEW = 'MANUAL_REVIEW',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  UNFINISHED = 'UNFINISHED',
}

export type IdentityValidationStatus = IDENTITY_VALIDATION_STATUS;

export type OnboardingValidationData = {
  applicationId: string;
  status: IdentityValidationStatus;
  expiredAt: Date;
};

export enum CUSTOMER_VALIDATION_STATUS {
  APPROVED = 1,
  REJECTED = 2,
}

export type CustomerValidationsStatus = CUSTOMER_VALIDATION_STATUS;

export type CustomerValidationsStep = {
  component: 'score' | 'identity';
  status: CustomerValidationsStatus;
  reason: string;
};

export type Locations = {
  city: string[];
  colony: string[];
  municipality: string[];
};

export type Location = {
  zipCode?: string;
  colony: string[]|null|string;
  city: string;
  municipality: string;
  state: string;
};
