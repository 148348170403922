import { GLOBAL_CONSTANTS as CONST } from '@app/core/constants/global.constants';
export const APP_CONSTANTS = {
  ALLOWED_ROUTES_TO_GOBACK: [
    CONST.COMPLETE_ROUTES.ONBOARDING_PERSONAL_INFO,
    CONST.COMPLETE_ROUTES.ONBOARDING_ADDRESS,
    CONST.COMPLETE_ROUTES.ONBOARDING_OTHER_INFO,
    CONST.COMPLETE_ROUTES.ONBOARDING_PEP,
    CONST.COMPLETE_ROUTES.ONBOARDING_INCOME,
  ],
  ALLOWED_ROUTES_TO_BURGER_MENU: [
    CONST.ROUTES.HOME,
    CONST.COMPLETE_ROUTES.HOME_INTENTION_LOAN_REQUEST,
    CONST.ROUTES.LOAN,
    CONST.ROUTES.PAYMENT,
    CONST.ROUTES.PROFILE,
    CONST.ROUTES.SUPPORT,
    `${CONST.ROUTES.SUPPORT}${CONST.ROUTES.LIST_FAQ}`,
    `${CONST.ROUTES.SUPPORT}${CONST.ROUTES.LIST_TIPS}`,
    CONST.ROUTES.DISBURSEMENT,
    CONST.COMPLETE_ROUTES.ONBOARDING_REJECTED
  ],
};
