import { createAction, props } from '@ngrx/store';
import {
  Bank,
  BankInfo,
  DISBURSEMENT_STATUS,
  ILoanLegalDocuments,
  LoanDetails,
  LoanMath,
  LoanParams,
} from '../../../core/models/loan.model';

// TODO: No está haciendo nada
export const visitFormLoan = createAction(
  '[Request Loan] Enter to the form loan',
  props<{ loan: LoanParams }>()
);
export const calculateLoanMath = createAction(
  '[Request Loan] Calculate loan math',
  props<{ loan: LoanParams }>()
);
export const successCalculateLoanMath = createAction(
  '[API] Success calculate loan math',
  props<{ loanMath: LoanMath }>()
);
export const failureCalculateLoanMath = createAction(
  '[API] Failure calculate loan math'
);

export const getDynamicDocuments = createAction(
  '[Request Loan] Get Loan Dynamic Documents',
  props<{ documentName: Array<string> }>()
);

export const successGetDynamicDocuments = createAction(
  '[Request Loan] Get Successfully Loan Dynamic Documents',
  props<{ dynamicDocuments: ILoanLegalDocuments }>()
);

export const failureGetDynamicDocuments = createAction(
  '[Request Loan] Failure Loan Dynamic Documents'
);

export const getBankList = createAction('[Request Loan] Get Bank List');

export const successGetBankList = createAction(
  '[Request Loan] Get Successfully Bank List',
  props<{ bankList: Bank[] }>()
);

export const failureGetBankList = createAction(
  '[Request Loan] Failure Get Bank List'
);

export const requestLoan = createAction('[Request Loan] request loan');
export const successRequestLoan = createAction('[API] Success request loan');
export const failureRequestLoan = createAction('[API] Failure request loan');
export const acceptLoan = createAction('[Request Loan] Accept values loan');
export const setPreviousStep = createAction(
  '[Request Loan] Set a previous step '
);

// #region disbursement
export const disburseLoan = createAction(
  '[Disbursement Loan] Request disburse loan',
  props<{ bankInfo: BankInfo }>()
);
export const successRequestDisburseLoan = createAction(
  '[API] Success the request to disburse loan'
);
export const failureRequestDisburseLoan = createAction(
  '[API] Failure the request to disburse loan'
);

export const startGetStatusDisburseLoan = createAction(
  '[Disbursement Loan] Start Get Status Disburse Loan'
);
export const successStartGetStatusDisburseLoan = createAction(
  '[Disbursement Loan] Success Get Status Disburse Loan'
);
export const failureStartGetStatusDisburseLoan = createAction(
  '[Disbursement Loan] Failure Get Status Disburse Loan'
);
export const finishGetStatusDisburseLoan = createAction(
  '[Disbursement Loan] Finish Get Status Success Disburse Loan',
  props<{ disburseStatus: DISBURSEMENT_STATUS }>()
);
export const restartDisbursementError = createAction(
  '[Disbursement Loan] Request if user will be enable to request the disbursement again'
);
export const successRestartDisbursementError = createAction(
  '[Disbursement Loan] Success user will be enable to request the disbursement again'
);

// #endregion

export const getCurrentLoan = createAction('[Home] Get current loan details');
export const successGetCurrentLoan = createAction(
  '[API] Success get current loan details',
  props<{ loan: LoanDetails }>()
);

export const downloadLegalDocument = createAction(
  '[Home Modal] Download legal document',
  props<{ documentName: string }>()
);
export const successDownloadLegalDocument = createAction(
  '[API] Success download legal document'
);

export const failureDownloadLegalDocument = createAction(
  '[API] Failure download legal document'
);

export const failureGetCurrentLoan = createAction(
  '[API] Failure get current loan details'
);

export const downloadPaymentPlanSimulator = createAction(
  '[Home Modal] Download Payment Plan Simulator'
);
export const successDownloadPaymentPlanSimulator = createAction(
  '[API] Success download Payment Plan Simulator',
  props<{ url: string }>()
);
export const failureDownloadPaymentPlanSimulator = createAction(
  '[API] Failure download Payment Plan Simulator'
);
export const downloadPaymentPlan = createAction(
  '[Home Modal] Download Payment Plan '
);

export const successDownloadPaymentPlan = createAction(
  '[API] Success download Payment Plan ',
  props<{ url: string }>()
);
export const failureDownloadPaymentPlan = createAction(
  '[API] Failure download Payment Plan '
);

export const downloadAccountStatus = createAction(
  '[Home Modal] Download account status'
);

export const successDownloadAccountStatus = createAction(
  '[API] Success download account status',
  props<{ url: string }>()
);

export const failureDownloadAccountStatus = createAction(
  '[API] Failure download account status'
);

export const goToRequestLoan = createAction('[Home] Go To Request Loan');
export const blockFlowLoanDisbursment = createAction('[Loan] Block Flow Loan');
