import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from './app.state';
import * as appActions from './app.actions';
import { isDisabled } from './app.actions';

export const initialAppState: AppState = {
  isOpenMenu: false,
  modalConfig: {
    show: false,
  },
  showGoBackButton: false,
  showMenuIcon: false,
  isDisabled: false,
};

const _appReducer = createReducer(
  initialAppState,
  on(appActions.collapseMenu, (state) => {
    return { ...state, isOpenMenu: false };
  }),
  on(appActions.expandMenu, (state) => {
    return { ...state, isOpenMenu: true };
  }),
  on(appActions.setModalConfig, (state, { modalConfig }) => {
    return { ...state, modalConfig };
  }),
  on(appActions.closeModal, (state) => {
    const modalConfig = { show: false };
    return { ...state, modalConfig };
  }),
  on(appActions.showGoBackButton, (state, { showGoBackButton }) => {
    return { ...state, showGoBackButton };
  }),
  on(appActions.showMenuIcon, (state, { showMenuIcon }) => {
    return { ...state, showMenuIcon };
  }),
  on(appActions.chageRoute, (state, { route }) => {
    return { ...state, currentRoute: route };
  }),
  on(appActions.isDisabled, (state, { isDisabled }) => {
    return { ...state, isDisabled };
  })
);

export function AppReducers(state: AppState | undefined, action: Action) {
  return _appReducer(state, action);
}
