import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
  selector: "layout-icon",
  templateUrl: "./icon.component.html",
  styleUrls: ["./icon.component.scss"],
})
export class IconComponent implements OnChanges {
  @Input() icon!: string;
  @Input() type?: "image" | "material" | "mo";
  @Input() color?: string;
  @Input() size?: string;

  constructor() {
    if (!this.type) this.type = "material";
    if (!this.color) this.color = "transparent";
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isDefaultType = !this.type || this.type === "material";
    if (changes["icon"] && !!this.icon && isDefaultType) {
      const isMOIcon = this.icon.startsWith("mo-");
      if (isMOIcon) this.type = "mo";

      const isImage = this.icon.startsWith("image/");
      if (isImage) {
        this.type = "image";
        this.icon = this.icon.substring(6);
      }
    }
  }
}
