import { ACTION_STATUS } from '@app/core/models/app.model';
import { OnboardingState } from './onboarding.state';
import { createReducer, on, Action } from '@ngrx/store';
import * as onboardingActions from './onboarding.actions';

export const initialOnboardingState: OnboardingState = {
  step: 0,
  stepInfoStatus: ACTION_STATUS.INITIAL,
  setStepInfoStatus: ACTION_STATUS.INITIAL,
};

const _onboardingReducer = createReducer(
  initialOnboardingState,

  on(onboardingActions.getOnboardingInfoByStep, (state, { step }) => {
    return {
      ...state,
      step,
      stepInfo: undefined,
      stepInfoStatus: ACTION_STATUS.PENDING,
    };
  }),
  on(onboardingActions.successGetOnboardingInfoByStep, (state, { stepInfo }) => {
    return {
      ...state,
      stepInfo,
      stepInfoStatus: ACTION_STATUS.SUCCESS,
    };
  }),
  on(onboardingActions.failureGetOnboardingInfoByStep, (state) => {
    return {
      ...state,
      stepInfoStatus: ACTION_STATUS.FAILURE,
    };
  }),
  on(onboardingActions.saveOnboardingInfo, (state) => {
    return {
      ...state,
      setStepInfoStatus: ACTION_STATUS.PENDING,
    };
  }),
  on(onboardingActions.successSaveOnboardingInfo, (state, { step }) => {
    return {
      ...state,
      step,
      setStepInfoStatus: ACTION_STATUS.SUCCESS,
    };
  }),
  on(onboardingActions.successFinishOnboarding, (state) => {
    return {
      ...state,
      setStepInfoStatus: ACTION_STATUS.SUCCESS,
    };
  }),
  on(onboardingActions.failureSaveOnboardingInfo, (state) => {
    return {
      ...state,
      setStepInfoStatus: ACTION_STATUS.FAILURE,
    };
  }),
  on(onboardingActions.finishIdentityValidationSDK, (state) => {
    return {
      ...state,
      isLoadingIdentityValidation: true,
    };
  }),
  on(onboardingActions.successGetMetadataToIdentityValidation, (state, metadata) => {
    return {
      ...state,
      isLoadingGetMetadata: true,
    };
  }),
  on(onboardingActions.successGetMetadataToIdentityValidation, (state, { metadata }) => {
    return {
      ...state,
      metadataToIdentityValidation: metadata,
      isLoadingGetMetadata: false,
    };
  }),
  on(onboardingActions.failureGetMetadataToIdentityValidation, (state) => {
    return { ...state, isLoadingGetMetadata: false };
  }),
  on(onboardingActions.changeZipCode, (state) => {
    return { ...state, isLoadingGetLocationByZipCode: true };
  }),
  on(onboardingActions.successGetLocationsByZipCode, (state, { location }) => {
    return { ...state, location, isLoadingGetLocationByZipCode: false, emptyByZipcode: false };
  }),
  on(onboardingActions.successEmptyByZipCode, (state) => {
    return { ...state, isLoadingGetLocationByZipCode: false, emptyByZipcode: true };
  }),
  on(onboardingActions.failureGetLocationsByZipCode, (state) => {
    return { ...state, isLoadingGetLocationByZipCode: false, emptyByZipcode: false };
  }),
  on(onboardingActions.showLoaderSuccess, (state) => {
    return { ...state, isLoadingValidations: true };
  }),
  on(
    onboardingActions.approveKYCValidation,
    onboardingActions.failureFinishIdentityValidation,
    onboardingActions.successFinishIdentityValidation,
    onboardingActions.successCustomerValidations,
    onboardingActions.failureCustomerValidations,
    (state) => {
      return { ...state, isLoadingValidations: false };
    }
  ),
  on(onboardingActions.showOtpView, (state) => {
    return {
      ...state,
      isStepOtp: true,
      setStepInfoStatus: ACTION_STATUS.SUCCESS,
    };
  }),
  on(onboardingActions.hideOtpViewReturnIncome,
     onboardingActions.hideOtpViewNextMati, (state) => {
    return { 
      ...state,
      isStepOtp: false,
    };
  }),
  on(onboardingActions.onboardingResendOTP, (status) => {
    return { ...status, sendOtpStatus: ACTION_STATUS.PENDING, };
  }),
  on(onboardingActions.responseOnboardingResendOTPCode, (status) => {
    return { ...status, sendOtpStatus: ACTION_STATUS.SUCCESS, };
  }),
  on(onboardingActions.failureOnboardingResendOTPCode, (status) => {
   return { ...status, sendOtpStatus: ACTION_STATUS.FAILURE, };
 }),
 on(onboardingActions.validateOTP, (status) => {
   return { ...status, validateOtpstatus: ACTION_STATUS.PENDING};
 }),
 on(onboardingActions.responseValidateOTP, (status) => {
   return { ...status, validateOtpstatus: ACTION_STATUS.SUCCESS};
 }),
 on(onboardingActions.failureValidateOTP, (status) => {
  return { ...status, validateOtpstatus: ACTION_STATUS.FAILURE};
 }),
);

export function OnboardingReducers(state: OnboardingState | undefined, action: Action) {
  return _onboardingReducer(state, action);
}
