import { IOnboardingStep as IOnboardingStep } from '../../core/models/onboarding.model';
import { ONBOARDING_CONSTANTS as CONST } from '../../core/constants/onboarding.constants';
import {
  IOnboardingPersonalInfo,
  IOnboardingAddress,
  IOnboardingOther,
  IOnboardingPEP,
  IOnboardingIncome,
} from '../../core/models/onboarding.model';

export class OnboardingFactory {
  public static createOnbordingStep(infoStep: IOnboardingStep, step: number) {
    if (step === CONST.STEPS.PERSONALINFO) return new OnboardingStepOne(infoStep);

    if (step === CONST.STEPS.ADDRESS) return new OnboardingStepTwo(infoStep);

    if (step === CONST.STEPS.OTHER) return new OnboardingStepThree(infoStep);

    if (step === CONST.STEPS.PEP) return new OnboardingStepFour(infoStep);

    if (step === CONST.STEPS.INCOME) return new OnboardingStepFive(infoStep);

    if (step === CONST.STEPS.MATI) return new OnboardingStepFive(infoStep);

    return;
  }
}

export class OnboardingStepOne {
  public name: string;
  public lastName: string;
  public dateOfBirth: string | Date;
  public gender: string;
  public rfc: string;
  public curp: string;
  public fiel: string;

  constructor(infoStep: IOnboardingStep) {
    const data = infoStep as IOnboardingPersonalInfo;
    this.name = data.name;
    this.lastName = data.lastName;
    this.dateOfBirth = new Date(data.dateOfBirth.replace(/-/g, '\/').replace(/T.+/, ''));
    this.gender = data.gender;
    this.rfc = data.rfc;
    this.curp = data.curp;
    this.fiel = data.fiel;
  }
}

export class OnboardingStepTwo {
  public street: string;
  public exteriorNumber: string;
  public interiorNumber: string;
  public zipCode: string;
  public colony: string;
  public state: string;
  public city: string;
  public country: string;
  public municipality: string;

  constructor(infoStep: IOnboardingStep) {
    const data = infoStep as IOnboardingAddress;
    this.street = data.street;
    this.exteriorNumber = data.exteriorNumber;
    this.interiorNumber = data.interiorNumber;
    this.zipCode = data.zipCode;
    this.colony = data.colony;
    this.state = data.state;
    this.city = data.city;
    this.municipality = data.municipality;
    this.country = data.country;
  }
}

export class OnboardingStepThree implements IOnboardingOther {
  public profession: string;
  public dependentPersons: number;
  public email: string;
  public loanFor: string;
  public codeRefer: string;

  constructor(infoStep: IOnboardingStep) {
    const data = infoStep as IOnboardingOther;
    this.profession = data.profession;
    this.dependentPersons = data.dependentPersons;
    this.email = data.email;
    this.loanFor = data.loanFor;
    this.codeRefer = data.codeRefer;
  }
}

export class OnboardingStepFour {
  public exposedPerson: boolean;
  public institutionPosition: string;
  public kinship: string;
  public creditIsThird: boolean;
  public selfIdentity: string;
  public nameCreditHolder: string;
  public reason: string;

  constructor(infoStep: IOnboardingStep) {
    const data = infoStep as IOnboardingPEP;
    this.exposedPerson = data.exposedPerson;
    this.institutionPosition = data.institutionPosition;
    this.kinship = data.kinship;
    this.creditIsThird = data.creditIsThird;
    this.selfIdentity = data.selfIdentity;
    this.nameCreditHolder = data.nameCreditHolder;
    this.reason = data.reason;
  }
}

export class OnboardingStepFive {
  public civilStatus: string;
  public studyLevel: string;
  public ownHome: string;
  public monthlyIncome: number;
  public monthlyExpenses: string;
  public hasGovSupport: boolean;

  constructor(infoStep: IOnboardingStep) {
    const data = infoStep as IOnboardingIncome;
    this.civilStatus = data.civilStatus;
    this.studyLevel = data.studyLevel;
    this.ownHome = data.ownHome;
    this.monthlyIncome = data.monthlyIncome;
    this.monthlyExpenses = data.monthlyExpenses;
    this.hasGovSupport = data.hasGovSupport;
  }
}
export class OnboardingStepSix {
  constructor(infoStep: IOnboardingStep) {}
}
