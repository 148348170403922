import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoanState } from './loan.state';

const getLoanFeatureState = createFeatureSelector<LoanState>('loan');

export const selectLoanStep = createSelector(getLoanFeatureState, (state: LoanState) => state.step);

export const selectLoanDynamicDocuments = createSelector(
  getLoanFeatureState,
  (state: LoanState) => state.dynamicDocuments
);
export const selectBankList = createSelector(
  getLoanFeatureState,
  (state: LoanState) => state.bankList ?? []
);
export const selectLoanParams = createSelector(
  getLoanFeatureState,
  (state: LoanState) => state.loanParams
);
export const selectLoanMath = createSelector(
  getLoanFeatureState,
  (state: LoanState) => state.loanMath
);

export const selectIsLoadingCalculateLoanMath = createSelector(
  getLoanFeatureState,
  (state: LoanState) => state.isLoadingCalculateLoanMath ?? false
);

export const selectIsLoadingDynamicDocuments = createSelector(
  getLoanFeatureState,
  (state: LoanState) => state.isLoadingDynamicDocuments ?? false
);

export const selectIsLoadingRequestLoan = createSelector(
  getLoanFeatureState,
  (state: LoanState) => state.isLoadingRequestLoan ?? false
);

export const selectIsLoadingDisburseLoan = createSelector(
  getLoanFeatureState,
  (state: LoanState) => state.isLoadingDisburseLoan ?? false
);

export const selectCurrentLoan = createSelector(
  getLoanFeatureState,
  (state: LoanState) => state.currentLoan
);

export const selectIsLoadingGetCurrentLoan = createSelector(
  getLoanFeatureState,
  (state: LoanState) => state.isLoadingGetCurrentLoan ?? false
);

export const selectStateTransactionDisbursement = createSelector(
  getLoanFeatureState,
  (state: LoanState) => state.disbursementState ?? null
);
