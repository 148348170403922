import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DevelopGuard } from './core/guards/develop.guard';
import { DisbursementGuard } from './core/guards/disbursement.guard';
import { LoggedGuard } from './core/guards/logged.guard';
import { OnboardingGuard } from './core/guards/onboarding.guard';
import { UnloggedGuard } from './core/guards/unlogged.guard';

const routes: Routes = [
  {
    path: 'ui-kit',
    loadChildren: () =>
      import('./presentation/features/ui-kit/ui-kit.module').then(
        (m) => m.UiKitModule
      ),
    canActivateChild: [DevelopGuard],
  },
  {
    path: 'tu-informacion',
    loadChildren: () =>
      import('./presentation/features/onboarding/onboarding.module').then(
        (m) => m.OnboardingModule
      ),
    canActivateChild: [LoggedGuard, OnboardingGuard],
  },
  {
    path: 'credito',
    loadChildren: () =>
      import('./presentation/features/loan/loan.module').then(
        (m) => m.LoanModule
      ),
    canActivateChild: [LoggedGuard],
  },
  {
    path: 'recibe-tu-credito',
    loadChildren: () =>
      import('./presentation/features/disbursement/disbursement.module').then(
        (m) => m.DisbursementModule
      ),
    canActivateChild: [LoggedGuard, DisbursementGuard],
  },
  {
    path: 'inicio',
    loadChildren: () =>
      import('./presentation/features/home/home.module').then(
        (m) => m.HomeModule
      ),
    canActivateChild: [LoggedGuard],
  },
  {
    path: 'pagar',
    loadChildren: () =>
      import('./presentation/features/payments/payments.module').then(
        (m) => m.PaymentsModule
      ),
    canActivateChild: [LoggedGuard],
  },
  {
    path: 'soporte',
    loadChildren: () =>
      import('./presentation/features/support/support.module').then(
        (m) => m.SupportModule
      ),
    canActivateChild: [LoggedGuard],
  },
  {
    path: 'perfil',
    loadChildren: () =>
      import('./presentation/features/profile/profile.module').then(
        (m) => m.ProfileModule
      ),
    canActivateChild: [LoggedGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./presentation/features/auth/auth.module').then(
        (m) => m.AuthModule
      ),
    canActivateChild: [UnloggedGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
