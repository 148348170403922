import { Pipe, PipeTransform } from '@angular/core';
import { COUNTRY_CONSTANTS } from '@app/core/constants/country.constants';
import { GLOBAL_CONSTANTS } from '@app/core/constants/global.constants';

@Pipe({ name: 'MOCurrency' })
export class MOCurrencyPipe implements PipeTransform {
  transform(value?: any, ...args: any[]): string {
    value = value ? value : 0;
    const [countryArgument, decimals] = args;
    const country = countryArgument || GLOBAL_CONSTANTS.DEFAULT_COUNTRY;
    const selectedCountry = COUNTRY_CONSTANTS[country];
    const formatValue = new Intl.NumberFormat(selectedCountry.language, {
      style: 'currency',
      currency: selectedCountry.code,
      minimumFractionDigits: decimals ?? selectedCountry.decimals,
    }).format(Number(value));
    return `${formatValue}`;
  }
}
