import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";

@Directive({
  selector: "[MOTooltip]",
})
export class TooltipDirective<U> {
  @Input("MOTooltip") tooltipTemplate?: TemplateRef<any> | string;
  @Input("tooltipData") tooltipData?: U;
  @Input() placement: "top" | "bottom" | "left" | "right";
  @Input() delay?: number;
  public offset: number;
  public tooltip?: HTMLElement;
  public backdrop?: HTMLElement;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private _viewContainerRef: ViewContainerRef
  ) {
    this.placement = "bottom";
    this.offset = 10;
  }

  @HostListener("click") onClick() {
    this.tooltip ? this.hide() : this.show();
  }

  @HostListener("mouseenter") onMouseEnter() {
    // !this.tooltip && this.show();
  }

  @HostListener("mouseleave") onMouseLeave() {
    // this.tooltip && this.hide();
  }

  show() {
    this.create();
    this.setPosition();
    this.renderer.addClass(this.tooltip, "ng-tooltip-show");
  }

  hide() {
    this.renderer.removeClass(this.tooltip, "ng-tooltip-show");
    this.renderer.removeClass(this.backdrop, "ng-tooltip-backdrop");
    window.setTimeout(() => {
      this.renderer.removeChild(document.body, this.tooltip);
      this.renderer.removeChild(document.body, this.backdrop);
      this.tooltip = undefined;
      this.backdrop;
    }, this.delay);
  }

  create() {
    this.tooltip = this.renderer.createElement("div");
    this.backdrop = this.renderer.createElement("div");

    if (this.tooltipTemplate instanceof TemplateRef) {
      // Template
      const embeddedViewRef = this._viewContainerRef.createEmbeddedView(
        this.tooltipTemplate,
        { row: this.tooltipData }
      );
      embeddedViewRef.detectChanges();

      embeddedViewRef.rootNodes.forEach((n) => {
        this.renderer.appendChild(this.tooltip, n);
      });
    } else {
      // Label
      this.renderer.appendChild(
        this.tooltip,
        this.renderer.createText(this.tooltipTemplate ?? "")
      );

      this.renderer.appendChild(document.body, this.tooltip);
    }

    this.renderer.appendChild(this.el.nativeElement, this.tooltip);
    this.renderer.appendChild(this.el.nativeElement, this.backdrop);

    this.renderer.addClass(this.backdrop, "ng-tooltip-backdrop");
    this.renderer.addClass(this.tooltip, "ng-tooltip");
    this.renderer.addClass(this.tooltip, `ng-tooltip-${this.placement}`);

    this.renderer.setStyle(
      this.tooltip,
      "-webkit-transition",
      `opacity ${this.delay}ms`
    );
    this.renderer.setStyle(
      this.tooltip,
      "-moz-transition",
      `opacity ${this.delay}ms`
    );
    this.renderer.setStyle(
      this.tooltip,
      "-o-transition",
      `opacity ${this.delay}ms`
    );
    this.renderer.setStyle(
      this.tooltip,
      "transition",
      `opacity ${this.delay}ms`
    );
  }

  setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect();
    const fallback = { height: 0, width: 0 };
    const tooltipPos = this.tooltip?.getBoundingClientRect() ?? fallback;

    const scrollPos =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    let top, left, right;
    if (this.placement === "top") {
      top = hostPos.top - tooltipPos.height - this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === "bottom") {
      top = hostPos.bottom + this.offset;
      // Take into account that this value is depending of tooltip width
      left = hostPos.left - tooltipPos.width + 40;
    }

    if (this.placement === "left") {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this.offset;
    }

    if (this.placement === "right") {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this.offset;
    }

    this.renderer.setStyle(this.tooltip, "top", `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltip, "left", `${left}px`);
  }
}
