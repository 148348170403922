import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PaymentState } from './payment.state';

const getPaymentFeatureState = createFeatureSelector<PaymentState>('payment');

export const selectPaymentStep = createSelector(
  getPaymentFeatureState,
  (state: PaymentState) => state.step
);

export const methodPayment = createSelector(
  getPaymentFeatureState,
  (state: PaymentState) => state.payment
);

export const isLoadingCreatePayment = createSelector(
  getPaymentFeatureState,
  (state: PaymentState) => state.isLoadingCreatePayment ?? false
);

export const currentDate = createSelector(
  getPaymentFeatureState,
  (state: PaymentState) => state?.currentDate
);
