import { Component, OnInit } from '@angular/core';
import { GLOBAL_CONSTANTS as CONST } from '@app/core/constants/global.constants';
import { CATEGORIES_EVENT, ACTION_EVENT } from '@app/core/constants/analytics.constants';
import { GoogleAnalyticsService } from '@app/domain/services/google-analytics.service';

@Component({
  selector: 'layout-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public privacyPolicyDocument: string;
  public termsAndConditions: string;

  constructor(private _gaService: GoogleAnalyticsService) {
    this.privacyPolicyDocument = CONST.LEGAL_DOCUMENTS.PRIVACY_POLICY;
    this.termsAndConditions = CONST.LEGAL_DOCUMENTS.TERMS_AND_CONDITIONS;
  }

  onOpenTerms() {
    this._gaService.sendEvent(CATEGORIES_EVENT.APP, ACTION_EVENT.CLICK, 'Leer T&C')
  }

  onOpenPrivacyPolicy() {
    this._gaService.sendEvent(CATEGORIES_EVENT.APP, ACTION_EVENT.CLICK, 'Leer PP')
  }
}
