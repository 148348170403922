import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { map, Observable } from 'rxjs';

import { StorageService } from '@app/data/browser/storage.service';
import { GLOBAL_CONSTANTS as CONST } from '../constants/global.constants';

@Injectable({
  providedIn: 'root',
})
export class UnloggedGuard implements CanActivate, CanActivateChild {
  constructor(private _storage: StorageService, private _router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._validateTokenEmpty(route, state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._validateTokenEmpty(route, state);
  }

  private _validateTokenEmpty(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean {
    const token$ = this._storage.getItem(CONST.PROP_STORAGE_TOKEN);

    return token$.pipe(
      map((token) => {
        if (token) this._router.navigateByUrl(CONST.ROUTES.HOME);
        return !token;
      })
    );
  }
}
