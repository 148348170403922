import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IModalParams } from '../modal-config.interface';
import { BankInfo } from '@app/core/models/loan.model';
import { BUTTON_TEMPLATE, COLOR_TEMPLATE, SIZES } from '@app/presentation/mo-forms/enums/fields.type';
import { Modal } from '../modal.base';
import { LoanFacade } from '@app/facades/loan.facade';

@Component({
  selector: 'app-modal-confirmation-data',
  templateUrl: './modal-confirmation-data.component.html',
  styleUrls: ['./modal-confirmation-data.component.scss']
})
export class ModalConfirmationDataComponent extends Modal {
  public resume: BankInfo;
  public BUTTON_SIZE = SIZES;
  public INPUT_TEMPLATES = COLOR_TEMPLATE;
  public BUTTON_TEMPLATES = BUTTON_TEMPLATE;
  // private loading:LoanService = new LoanService(showDialog, )

  constructor(
    public override modalRef: MatDialogRef<ModalConfirmationDataComponent>,
    @Inject(MAT_DIALOG_DATA) public override data: IModalParams,
    private _loanFacade: LoanFacade,
  ) { 
    super(modalRef, data);
    this.resume = data?.data as BankInfo;
  }
  
  public closeModal(): void {
    this.modalRef.close();
  }

  public confirmData(){
    this._loanFacade.disburseLoan(this.resume);
    this.modalRef.close();
  }
}
