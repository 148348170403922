export interface AppData {
  message: string;
}
export interface Token {
  access_token: string;
  token_type?: 'Bearer' | 'Token';
}

export enum ACTION_STATUS {
  DELETING = 'deleting',
  INITIAL = 'initial',
  PENDING = 'loading',
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export type ActionStatus = ACTION_STATUS;

export interface IRedirectByStep {
  REDIRECT_BY_STEP: {
    [key: number]: string;
  };
}
