import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpEventType,
} from '@angular/common/http';
import { filter, map, Observable } from 'rxjs';

@Injectable()
export class AppCamelToSnakeInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const payload = request.body ?? {};

    return next.handle(request.clone({ body: this.camelToSnake(payload) })).pipe(
      filter(
        (event: HttpEvent<any>) =>
          event?.type === HttpEventType.Response && event instanceof HttpResponse
      ),
      map((res: HttpEvent<any>) => {
        const response = res as HttpResponse<any>;
        return response.clone({ body: this.snakeToCamel(response.body ?? {}) });
      })
    );
  }

  private snakeToCamel(body: { [key: string]: any } | any[]) {
    if (Array.isArray(body)) {
      if (body.length > 0 && typeof body[0] !== 'object') return body;
      return body.map((item) => {
        if (typeof item === 'object') {
          return this._objSnakeToCamel(item);
        }
        return item;
      });
    } else {
      return this._objSnakeToCamel(body);
    }
  }

  private _objSnakeToCamel(object: { [key: string]: any }) {
    const newObj: { [key: string]: any } = {};
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        const camel = this._snakeToCamel(key);
        if (Boolean(object[key]) && typeof object[key] === 'object') {
          newObj[camel] = this.snakeToCamel(object[key]);
        } else {
          newObj[camel] = object[key];
        }
      }
    }

    return newObj;
  }

  private _snakeToCamel(str: string) {
    if (str.length === 0) return str;
    if (str === str.toUpperCase()) return str;

    return str
      .toLowerCase()
      .replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
  }

  private camelToSnake(object: { [key: string]: any }) {
    const newObj: { [key: string]: any } = {};
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        const snake = key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
        newObj[snake] = object[key];
      }
    }

    return newObj;
  }
}
