import { PaymentState } from './payment.state';
import { createReducer, Action, on } from '@ngrx/store';
import * as paymentActions from './payment.actions';
import { PAYMENT_TRANSACTION_TYPE } from '@app/core/constants/payment.constants';

export const initialPaymentState: PaymentState = {
  step: 1,
  isLoadingCreatePayment: false,
  url: undefined,
  payment: {
    amount: 0,
    transactionType: PAYMENT_TRANSACTION_TYPE.ACH,
  },
  wireTransfer: {},
  currentDate: new Date(),
};

const _paymentReducer = createReducer(
  initialPaymentState,
  on(paymentActions.requestCreatePayment, (state, { payment }) => {
    return {
      ...state,
      payment,
      isLoadingCreatePayment: true,
    };
  }),
  on(paymentActions.successRequestCreatePayment, (state, { wireTransfer }) => {
    return {
      ...state,
      wireTransfer,
      isLoadingCreatePayment: false,
    };
  }),
  on(paymentActions.failureRequestCreatePayment, (state) => {
    return {
      ...state,
      isLoadingCreatePayment: false,
    };
  }),
  on(paymentActions.successDownloadBarcode, (state, { url }) => {
    return {
      ...state,
      url,
    };
  }),
  on(paymentActions.setStep, (state, { step }) => {
    return {
      ...state,
      step,
    };
  }),
  on(paymentActions.successRequestCancelTransaction, (state) => {
    return {
      ...state,
      step: 1,
    };
  }),
  on(paymentActions.showBarcode, (state) => {
    return {
      ...state,
      isLoadingCreatePayment: false,
    };
  })
);

export function PaymentReducers(
  state: PaymentState | undefined,
  action: Action
) {
  return _paymentReducer(state, action);
}
