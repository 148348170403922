import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppFacade } from '@app/facades/app.facade';
import { getUrlWhatsapp } from '@app/core/services/util';
import { GoogleAnalyticsService } from '@app/domain/services/google-analytics.service';
import { CATEGORIES_EVENT, ACTION_EVENT } from '@app/core/constants/analytics.constants';

@Component({
  selector: 'layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() showMenuIcon: boolean | null;
  @Input() showGoBackButton: boolean | null;
  @Output() goHome = new EventEmitter<boolean | undefined>();

  public isOpenModal: boolean = false;
  public urlWhatsapp: string;

  constructor(private _appFacade: AppFacade, private _gaService: GoogleAnalyticsService) {
    this.showMenuIcon = true;
    this.showGoBackButton = false;
    this.urlWhatsapp = getUrlWhatsapp();
  }

  public toggleMenu() {
    this.isOpenModal = !this.isOpenModal;
    if (this.isOpenModal) {
      this._appFacade.expandMenu();
    } else {
      this._appFacade.collapseMenu();
    }
  }

  public onOpenWhatsapp() {
    this._gaService.sendEvent(CATEGORIES_EVENT.APP, ACTION_EVENT.CLICK, 'Whatsapp flotante')
  }

  public onGoBackToPreviousPage() {
    this._appFacade.goBackToPreviousPage();
  }
  public onGotoHome() {
    this.goHome.emit(true);
  }
}
