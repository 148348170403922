export const LOAN_CONSTANTS = {
  MIN_AMOUNT: 500,
  ANNUAL_INTEREST: 1,
  TAX_RATE: 0.16,
};

export const DISBURSEMENT_TYPE = {
  CASH: 2,
  BANK: 1,
};
