import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  CATEGORIES_EVENT,
  ACTION_EVENT,
} from '@app/core/constants/analytics.constants';
import * as onboardingActions from '../store/onboarding/onboarding.actions';
import { GoogleAnalyticsService } from './google-analytics.service';
import { AuthFacade } from '../../facades/auth.facade';
import { Observable } from 'rxjs';
import { User } from '@app/core/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class MatiService {
  public userId: string | undefined;

  constructor(
    private _authFacade: AuthFacade,
    private _store: Store,
    private _gaService: GoogleAnalyticsService
  ) {
    this._authFacade.user$.subscribe((user) => {
      return (this.userId = user?.externalId);
    });
    this.loadMati();
  }

  public loadMati() {
    const scripts = document.getElementsByTagName('script')[0];
    const script = document.createElement('script');
    script['async'] = true;
    script['src'] = 'https://web-button.getmati.com/button.js';
    scripts?.parentNode?.insertBefore(script, scripts);
  }

  public startMati(matiButton: any) {
    this._gaService.sendEvent(
      CATEGORIES_EVENT.NEW_USER,
      ACTION_EVENT.CLICK,
      `Redirección KYC, ID: ${this.userId}`
    );
    setTimeout(() => {
      matiButton.click();
    }, 500);
    matiButton.addEventListener('mati:userFinishedSdk', () => {
      this._store.dispatch(onboardingActions.finishIdentityValidationSDK());
    });
  }
}
