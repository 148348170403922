import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ModalConfig } from '@app/presentation/mo-layout/components/modal/modal-config.interface';

import * as appSelectors from '../domain/store/app/app.selectors';
import * as appActions from '../domain/store/app/app.actions';
import { UIService } from '../core/services/ui.service';
import { isDisabled } from '../domain/store/app/app.actions';

@Injectable({ providedIn: 'root' })
export class AppFacade {
  public isOpenMenu$: Observable<boolean>;
  public modalConfig$: Observable<ModalConfig>;
  public showGoBackButton$: Observable<boolean>;
  public showMenuIcon$: Observable<boolean>;
  public currentRoute$: Observable<string | undefined>;
  public isDisabled$: Observable<boolean>;

  constructor(private _store: Store, private _uiService: UIService) {
    this.isOpenMenu$ = this._store.select(appSelectors.isOpenMenu);
    this.modalConfig$ = this._store.select(appSelectors.isModalOpen);
    this.showGoBackButton$ = this._store.select(appSelectors.showGoBackButton);
    this.showMenuIcon$ = this._store.select(appSelectors.showMenuIcon);
    this.currentRoute$ = this._store.select(appSelectors.currentRoute);
    this.isDisabled$ = this._store.select(appSelectors.isDisabled);
  }

  public collapseMenu() {
    this._store.dispatch(appActions.collapseMenu());
  }

  public expandMenu() {
    this._store.dispatch(appActions.expandMenu());
  }

  public openModal(modalConfig: ModalConfig) {
    this._store.dispatch(appActions.setModalConfig({ modalConfig }));
  }

  public closeModal() {
    this._store.dispatch(appActions.closeModal());
  }

  public goBackToPreviousPage() {
    this._store.dispatch(appActions.goBackToPreviousPage());
  }

  public getUrlByChangeEvent() {
    return this._uiService.getUrlByChangeEvent();
  }

  public chageRoute(route: string) {
    this._store.dispatch(appActions.chageRoute({ route }));
  }

  public showGoBackButton(value: boolean) {
    this._store.dispatch(appActions.showGoBackButton({ showGoBackButton: value }));
  }
  public showMenuIcon(value: boolean) {
    this._store.dispatch(appActions.showMenuIcon({ showMenuIcon: value }));
  }
  public confirmSeeModal() {
    this._store.dispatch(appActions.confirmSeenModal());
  }
  public isDisabled(value: boolean) {
    this._store.dispatch(appActions.isDisabled({ isDisabled: value }));
  }
}
