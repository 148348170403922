import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule, APP_INITIALIZER } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeEsMX from '@angular/common/locales/es-MX';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '@environment';
import { appReducers, appEffects } from '@app/domain/store';
import { AppComponent } from '@app/presentation/app/app.component';
import { AppRoutingModule } from './app-routing.module';
import { GLOBAL_CONSTANTS } from './core/constants/global.constants';
import { AppInterceptorService } from './core/interceptors/app-interceptor.service';
import { AppMockInterceptorService } from './core/interceptors/app-mock-interceptor.service';
import { MOLayoutModule } from './presentation/mo-layout/mo-layout.module';
import { AppCamelToSnakeInterceptor } from './core/interceptors/app-camel-to-snake.interceptor';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { DesktopPage } from './presentation/features/public/desktop/desktop.page';
import { AppInitializer } from './core/services/app-initializer.service';

@NgModule({
  declarations: [AppComponent, DesktopPage],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot(appEffects),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    BrowserAnimationsModule,
    MOLayoutModule,
    NgxExtendedPdfViewerModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: GLOBAL_CONSTANTS.DEFAULT_LANGUAGE },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppCamelToSnakeInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppMockInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptorService,
      multi: true,
    },
    { provide: APP_INITIALIZER, useFactory: AppInitializer, multi: true },

  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
registerLocaleData(localeEsMX, GLOBAL_CONSTANTS.DEFAULT_LANGUAGE);
