import { AppReducers } from './app/app.reducer';
import { AuthReducers } from './auth/auth.reducer';
import { AuthEffects } from './auth/auth.effects';
import { AuthState } from './auth/auth.state';
import { AppState } from './app/app.state';
import { OnboardingReducers } from './onboarding/onboarding.reducers';
import { OnboardingEffects } from './onboarding/onboarding.effects';
import { AppEffects } from './app/app.effects';
import { LoanState } from './loan/loan.state';
import { LoanReducers } from './loan/loan.reducers';
import { LoanEffects } from './loan/loan.effects';
import { PaymentReducers } from './payment/payment.reducers';
import { PaymentEffects } from './payment/payment.effects';

export interface RootState {
  auth: AuthState;
  app: AppState;
  loan: LoanState;
}

export const appReducers = {
  app: AppReducers,
  auth: AuthReducers,
  onboarding: OnboardingReducers,
  loan: LoanReducers,
  payment: PaymentReducers
};

export const appEffects = [
  AuthEffects,
  OnboardingEffects,
  AppEffects,
  LoanEffects,
  PaymentEffects
];
