import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IModal, IModalCallback, IModalParams } from './modal-config.interface';

@Component({
  selector: 'class-dialog',
  template: '',
})
export class Modal implements IModal {
  public title: string;
  public message: string;
  public image: string;
  public type:
    | 'text'
    | 'loading'
    | 'alert'
    | 'detail'
    | 'warning'
    | 'success'
    | 'danger'
    | 'barcode'
    | 'notification-congratulations';
  public callbacks?: IModalCallback;

  constructor(
    public modalRef: MatDialogRef<Modal>,
    @Inject(MAT_DIALOG_DATA) public data: IModalParams
  ) {
    this.title = this.data.title ?? '';
    this.message = this.data.message ?? '';
    this.type = this.data.type ?? 'text';
    this.image = this.data.image ? this.data.image : 'image/notification-congratulations.png';
    this.callbacks = this.data.callbacks;
  }

  public onCancel(): void {
    this.modalRef.close();
    if (this.callbacks) {
      this._execFunction(this.callbacks.close);
    }
  }

  public onOk(): void {
    if (this.callbacks) {
      this._execFunction(this.callbacks.ok);
    }
  }

  public onClose(): void {
    this.modalRef.close();
    if (this.callbacks) {
      this._execFunction(this.callbacks.cancel);
    }
  }

  private _execFunction(fn?: Function) {
    if (fn && fn instanceof Function) {
      fn();
    }
  }
}
