import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BUTTON_TEMPLATE, SIZES } from '@app/presentation/mo-forms/enums/fields.type';
import { IModalButtons, IModalParams } from './modal-config.interface';
import { Modal } from './modal.base';
import { LoanDetails } from '@app/core/models/loan.model';
import { Observable, Subscription } from 'rxjs';
import { LoanFacade } from '@app/facades/loan.facade';

@Component({
  selector: 'layout-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent extends Modal {
  public infoButton: IModalButtons;
  public BUTTON_TEMPLATES = BUTTON_TEMPLATE;
  public BUTTON_SIZE = SIZES;
  public loan$: Observable<LoanDetails | undefined>;

  constructor(
    private _loanFacade: LoanFacade,
    public override modalRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public override data: IModalParams
  ) {
    super(modalRef, data);
    const defaultButtons = {
      showButtons: true,
      showButtonClose: true,
      showButtonCancel: false,
      showButtonOk: true,
      textCancel: 'Cancelar',
      textOk: 'Aceptar',
    };
    this.infoButton = { ...defaultButtons, ...data.infoButton };
    this.loan$ = this._loanFacade.currentLoan$;
  }
}
