import { environment } from '@environment';

export const GLOBAL_CONSTANTS = {
  SIZE_MOBILE_SCREEN: 990,
  DEFAULT_COUNTRY: 'MX',
  DEFAULT_LANGUAGE: 'es-MX',
  PROP_STORAGE_TOKEN: 'prop.promujer.session-token',
  PROP_STORAGE_TOKEN_MOCK: 'prop.promujer.session-mock-token',
  PROP_STORAGE_PASSWORD_TOKEN: 'prop.promujer.create-password-token',
  PROP_COOKIE_CONSENT: 'prop.promujer.cookie-consent',

  WHATSAPP_NUMBER: '527717153006',
  WHATSAPP_NUMBER_LABEL: '7717153006',
  CONTACT_NUMBER: '7717153006',
  URL_PORTAL: 'https://portal.promujerdigital.org/',
  URL_TIPS_IMPROVE_SCORE:
    'https://www.promujerdigital.org/quiero-mejorar-mi-score/',
  HTTP_CODES: {
    NOT_FOUND: 404,
    FORBIDDEN: 403,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    CONFLICT: 409,
    OK: 200,
    SUCCESS: '00',
    INTERNAL_SERVER_ERROR: 500,
    ERROR: 0,
  },
  TEST_INFO: {
    TOKEN: 'ESTO-ES-UN-TOKEN-FALSO-PARA-MOCKEAR-LOS-API-REQUEST',
    EMAIL: 'test@promujer.com',
    USER: 'test@promujer.com',
  },
  REGEX: {
    NUMBER: /^[0-9]*$/,
    DECIMAL_NUMBER: /^[0-9.]*$/,
    CURRENCY: /^[$0-9.,]*$/,
    CURRENCY_WITHOUT_DECIMALS: /^[$0-9,]*$/,
    LETTERS: /^[A-Za-zá-ú\s.]+$/,
    SEARCH: /^[A-Za-zá-ú\s.]+$/,
    CURP_RFC: /^[A-Za-zá-ú0-9._+-]+$/,
    CODE: /^[A-Za-zá-ú\s0-9._+-]+$/,
    EMAIL: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
    EMAIL_INPUT: /[A-Za-z0-9._%+-@]$/,
    PASSWORD:
      /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/])(?=.{8,})/,
    WEB_SITE:
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/,
    WEB_SITE_INPUT: /[A-Za-z0-9._%+-@]$/,
    ALPHANUMERIC: /^[a-zA-Z0-9]*$/,
    ALL: /^[a-zA-Z0-9-!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/]*$/,
  },
  ROUTES: {
    SIGNUP: '/crear-cuenta',
    ONBOARDING: '/tu-informacion',
    ONBOARDING_PERSONAL_INFO: 'informacion-personal',
    ONBOARDING_ADDRESS: 'direccion',
    ONBOARDING_OTHER_INFO: 'informacion-adicional',
    ONBOARDING_PEP: 'legal-pep',
    ONBOARDING_INCOME: 'ingresos',
    ONBOARDING_MATI: 'mati',
    LOAN: '/credito',
    PAYMENT: '/pagar',
    SUPPORT: '/soporte',
    PROFILE: '/perfil',
    DISBURSEMENT: '/recibe-tu-credito',
    DISBURSEMENT_SUCCESS: '/recibe-tu-credito/success',
    DISBURSEMENT_LOGOUT: '/recibe-tu-credito/exit',
    DISBURSEMENT_ERROR: '/recibe-tu-credito/error',
    LOGIN: '/inicio-sesion',
    FORGOT_PASSWORD: '/restablecer-contrasena',
    HOME: '/inicio',
    LIST_FAQ: '/list-faq',
    LIST_TIPS: '/list-tips',
    ROOT: '/',
  },
  COMPLETE_ROUTES: {
    ONBOARDING_PERSONAL_INFO: '/tu-informacion/informacion-adicional',
    ONBOARDING_ADDRESS: '/tu-informacion/direccion',
    ONBOARDING_OTHER_INFO: '/tu-informacion/informacion-adicional',
    ONBOARDING_PEP: '/tu-informacion/legal-pep',
    ONBOARDING_INCOME: '/tu-informacion/ingresos',
    ONBOARDING_MATI: '/tu-informacion/mati',
    ONBOARDING_REJECTED: '/tu-informacion/rechazado',
    HOME_INTENTION_LOAN_REQUEST: '/inicio/intencion-solicitud',
    SIGNUP_INTENTION: '/crear-cuenta/interes-registro',
    CONFIRMATION_SIGNUP_INTENTION:
      '/crear-cuenta/confirmacion-interes-registro',
  },
  LEGAL_DOCUMENTS: {
    PRIVACY_POLICY: `${environment.documentsFolder}/politica-privacidad.pdf`,
    TERMS_AND_CONDITIONS: `${environment.documentsFolder}/terminos-y-condiciones.pdf`,
    BACKUP_CONTRACT: 'app/presentation/assets/documents/contrato-credito.pdf',
  },
  OTP: {
    ONBOARDING_CIRCLE_CREDIT: 'credit_circle',
    SIGNUP: 'sign_up',
    FORGOT_PASSWROD: 'forgot_password',
  },
};
