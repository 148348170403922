import { Injectable } from '@angular/core';
import { catchError, first, map, Observable, of, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { StorageService } from '@app/data/browser/storage.service';
import { GLOBAL_CONSTANTS as CONST } from '../constants/global.constants';
import * as authSelectors from '@store/auth/auth.selectors';
import * as authActions from '@store/auth/auth.actions';
import { User } from '../models/user.model';
import { UserService } from '@app/data/mo-api/user.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedGuard implements CanActivate, CanActivateChild {
  constructor(
    private _storage: StorageService,
    private _store: Store,
    private _userAPI: UserService,
    private _router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._validateActiveSession(route, state);
  }
  
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._validateActiveSession(route, state);
  }

  private _validateActiveSession(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean {
    const token$ = this._storage.getItem(CONST.PROP_STORAGE_TOKEN) as Observable<string>;
    return token$.pipe(
      switchMap((token) => {
        if (!token) {
          this._router.navigateByUrl(CONST.ROUTES.LOGIN);
          return of(false);
        }
        return this._getActiveUser();
      })
    );
  }

  private _getActiveUser() {
    return this._store.select(authSelectors.selectIsLogged).pipe(
      first(),
      switchMap((isLogged?: boolean) => {
        if (isLogged) return of(true);

        return this._loadActiveSession();
      })
    );
  }

  private _loadActiveSession() {
    return this._userAPI.getUserInfo().pipe(
      map((user: User) => {
        this._store.dispatch(authActions.responseUserGuard({ user }));
        return true;
      }),
      catchError((_) => {
        this._store.dispatch(authActions.logoutGuard());
        return of(false);
      })
    );
  }
}
