import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoanFacade } from '@app/facades/loan.facade';
import { GLOBAL_CONSTANTS as CONST } from '../../../../core/constants/global.constants';

@Component({
  selector: 'disbursement-message-error-bank',
  templateUrl: './message-error-bank.component.html',
  styleUrls: ['./message-error-bank.component.scss'],
})
export class MessageErrorBankComponent {
  public whatsappNumber: string;
  public type: 'success' | 'alert' | 'danger';

  constructor(private _router: Router, private _loanFacade: LoanFacade) {
    this.whatsappNumber = CONST.WHATSAPP_NUMBER_LABEL;
    this.type = 'danger';
  }

  onFinish() {
    this._loanFacade.changeStateUserToRetryDisbursement();
  }
}
