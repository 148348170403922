import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnChanges {
  @Input() current?: number;
  @Input() total?: number;
  @Input() value?: string;

  constructor() {
    this.value = '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes['current'] || changes['total']) && this.current && this.total) {
      this.value = (this.current * 100 / this.total).toString();
    }
  }
}
