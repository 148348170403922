<div *ngIf="isOpenMenu">
  <div [class]="'bg-menu animate__animated ' + styleBackground">
    <div [class]="'menu animate__animated ' + styleMenu">
      <ul *ngIf="configuration">
        <li *ngFor="let menu of configuration">
          <a (click)="goToPath(menu?.url)">{{ menu.title }}</a>
        </li>
      </ul>
      <div *ngIf="showActionButton && actionText" class="m-t-50">
        <mo-button
          [text]="actionText"
          [displayBlock]="true"
          [isDisabled]="false"
          [size]="BUTTON_SIZE.LG"
          [template]="BUTTON_TEMPLATES.PRIMARY"
          (click)="onLogout()"
        ></mo-button>
      </div>
    </div>
  </div>
</div>
