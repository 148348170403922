export interface Payment {
  amount: number;
  transactionType: number,
}

export enum PAYMENT_STATUS {
  PROCESSED = 1,
  FAILED = 2,
  RETURNED = 3,
  BAD_REQUEST = 4,
  CANCEL_FAILED = 5,
  WRONG_PROCESSED = 6,
  PENDING = -1,
  SENT = -2,
  PENDING_PROVIDER = -3
}
export type PaymentStatus = PAYMENT_STATUS;

export enum PAYMENT_STEP {
  FORM = 1,
  STP_INFORMATION = 2
}
export interface PaymentCreated {
  numericalReference: string;
  expirationDate: Date;
  status: PaymentStatus;
  clabe: string;
  amount: string;
  methodPayment: number;
  barcodeUrl?: string;
  barcodePdf?: string;
}

export interface WireTransfer {
}
