import { GLOBAL_CONSTANTS } from './global.constants';

export type CountryConfig = {
  code: string;
  sign: string;
  decimals: number;
  phoneMask: string;
  language: string;
  phoneLength: number;
  thousandsSeparator: string;
  flag: string;
};

export const COUNTRY_CONSTANTS: { [_: string]: CountryConfig } = {
  [GLOBAL_CONSTANTS.DEFAULT_COUNTRY]: {
    code: 'MXN',
    sign: '$',
    decimals: 2,
    phoneMask: '00 000  000',
    language: 'es-MX',
    phoneLength: 10,
    thousandsSeparator: ',',
    flag: 'image/flag-mx.png',
  },
};
