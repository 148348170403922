import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  switchMap,
  map,
  catchError,
  of,
  withLatestFrom,
  filter,
  tap,
  delay,
} from 'rxjs';
import { LoanAPI } from '../../../data/mo-api/loan.api';

import { LOAN_CONSTANTS } from '@app/core/constants/loan.constants';
import { GLOBAL_CONSTANTS } from '@app/core/constants/global.constants';
import {
  CATEGORIES_EVENT,
  ACTION_EVENT,
} from '@app/core/constants/analytics.constants';
import { LoanService } from '@app/domain/services/loan.service';
import * as loanActions from './loan.actions';
import * as appActions from './../app/app.actions';
import * as loanSelectors from './loan.selectors';
import { UIService } from '@app/core/services/ui.service';
import { AppService } from '@app/domain/services/app.service';
import * as authSelector from '@app/domain/store/auth/auth.selectors';
import { DISBURSEMENT_STATUS } from '@app/core/models/loan.model';
import { GoogleAnalyticsService } from '@app/domain/services/google-analytics.service';
import { environment } from '@environment';
import { ILoanLegalUrlDocuments } from '../../../core/models/loan.model';
import { downloadPaymentPlan } from './loan.actions';
import { FacebookPixelService } from '@app/domain/services/facebook-pixel.service';

@Injectable()
export class LoanEffects {
  constructor(
    private actions$: Actions,
    private _service: LoanService,
    private _api: LoanAPI,
    private _store: Store,
    private _router: Router,
    private _uiService: UIService,
    private _appService: AppService,
    private _gaService: GoogleAnalyticsService,
    private _facebookPixelService: FacebookPixelService
  ) {}

  public getDynamicDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loanActions.getDynamicDocuments),
      withLatestFrom(this._store.select(loanSelectors.selectLoanParams)),
      switchMap(([{ documentName }, loan]) => {
        this._gaService.sendEvent(
          CATEGORIES_EVENT.REQUEST_LOAN,
          ACTION_EVENT.CLICK,
          'Acepto T&C'
        );

        this._facebookPixelService.sendEvent(
          CATEGORIES_EVENT.REQUEST_LOAN,
          ACTION_EVENT.CLICK,
          'Acepto T&C'
        );

        if (!loan) return of(loanActions.failureGetDynamicDocuments());
        return this._api.getDynamicDocuments(documentName, loan).pipe(
          map((dynamicDocuments) => {
            return loanActions.successGetDynamicDocuments({ dynamicDocuments });
          }),
          catchError(() => of(loanActions.failureGetDynamicDocuments()))
        );
      })
    )
  );

  public downloadAccountStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loanActions.downloadAccountStatus),
      switchMap(() => {
        return this._api.getAccountStatus().pipe(
          map((resp: ILoanLegalUrlDocuments) =>
            loanActions.successDownloadAccountStatus(resp)
          ),
          catchError(() => of(loanActions.failureDownloadAccountStatus()))
        );
      })
    )
  );

  public successDownloadAccountStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loanActions.successDownloadAccountStatus),
      switchMap(({ url }) => {
        return this._api.validUrlContract(url).pipe(
          tap((resp: any) => {
            const { status } = resp;
            return status === 404
              ? loanActions.failureDownloadAccountStatus()
              : this._uiService.downloadAccountStatus(url);
          }),
          catchError((_) => of(loanActions.failureDownloadAccountStatus()))
        );
      })
    )
  );

  public failureDownloadAccountStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loanActions.failureDownloadAccountStatus),
        tap(() => {
          this._uiService.showDialog({
            message: `¡Gracias por tu solicitud! Tu estado de cuenta será enviado por correo electrónico el primer día del próximo mes.`,
            type: 'text',
            infoButton: {
              showButtonOk: true,
              showButtonClose: true,
            },
          });
        })
      ),
    { dispatch: false }
  );

  public downloanPaymentPlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loanActions.downloadPaymentPlan),
      withLatestFrom(this._store.select(loanSelectors.selectCurrentLoan)),
      map(([_, loan]) => {
        const url: string | any = loan?.urls[2].url;

        loan?.isFrozen
          ? this._uiService.showDialog({
              message: `En este momento no cuenta con un plan de pagos disponible ya que su crédito tiene unas características especiales. Para mayor información, puedes comunicarte a servicio al cliente.`,
              type: 'text',
              infoButton: {
                showButtonOk: true,
                showButtonClose: true,
              },
            })
          : this._uiService.downloadPaymentPlan(url);

        return loanActions.successDownloadPaymentPlan(url);
      })
    )
  );

  public failureDownloadPaymentPlan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loanActions.failureDownloadPaymentPlan),
        tap(() => {
          this._uiService.showDialog({
            message: `¡Gracias por tu solicitud! Tu de plan de pago será enviado por correo electrónico el primer día del próximo mes.`,
            type: 'text',
            infoButton: {
              showButtonOk: true,
              showButtonClose: true,
            },
          });
        })
      ),
    { dispatch: false }
  );

  public successDownloadPaymentPlanSimulator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loanActions.successDownloadPaymentPlanSimulator),
      switchMap(({ url }) => {
        return this._api.validUrlContract(url).pipe(
          tap((resp: any) => {
            const { status } = resp;
            return status === 404
              ? loanActions.failureDownloadPaymentPlanSimulator()
              : this._uiService.downloadPaymentPlanSimulator(url);
          }),
          catchError((_) =>
            of(loanActions.failureDownloadPaymentPlanSimulator())
          )
        );
      })
    )
  );

  public failureDownloadPaymentPlanSimulator$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loanActions.failureDownloadPaymentPlanSimulator),
        tap(() => {
          this._uiService.showDialog({
            message: `¡Gracias por tu solicitud! Tu de plan de pago será enviado por correo electrónico el primer día del próximo mes.`,
            type: 'text',
            infoButton: {
              showButtonOk: true,
              showButtonClose: true,
            },
          });
        })
      ),
    { dispatch: false }
  );

  public calculateLoanMath$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loanActions.calculateLoanMath),
      switchMap(({ loan }) => {
        this._gaService.sendEvent(
          CATEGORIES_EVENT.REQUEST_LOAN,
          ACTION_EVENT.CLICK,
          'Calcular crédito'
        );
        this._facebookPixelService.sendEvent(
          CATEGORIES_EVENT.REQUEST_LOAN,
          ACTION_EVENT.CLICK,
          'Calcular crédito'
        );
        return this._api.calculateLoanMath(loan).pipe(
          map((loanMath) => {
            loanMath.amount = Number(loan.amount);
            loanMath.taxPercentage = LOAN_CONSTANTS.TAX_RATE;
            return loanActions.successCalculateLoanMath({ loanMath });
          }),
          // TODO: Cuando el simulador falla no se debería habilitar el botón de continuar
          catchError(() => of(loanActions.failureCalculateLoanMath()))
        );
      })
    )
  );

  public failureCalculateLoanMath$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loanActions.failureCalculateLoanMath),
        tap(() => {
          this._uiService.showDialog({
            title: '¡Tuvimos un problema!',
            message: ` No pudimos calcular correctamente tu crédito, por favor cierra sesión y vuelve intentarlo.`,
            type: 'alert',
            infoButton: {
              showButtonClose: false,
              textOk: 'Aceptar y cerrar sesión',
            },
          });
        })
      ),
    { dispatch: false }
  );

  public requestLoan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loanActions.requestLoan),
      withLatestFrom(this._store.select(loanSelectors.selectLoanParams)),
      switchMap(([_, loan]) => {
        this._gaService.sendEvent(
          CATEGORIES_EVENT.REQUEST_LOAN,
          ACTION_EVENT.CLICK,
          'Firmar contrato'
        );
        this._facebookPixelService.sendEvent(
          CATEGORIES_EVENT.REQUEST_LOAN,
          ACTION_EVENT.CLICK,
          'Firmar contrato'
        );
        if (!loan) return of(loanActions.failureRequestLoan());
        return this._api.requestLoan(loan).pipe(
          map(() => {
            return loanActions.successRequestLoan();
          }),
          catchError(() => of(loanActions.failureRequestLoan()))
        );
      })
    )
  );

  public getBankList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loanActions.getBankList),
      map(() => {
        const bankList = this._service.getBankList();
        return loanActions.successGetBankList({ bankList });
      }),
      catchError(() => of(loanActions.failureGetBankList()))
    )
  );

  public getCurrentLoan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loanActions.getCurrentLoan),
      withLatestFrom(this._store.select(authSelector.selectUser)),
      filter(([_, user]) => !!user && Boolean(user?.loans?.length)),
      switchMap(([_, user]) => {
        const loanId = user?.loans?.length
          ? user.loans[user.loans.length - 1]
          : '';
        return this._api.getLoanDetails(loanId).pipe(
          map((loan) => {
            if (loan.movements.length > 6) {
              const arrayLength = loan.movements.length;
              const lengthNeeded = arrayLength - 6;
              const filteredMovements = loan.movements?.slice(
                lengthNeeded,
                arrayLength
              );
              loan.movements = filteredMovements;
            }
            return loanActions.successGetCurrentLoan({
              loan: { ...loan, id: loanId },
            });
          }),
          catchError(() => of(loanActions.failureGetCurrentLoan()))
        );
      })
    )
  );

  public disburseLoan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loanActions.disburseLoan),
      switchMap(({ bankInfo }) => {
        this._gaService.sendEvent(
          CATEGORIES_EVENT.REQUEST_LOAN,
          ACTION_EVENT.CLICK,
          'Desembolso STP'
        );
        this._facebookPixelService.sendEvent(
          CATEGORIES_EVENT.REQUEST_LOAN,
          ACTION_EVENT.CLICK,
          'Desembolso STP'
        );
        if (environment.blockGeneralFlow) {
          return of(loanActions.blockFlowLoanDisbursment());
        } else {
          return this._api.disburseLoan(bankInfo).pipe(
            map(() => {
              this._service.showLoaderDisbursingLoan();
              return loanActions.successRequestDisburseLoan();
            }),
            catchError(() => of(loanActions.failureRequestDisburseLoan()))
          );
        }
      })
    )
  );

  public blockLoanDisbursement$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loanActions.blockFlowLoanDisbursment),
        tap(() => {})
      ),
    { dispatch: false }
  );

  public startGetStatusDisbursement$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loanActions.successRequestDisburseLoan),
        delay(5000),
        tap(() => {
          this._router.navigateByUrl(GLOBAL_CONSTANTS.ROUTES.HOME);
          this._uiService.closeDialog();
          // this action is for automatic disbursement, for get status each 30seg ---> loanActions.startGetStatusDisburseLoan()
        })
      ),
    { dispatch: false }
  );

  public startPollingDisburseLoanStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loanActions.startGetStatusDisburseLoan),
        switchMap(() => {
          return this._appService.startPolling({
            apiCall: () => this._api.getDisburseLoanStatus(),
            validateFinished: (data: any) =>
              this._service.validateGetStatusDisbursementFinished(data),
            actionSuccess: loanActions.successStartGetStatusDisburseLoan,
            actionFailure: loanActions.failureStartGetStatusDisburseLoan,
          });
        })
      ),
    { dispatch: false }
  );

  public successGetStateDisbursement$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loanActions.successStartGetStatusDisburseLoan),
        withLatestFrom(
          this._store.select(loanSelectors.selectStateTransactionDisbursement)
        ),
        tap(([_, disbursementState]) => {
          if (
            disbursementState === DISBURSEMENT_STATUS.PENDING ||
            disbursementState === DISBURSEMENT_STATUS.PENDING_PROVIDER ||
            disbursementState === DISBURSEMENT_STATUS.SENT
          ) {
            this._router.navigate([GLOBAL_CONSTANTS.ROUTES.HOME]);
          }
        })
      ),
    { dispatch: false }
  );

  public disbursementSuccessRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loanActions.finishGetStatusDisburseLoan),
        tap(({ disburseStatus }) => {
          if (disburseStatus === DISBURSEMENT_STATUS.PROCESSED) {
            this._router.navigate([
              GLOBAL_CONSTANTS.ROUTES.DISBURSEMENT_SUCCESS,
            ]);
          } else if (
            disburseStatus !== DISBURSEMENT_STATUS.PENDING &&
            disburseStatus !== DISBURSEMENT_STATUS.PENDING_PROVIDER &&
            disburseStatus !== DISBURSEMENT_STATUS.SENT
          ) {
            this._router.navigate([GLOBAL_CONSTANTS.ROUTES.DISBURSEMENT_ERROR]);
          }
        })
      ),
    { dispatch: false }
  );

  public requestToEnableUserToRetryDisbursement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loanActions.restartDisbursementError),
      switchMap(() =>
        this._api
          .restartDisbursementError()
          .pipe(map(() => loanActions.successRestartDisbursementError()))
      )
    )
  );

  public succecessChangeStateUserToRetrayDisbursement$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loanActions.successRestartDisbursementError),
        tap(() => this._router.navigate([GLOBAL_CONSTANTS.ROUTES.DISBURSEMENT]))
      ),
    { dispatch: false }
  );

  public downloadLegalDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loanActions.downloadLegalDocument),
      withLatestFrom(this._store.select(loanSelectors.selectCurrentLoan)),
      switchMap(([{ documentName }, loan]) => {
        if (!loan) return of(loanActions.failureDownloadLegalDocument());

        return this._api.getDynamicDocuments([documentName], loan.id).pipe(
          map((dynamicDocuments) => {
            this._uiService.onDownloadFiles(dynamicDocuments[documentName].url);
            return loanActions.successGetDynamicDocuments({ dynamicDocuments });
          }),
          catchError(() => of(loanActions.failureGetDynamicDocuments()))
        );
      })
    )
  );

  public goBackPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.goBackToPreviousPage),
      withLatestFrom(this._store.select(loanSelectors.selectLoanStep)),
      map(([_, loanStep]) => {
        return loanActions.setPreviousStep();
      })
    )
  );

  public visitFormLoan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loanActions.visitFormLoan),
        tap(() =>
          this._gaService.sendEvent(
            CATEGORIES_EVENT.REQUEST_LOAN,
            ACTION_EVENT.VISIT,
            'Simulador de crédito'
          )
        )
      ),
    { dispatch: false }
  );
  public visitFormLoanFacebook$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loanActions.visitFormLoan),
        tap(() =>
          this._facebookPixelService.sendEvent(
            CATEGORIES_EVENT.REQUEST_LOAN,
            ACTION_EVENT.VISIT,
            'Simulador de crédito'
          )
        )
      ),
    { dispatch: false }
  );

  public acceptLoan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loanActions.acceptLoan),
        tap(() =>
          this._gaService.sendEvent(
            CATEGORIES_EVENT.REQUEST_LOAN,
            ACTION_EVENT.CLICK,
            'Acepto crédito'
          )
        )
      ),
    { dispatch: false }
  );
  public acceptLoanFacebookEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loanActions.acceptLoan),
        tap(() =>
          this._facebookPixelService.sendEvent(
            CATEGORIES_EVENT.REQUEST_LOAN,
            ACTION_EVENT.CLICK,
            'Acepto crédito'
          )
        )
      ),
    { dispatch: false }
  );

  public goToRequestLoan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loanActions.goToRequestLoan),
        tap(() => {
          this._gaService.sendEvent(
            CATEGORIES_EVENT.REQUEST_LOAN,
            ACTION_EVENT.CLICK,
            'Solicítalo aquí'
          );
          this._facebookPixelService.sendEvent(
            CATEGORIES_EVENT.REQUEST_LOAN,
            ACTION_EVENT.CLICK,
            'Solicítalo aquí'
          );
          if (environment.blockGeneralFlow) {
            this._router.navigateByUrl(
              GLOBAL_CONSTANTS.COMPLETE_ROUTES.HOME_INTENTION_LOAN_REQUEST
            );
          } else {
            this._router.navigateByUrl(GLOBAL_CONSTANTS.ROUTES.LOAN);
          }
        })
      ),
    { dispatch: false }
  );
}
