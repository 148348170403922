import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Payment } from '@core/models/payment.model';

import { URLS } from './url.constants';

@Injectable({ providedIn: 'root' })
export class PaymentAPI {
  constructor(private _http: HttpClient) {}

  public sentDataToCreatePayment(data: Payment): Observable<any> {
    return this._http.post(`${URLS.PAYMENT}`, data);
  }

  public cancelTransaction(): Observable<{}> {
    return this._http.patch(`${URLS.CANCEL_TRANSACTION}`, {});
  }
}
