import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from './app.state';

export const getAppFeatureState: any = createFeatureSelector('app');

export const isOpenMenu = createSelector(
  getAppFeatureState,
  (state: AppState) => state?.isOpenMenu
);

export const isModalOpen = createSelector(
  getAppFeatureState,
  (state: AppState) => state?.modalConfig
);

export const showGoBackButton = createSelector(
  getAppFeatureState,
  (state: AppState) => state?.showGoBackButton
);
export const showMenuIcon = createSelector(
  getAppFeatureState,
  (state: AppState) => state?.showMenuIcon
);

export const currentRoute = createSelector(
  getAppFeatureState,
  (state: AppState) => state?.currentRoute
);

export const isDisabled = createSelector(
  getAppFeatureState,
  (state: AppState) => state?.isDisabled
);
