import { Injectable } from '@angular/core';
import { CookiesService } from '@app/core/services/cookies.service';
import { environment } from '@environment';

declare let fbq: Function;

@Injectable({
  providedIn: 'root',
})
export class FacebookPixelService {
  public PIXEL_ID: string = environment.pixel_id;
  public PROD: boolean = environment.production;

  constructor(private _cookieService: CookiesService) {}

  public sendEvent(eventType: string, eventName: string, data?: any) {
    const cookieConsent = this._cookieService.getCookieConsent();
    const { exists, value } = cookieConsent;

    if (this.PROD && !!this.PIXEL_ID && exists && value === 'accepted') {
      if (data) fbq(eventType, eventName, data);
      else fbq(eventType, eventName);
    }
  }
}
