import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IIntentionRegister } from '@app/core/models/signup.model';
import { User } from '@app/core/models/user.model';
import { map, Observable } from 'rxjs';
import { URLS } from './url.constants';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private _http: HttpClient) {}

  public getUserInfo(): Observable<User> {
    return this._http.get<User>(URLS.GET_USER);
  }

  public changeEmail(email: string): Observable<any> {
    return this._http.patch(URLS.CHANGE_EMAIL, email)
    .pipe(
      map( () => ({ email }))
    );
  }

  public confirmSeeModal(): Observable<any> {
    return this._http.patch(URLS.CONFIRM_SEE_MODAL, {sawPopup: true});
  }

  public saveIntentionSignup(intention: IIntentionRegister): Observable<null> {
    return this._http.post<null>(URLS.INTENTION_SIGNUP, intention);
  }
}
