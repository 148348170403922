import { appReducers } from '@app/domain/store';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import { catchError, map, of, tap } from 'rxjs';
import { UIService } from '../../../core/services/ui.service';
import { switchMap } from 'rxjs';

import * as appActions from './app.actions';
import { UserService } from '@app/data/mo-api/user.service';

import { GLOBAL_CONSTANTS as CONST } from '@app/core/constants/global.constants';
import { Router } from '@angular/router';

@Injectable()
export class AppEffects {
  constructor(
    private _actions$: Actions,
    private _uiService: UIService,
    private _userService: UserService,
    private _router: Router
  ) {}

  public chageRoute$ = createEffect(() =>
    this._actions$.pipe(
      ofType(appActions.chageRoute),
      map(({ route }) => {
        const showGoBackButton = this._uiService.isRouteAllowToBackButton(route);

        return appActions.showGoBackButton({ showGoBackButton });
      })
    )
  );
  public showMenu$ = createEffect(() =>
    this._actions$.pipe(
      ofType(appActions.chageRoute),
      map(({ route }) => {
        const showMenuIcon = this._uiService.isRouteAllowToBurgerMenu(route);
        return appActions.showMenuIcon({ showMenuIcon });
      })
    )
  );

  public confirmSeeModal$ = createEffect(() =>
    this._actions$.pipe(
      ofType(appActions.confirmSeenModal),
      switchMap(() =>
        this._userService.confirmSeeModal().pipe(
          map(() => appActions.confirmSeenModalSuccess()),
          catchError(() => of(appActions.confirmSeenModalError()))
        )
      )
    )
  );

  public redirectPageBlockFlow$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(appActions.showPageBlockFlowRegister),
        tap(() => {
          this._router.navigateByUrl(CONST.COMPLETE_ROUTES.SIGNUP_INTENTION);
        })
      ),
    { dispatch: false }
  );

  public isDisabled$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(appActions.isDisabled),
        tap(() => {
          appActions.isDisabled({ isDisabled: true });
        })
      ),
    { dispatch: false }
  );
}
