import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IModalParams } from '../modal-config.interface';
import { Modal } from '../modal.base';
import { LoanDetails, LOAN_DOCUMENTS } from '@app/core/models/loan.model';
import { LoanFacade } from '@app/facades/loan.facade';
import { downloadPaymentPlan } from '../../../../../domain/store/loan/loan.actions';
import {
  SIZES,
  COLOR_TEMPLATE,
  BUTTON_TEMPLATE,
} from '@app/presentation/mo-forms/enums/fields.type';
@Component({
  selector: 'app-modal-detail',
  templateUrl: './modal-detail.component.html',
  styleUrls: ['./modal-detail.component.scss'],
})
export class ModalDetailComponent extends Modal {
  public loan: LoanDetails;
  public BUTTON_SIZE = SIZES;
  public INPUT_TEMPLATES = COLOR_TEMPLATE;
  public BUTTON_TEMPLATES = BUTTON_TEMPLATE;

  constructor(
    public override modalRef: MatDialogRef<ModalDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public override data: IModalParams,
    private _loanFacade: LoanFacade
  ) {
    super(modalRef, data);
    this.loan = data.data as LoanDetails;
  }

  public onDownloadContract() {
    this._loanFacade.downloadLegalDocument(LOAN_DOCUMENTS.CONTRACT);
  }

  public onDownloadPromissoryNote() {
    this._loanFacade.downloadLegalDocument(LOAN_DOCUMENTS.PROMISSORY_NOTE);
  }
  public onDownloadAccountStatus() {
    this._loanFacade.downloadAccountStatus();
  }

  public onDownloadPaymentPlan() {
    this._loanFacade.downloadPaymentPlan();
  }

  public closeModal(): void {
    this.modalRef.close();
  }
}
