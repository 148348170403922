<div class="modal-creditReport">
    <h2 class="title">
        {{title}}
    </h2>

    
    <div class="body" [innerHtml]="message"></div>

    <div class="actions">
        <mo-button
            cdkFocusInitial
            (click)="onOk()"
            [text]="'Aceptar y continuar'"
            [size]="BUTTON_SIZE.LG"
            [template]="BUTTON_TEMPLATES.PRIMARY">
        </mo-button>
    </div>

</div>