<section class="container-desktop">
  <article class="image-side">
    <img class="logo" src="/app/presentation/assets/logo/logo-promujer.png" />
    <img class="decoration" src="/app/presentation/assets/images/phone.png" />
  </article>
  <article class="flex-v">
    <div class="flex-v" data-testid="text">
      <h1 class="text-decoration-secondary">¡Recuerda!</h1>
      <h5>
        Para solicitar tu crédito con nosotros, ingresa a tráves de tu celular a la siguiente liga:
        {{urlPortal}}<br />
        ¡Te esperamos!
      </h5>
    </div>
  </article>
</section>
