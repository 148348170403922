import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import {
  CustomerValidationsStep,
  IOnboardingStep,
  Location,
  OnboardingValidationData,
} from '../../core/models/onboarding.model';
import { URLS } from './url.constants';
import { GLOBAL_CONSTANTS } from '@app/core/constants/global.constants';

@Injectable({ providedIn: 'root' })
export class OnboardingAPI {
  constructor(private _http: HttpClient) {}

  public getOnboardingByStep(step: number): Observable<IOnboardingStep> {
    return this._http.get<IOnboardingStep>(`/customers/steps/${step}/`);
  }

  public setOnboardingInfoByStep(step: number, stepInfo: IOnboardingStep): Observable<any> {
    return this._http.post(`/customers/steps/${step}/`, stepInfo);
  }

  public getValidationData(): Observable<OnboardingValidationData> {
    return this._http.post<OnboardingValidationData>(
      URLS.ONBOARDING_IDENTITY_VALIDATION_STATUS,
      {}
    );
  }

  public notifyMatiFinish(): Observable<null> {
    return this._http.put<null>(
      URLS.ONBOARDING_IDENTITY_VALIDATION_STATUS,
      { kycFinished: true }
    );
  }

  public getCustomerValidationStatus(): Observable<CustomerValidationsStep> {
    return this._http.get<CustomerValidationsStep>(URLS.ONBOARDING_CUSTOMER_VALIDATIONS_STATUS);
  }

  public getLocationByZipcode(zipcode: string): Observable<Location | undefined> {
    return this._http.get<Location>(`${URLS.ONBOARDING_GET_LOCATION_BY_ZIPCODE}${zipcode}/`).pipe(
      map((response) => {
        const location = Object.keys(response)?.length > 0 && response;
        if (location) delete location.zipCode;
        return location || undefined;
      })
    );
  }

  public reSendOTP(numberPhone: string): Observable<any> {
    return this._http.post(URLS.SEND_OTP_CODE_ONBOARDING, {phone_number: numberPhone.slice(2), type_otp: GLOBAL_CONSTANTS.OTP.ONBOARDING_CIRCLE_CREDIT});
  }
  
  public validateOTP({ phone, code }: { phone: string; code: string }): Observable<any> {
    return this._http.patch(URLS.VALIDATE_OTP_CODE, { phone_number: phone.slice(2), code, type_otp: GLOBAL_CONSTANTS.OTP.ONBOARDING_CIRCLE_CREDIT });
  }
}
