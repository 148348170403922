export const ONBOARDING_CONSTANTS = {
  STEPS: {
    SIGNUP_TOTAL_STEPS: 4,
    TOTAL: 10, //4 Signup 6 onboarding
    PERSONALINFO: 1,
    ADDRESS: 2,
    OTHER: 3,
    PEP: 4,
    INCOME: 5,
    MATI: 6,
  },
  TIME_MS_TO_GET_VALIDATION_STATUS: 30 * 1000, // 30seg
  ATTEMPTS_TO_GET_VALIDATION_STATUS: 14 /// El mensaje de espera dice 7 minutoss
};
