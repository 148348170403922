import { Action, createReducer, on } from '@ngrx/store';
import { AuthState } from './auth.state';
import { ACTION_STATUS } from '@app/core/models/app.model';
import { AUTH_CONSTANTS, GENDER_LIST_IMG } from '@app/core/constants/auth.constants';
import * as authActions from './auth.actions';
import * as onboardingActions from '../onboarding/onboarding.actions';
import * as appActions from '../app/app.actions';

export const initialAuth: AuthState = {
  isLogged: false,
};

const _authReducer = createReducer(
  initialAuth,
  on(
    authActions.responseUserGuard,
    authActions.responseUserLogin,
    authActions.responseUserSignup,
    (state, { user }) => {

      const optionGender = user.gender ? user.gender: 'F';
      const avatarUrl = GENDER_LIST_IMG[optionGender];
      
      return {
        ...state,
        isLogged: true,
        userLogged: {
          ...user,
          score: Number(user.score),
          avatarUrl,
          phoneNumber: `52${user.phoneNumber}`
        },
      };
    }
  ),
  on(authActions.initSignup, (state, props) => {
    return {
      ...state,
      signupSendOTP: ACTION_STATUS.PENDING,
      signupData: {
        phone: props.phone,
      },
    };
  }),
  on(appActions.showPageBlockFlowRegister, (state) => {
    return {
      ...state,
      signupSendOTP: ACTION_STATUS.INITIAL,
    };
  }),
  on(authActions.responseSendOTPCode, (state) => {
    return {
      ...state,
      signupSendOTP: ACTION_STATUS.SUCCESS,
      signupStep: AUTH_CONSTANTS.ONBOARDING_STEPS.OTP,
    };
  }),
  on(authActions.failureSendOTPCode, (state) => {
    return { ...state, signupSendOTP: ACTION_STATUS.FAILURE };
  }),
  on(authActions.validateOTP, (state) => {
    return { ...state, signupValidateOTP: ACTION_STATUS.PENDING };
  }),
  on(authActions.responseValidateOTPCode, (state) => {
    return {
      ...state,
      signupValidateOTP: ACTION_STATUS.SUCCESS,
      signupStep: AUTH_CONSTANTS.ONBOARDING_STEPS.PASSWORD,
    };
  }),
  on(authActions.failureValidateOTPCode, (state) => {
    return { ...state, signupValidateOTP: ACTION_STATUS.FAILURE };
  }),
  on(authActions.validatePassword, (state) => {
    return { ...state, signupValidatePassword: ACTION_STATUS.PENDING };
  }),
  on(authActions.responseValidatePassword, (state) => {
    return {
      ...state,
      signupValidatePassword: ACTION_STATUS.SUCCESS,
      signupStep: AUTH_CONSTANTS.ONBOARDING_STEPS.SUCCESS,
    };
  }),
  on(authActions.failureValidatePassword, (state) => {
    return { ...state, signupValidatePassword: ACTION_STATUS.FAILURE };
  }),
  on(authActions.resendOTP, (state) => {
    return { ...state, signupResendOTP: ACTION_STATUS.PENDING };
  }),
  on(authActions.responseReSendOTPCode, (state) => {
    return { ...state, signupResendOTP: ACTION_STATUS.SUCCESS };
  }),
  on(authActions.failureReSendOTPCode, (state) => {
    return { ...state, signupResendOTP: ACTION_STATUS.FAILURE };
  }),
  on(authActions.setNewStep, (state, { signupStep }) => {
    return { ...state, signupStep };
  }),
  on(authActions.forgotGoBackPage, (state) => {
    let forgotStep;
    if (
      !state.forgotStep ||
      state.forgotStep <= AUTH_CONSTANTS.FORGOT_PASSWORD_STEPS.PHONE_NUMBER
    ) {
      forgotStep = AUTH_CONSTANTS.FORGOT_PASSWORD_STEPS.PHONE_NUMBER;
    } else {
      forgotStep = state.forgotStep - 1;
    }

    return { ...state, forgotStep };
  }),
  on(authActions.forgotSendOTP, (state, { phone }) => {
    return {
      ...state,
      forgotIsLoadingSendOTP: true,
      forgotData: { phone },
    };
  }),
  on(authActions.responseForgotSendOTP, (state) => {
    return {
      ...state,
      forgotIsLoadingSendOTP: false,
      forgotStep: AUTH_CONSTANTS.FORGOT_PASSWORD_STEPS.OTP,
    };
  }),
  on(authActions.failureForgotSendOTP, (state) => {
    return { ...state, forgotIsLoadingSendOTP: false };
  }),
  on(authActions.forgotResendOTP, (state) => {
    return { ...state, forgotIsLoadingResendOTP: true };
  }),
  on(authActions.responseForgotResendOTP, (state) => {
    return { ...state, forgotIsLoadingResendOTP: false };
  }),
  on(authActions.failureForgotResendOTP, (state) => {
    return { ...state, forgotIsLoadingResendOTP: false };
  }),
  on(authActions.forgotValidateOTP, (state, { code }) => {
    const { phone } = state.forgotData ?? { phone: '' };
    return {
      ...state,
      forgotData: { phone, code },
      forgotStep: AUTH_CONSTANTS.FORGOT_PASSWORD_STEPS.PASSWORD,
    };
  }),
  on(authActions.failureForgotValidateOTPCode, (state) => {
    return {
      ...state,
      forgotStep: AUTH_CONSTANTS.FORGOT_PASSWORD_STEPS.OTP,
    };
  }),
  on(authActions.forgotSaveNewPassword, (state) => {
    return { ...state, forgotIsLoadingSavePassword: true };
  }),
  on(authActions.responseForgotSavePassword, (state) => {
    return {
      ...state,
      forgotIsLoadingSavePassword: false,
      forgotStep: AUTH_CONSTANTS.FORGOT_PASSWORD_STEPS.SUCCESS,
    };
  }),
  on(authActions.failureForgotSavePassword, (state) => {
    return { ...state, forgotIsLoadingSavePassword: false };
  }),
  on(authActions.login, (state) => {
    return { ...state, loginIsLoading: true };
  }),
  on(authActions.responseLogin, (state) => {
    return { ...state, loginIsLoading: false };
  }),
  on(authActions.failureLogin, (state) => {
    return { ...state, loginIsLoading: false };
  }),
  on(authActions.logoutGuard, authActions.logoutAPI, (state) => {
    return { ...state, userLogged: undefined, isLogged: false };
  }),
  on(authActions.changeEmail, (state) => ({
    ...state,
    isLoadingUpdateProfile: true,
  })),
  on(authActions.successChangeEmail, (state, { email }) => {
    let userLogged;
    if (state.userLogged) {
      userLogged = {
        ...state.userLogged,
        email,
      };
    }

    return {
      ...state,
      isLoadingUpdateProfile: false,
      userLogged,
    };
  }),
  on(authActions.failureChangeEmail, (state) => ({
    ...state,
    isLoadingUpdateProfile: false,
  })),
  on(authActions.saveHomeType, (state, { homeType }) => {
    return { ...state, homeType };
  }),
  on(authActions.goToHomeAfterChangePassword, (state) => {
    return {
      ...state,
      forgotStep: AUTH_CONSTANTS.FORGOT_PASSWORD_STEPS.PHONE_NUMBER
    }
  }),
  on(onboardingActions.successSaveOnboardingInfo, (state, { step }) => {
    let userLogged;

    if (state.userLogged) {
      userLogged = {
        ...state.userLogged,
        step: step - 1
      }
    }

    return {
      ...state,
      userLogged
    };
  })
);

export function AuthReducers(state: AuthState | undefined, action: Action) {
  return _authReducer(state, action);
}
