<header>
  <button
    *ngIf="!showMenuIcon && showGoBackButton; else emptySpace"
    class="go-back-btn ic-left"
    (click)="onGoBackToPreviousPage()"
  >
    <img src="/app/presentation/assets/icons/goback-arrow.svg" alt="Botón ir atrás" />
  </button>
  <layout-icon
    icon="menu"
    (click)="toggleMenu()"
    class="ic-left"
    *ngIf="showMenuIcon && !showGoBackButton; else emptySpace"
  ></layout-icon>
  <ng-template #emptySpace>
    <span></span>
  </ng-template>
  <img src="/app/presentation/assets/logo/logo-promujer.png" (click)="onGotoHome()" />
  <a class="ic-right" [href]="urlWhatsapp" target="_blank" rel="noreferrer noopener" (click)="onOpenWhatsapp()">
    <i class="mo-whatsapp"></i>
  </a>
</header>
