import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, tap, withLatestFrom } from 'rxjs';

import { AUTH_CONSTANTS } from '@app/core/constants/auth.constants';
import {
  GLOBAL_CONSTANTS as CONST,
  GLOBAL_CONSTANTS,
} from '@app/core/constants/global.constants';
import {
  CATEGORIES_EVENT,
  ACTION_EVENT,
} from '@app/core/constants/analytics.constants';
import { StorageService } from '@app/data/browser/storage.service';
import { AuthAPI } from '@app/data/mo-api/auth.api';
import { UserService } from '@app/data/mo-api/user.service';
import { AuthService } from '@app/domain/services/auth.service';
import { UIService } from '@app/core/services/ui.service';
import { GoogleAnalyticsService } from '@app/domain/services/google-analytics.service';

import * as onboardingActions from '../onboarding/onboarding.actions';
import * as authActions from './auth.actions';
import * as authSelectors from './auth.selectors';
import * as appActions from './../app/app.actions';
import * as loanActions from '../loan/loan.actions';
import { environment } from '@environment';
import { FacebookPixelService } from '@app/domain/services/facebook-pixel.service';

@Injectable()
export class AuthEffects {
  constructor(
    private _store: Store,
    private _actions$: Actions,
    private _api: AuthAPI,
    private _userApi: UserService,
    private _router: Router,
    private _storage: StorageService,
    private _authService: AuthService,
    private _uiService: UIService,
    private _gaService: GoogleAnalyticsService,
    private _facebookPixelService: FacebookPixelService
  ) {}

  // #region Signup
  public sendOTPCode$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.initSignup),
      switchMap(({ phone }) => {
        this._gaService.sendEvent(
          CATEGORIES_EVENT.NEW_USER,
          ACTION_EVENT.CLICK,
          'Siguiente'
        );
        this._facebookPixelService.sendEvent(
          CATEGORIES_EVENT.NEW_USER,
          ACTION_EVENT.CLICK,
          'Siguiente'
        );
        if (environment.blockGeneralFlow) {
          return of(appActions.showPageBlockFlowRegister());
        } else {
          return this._api.sendOTP(phone).pipe(
            map(() => authActions.responseSendOTPCode()),
            catchError(() => of(authActions.failureSendOTPCode()))
          );
        }
      })
    )
  );

  public resendOTPCode$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.resendOTP),
      withLatestFrom(this._store.select(authSelectors.signupData)),
      switchMap(([_, signupData]) => {
        if (!signupData) {
          return of(authActions.failureValidateOTPCode());
        }

        this._gaService.sendEvent(
          CATEGORIES_EVENT.NEW_USER,
          ACTION_EVENT.CLICK,
          'Reenvío OTP'
        );
        this._facebookPixelService.sendEvent(
          CATEGORIES_EVENT.NEW_USER,
          ACTION_EVENT.CLICK,
          'Reenvío OTP'
        );
        return this._api.reSendOTP(signupData?.phone).pipe(
          map(() => authActions.responseReSendOTPCode()),
          catchError(() => of(authActions.failureReSendOTPCode()))
        );
      })
    )
  );

  public validateOTPCode$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.validateOTP),
      withLatestFrom(this._store.select(authSelectors.signupData)),
      switchMap(([props, signupData]) => {
        if (!signupData) {
          return of(authActions.failureValidateOTPCode());
        }

        this._gaService.sendEvent(
          CATEGORIES_EVENT.NEW_USER,
          ACTION_EVENT.CLICK,
          'Validación OTP'
        );
        this._facebookPixelService.sendEvent(
          CATEGORIES_EVENT.NEW_USER,
          ACTION_EVENT.CLICK,
          'Validación OTP'
        );
        return this._api
          .validateOTP({ phone: signupData.phone, code: props.code })
          .pipe(
            map(() => authActions.responseValidateOTPCode()),
            catchError(() => of(authActions.failureValidateOTPCode()))
          );
      })
    )
  );

  public validatePassword$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.validatePassword),
      switchMap(({ signUpPayload: data }) => {
        this._gaService.sendEvent(
          CATEGORIES_EVENT.NEW_USER,
          ACTION_EVENT.CLICK,
          'Creación cuenta'
        );
        this._facebookPixelService.sendEvent(
          CATEGORIES_EVENT.NEW_USER,
          ACTION_EVENT.CLICK,
          'Creación cuenta'
        );
        return this._api.signup(data).pipe(
          map(() =>
            authActions.responseValidatePassword({
              phone: data.phone_number,
              password: data.password,
            })
          ),
          catchError(() => of(authActions.failureValidatePassword()))
        );
      })
    )
  );

  public goBackPage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(appActions.goBackToPreviousPage),
      withLatestFrom(this._store.select(authSelectors.signupStep)),
      map(([_, signupStep]) => {
        if (signupStep > AUTH_CONSTANTS.ONBOARDING_STEPS.PHONE_NUMBER) {
          signupStep--;
        }
        if (signupStep < AUTH_CONSTANTS.ONBOARDING_STEPS.PASSWORD) {
          this._store.dispatch(
            appActions.showGoBackButton({ showGoBackButton: false })
          );
        }
        return authActions.setNewStep({ signupStep });
      })
    )
  );

  // #endregion
  public startOnboarding$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(authActions.startOnboardingFromSignup),
        tap(() =>
          this._router.navigateByUrl(
            CONST.COMPLETE_ROUTES.ONBOARDING_PERSONAL_INFO
          )
        )
      ),
    { dispatch: false }
  );

  // #region forgot
  public forgotSendOTPCode$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.forgotSendOTP),
      switchMap(({ phone }) =>
        this._api.forgotSendOTP(phone).pipe(
          map(() => authActions.responseForgotSendOTP()),
          catchError(() => of(authActions.failureForgotSendOTP()))
        )
      )
    )
  );

  public forgotValidateOTPCode$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.forgotValidateOTP),
      withLatestFrom(this._store.select(authSelectors.forgotData)),
      switchMap(([props, forgotData]) => {
        if (!forgotData) {
          return of(authActions.failureForgotResendOTP());
        }

        return this._api
          .forgotValidateOTP({ phone: forgotData.phone, code: props.code })
          .pipe(
            map(() => authActions.responseForgotValidateOTPCode()),
            catchError(() => of(authActions.failureForgotValidateOTPCode()))
          );
      })
    )
  );

  public forgotResendOTPCode$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.forgotResendOTP),
      withLatestFrom(this._store.select(authSelectors.forgotData)),
      switchMap(([_, forgotData]) => {
        if (!forgotData) {
          return of(authActions.failureForgotResendOTP());
        }

        return this._api.forgotSendOTP(forgotData.phone).pipe(
          map(() => authActions.responseForgotResendOTP()),
          catchError(() => of(authActions.failureForgotResendOTP()))
        );
      })
    )
  );

  public forgotSaveNewPassword$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.forgotSaveNewPassword),
      withLatestFrom(this._store.select(authSelectors.forgotData)),
      switchMap(([{ password }, forgotData]) => {
        if (!forgotData) {
          return of(authActions.failureForgotSavePassword());
        }

        return this._api
          .forgotSaveNewPassword({ password, ...forgotData })
          .pipe(
            map(() => authActions.responseForgotSavePassword()),
            catchError(() => of(authActions.failureForgotSavePassword()))
          );
      })
    )
  );

  public successForgotPassword$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(authActions.responseForgotSavePassword),
        tap(() => {
          this._gaService.sendEvent(
            CATEGORIES_EVENT.FORGOT_PASSWORD,
            ACTION_EVENT.VISIT,
            'Actualización exitosa'
          );
          this._facebookPixelService.sendEvent(
            CATEGORIES_EVENT.FORGOT_PASSWORD,
            ACTION_EVENT.VISIT,
            'Actualización exitosa'
          );
        })
      ),
    { dispatch: false }
  );

  public goToHomeAfterChangePassword = createEffect(
    () =>
      this._actions$.pipe(
        ofType(authActions.goToHomeAfterChangePassword),
        tap(() => {
          this._router.navigateByUrl(CONST.ROUTES.LOGIN);
        })
      ),
    { dispatch: false }
  );
  // #endregion

  public login$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.login),
      switchMap((loginData) => {
        this._gaService.sendEvent(
          CATEGORIES_EVENT.LOGIN,
          ACTION_EVENT.CLICK,
          'Iniciar sesión'
        );
        this._facebookPixelService.sendEvent(
          CATEGORIES_EVENT.LOGIN,
          ACTION_EVENT.CLICK,
          'Iniciar sesión'
        );
        return this._api.login(loginData).pipe(
          map(() => authActions.responseLogin()),
          catchError(() => of(authActions.failureLogin()))
        );
      })
    )
  );

  // #region get user data

  public loginEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        authActions.responseLogin,
        authActions.reloadSession,
        onboardingActions.successFinishOnboarding,
        onboardingActions.finishKYCValidation,
        onboardingActions.failureFinishIdentityValidation,
        onboardingActions.successCustomerValidations
      ),
      switchMap(() =>
        this._userApi.getUserInfo().pipe(
          map((user) => {
            this._authService.redirectAfterLogin(user);
            return authActions.responseUserLogin({ user });
          }),
          catchError(() => of(authActions.failureUserLogin()))
        )
      )
    )
  );

  public extraCallsGetUserInfo$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loanActions.successRestartDisbursementError),
      switchMap(() =>
        this._userApi.getUserInfo().pipe(
          // TODO: Change this action to a generic action
          map((user) => authActions.responseUserLogin({ user })),
          catchError(() => of(authActions.failureUserLogin()))
        )
      )
    )
  );

  public getStatusAfterLoanRequest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loanActions.successRequestLoan),
      switchMap(() =>
        this._userApi.getUserInfo().pipe(
          map((user) => {
            return authActions.responseUserLogin({ user });
          }),
          tap(() => {
            this._router.navigateByUrl(GLOBAL_CONSTANTS.ROUTES.DISBURSEMENT);
          })
        )
      )
    )
  );

  public validateUserStatus$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.responseUserLogin, authActions.responseUserGuard),
      map(({ user }) => {
        const homeType = this._authService.getHomeType(user);
        return authActions.saveHomeType({ homeType });
      })
    )
  );

  public loginSingup$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.responseValidatePassword),
      withLatestFrom(this._store.select(authSelectors.selectUser)),
      switchMap(([loginData, userLogged]) => {
        this._store.dispatch(
          appActions.showGoBackButton({ showGoBackButton: false })
        );

        this._gaService.sendEvent(
          CATEGORIES_EVENT.NEW_USER,
          ACTION_EVENT.VISIT,
          `Creación de cuenta exitosa, ID: ${userLogged?.externalId}`
        );
        this._facebookPixelService.sendEvent(
          CATEGORIES_EVENT.NEW_USER,
          ACTION_EVENT.VISIT,
          `Creación de cuenta exitosa, ID: ${userLogged?.externalId}`
        );
        return this._api
          .login(loginData)
          .pipe(map(() => authActions.responseLoginSignup()));
      })
    )
  );

  public loginSignupEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.responseLoginSignup),
      switchMap(() =>
        this._userApi.getUserInfo().pipe(
          map((user) => authActions.responseUserSignup({ user })),
          catchError(() => of(authActions.failureUserLogin()))
        )
      )
    )
  );

  // #endregion

  public logout$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          authActions.logoutGuard,
          authActions.logoutAPI,
          authActions.logoutUserStatus
        ),
        tap(() => {
          this._storage.removeItem(CONST.PROP_STORAGE_TOKEN);
          this._store.dispatch(
            appActions.showMenuIcon({ showMenuIcon: false })
          );
          this._store.dispatch(
            appActions.showGoBackButton({ showGoBackButton: false })
          );
          this._router.navigateByUrl(CONST.ROUTES.LOGIN);
        })
      ),
    { dispatch: false }
  );

  // Change Email
  public changeEmail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.changeEmail),
      switchMap(({ email }) =>
        this._userApi.changeEmail(email).pipe(
          map((response) => authActions.successChangeEmail(response.email)),
          catchError(() => of(authActions.failureChangeEmail))
        )
      )
    )
  );

  //intention signup
  public intentionSignup$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.saveItentionSignup),
      switchMap(({ intentionData }) =>
        this._userApi.saveIntentionSignup(intentionData).pipe(
          map(() => authActions.reponseSaveItentionSignup()),
          catchError(() => of(authActions.failureSaveItentionSignup))
        )
      )
    )
  );

  public intentionSignupSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(authActions.reponseSaveItentionSignup),
        tap(() => {
          this._router.navigateByUrl(
            CONST.COMPLETE_ROUTES.CONFIRMATION_SIGNUP_INTENTION
          );
        })
      ),
    { dispatch: false }
  );
}
