<div class="resume-details">
    <div class="description">
      <h3 class="title">Recuerda</h3>
        <div>
            <p>La cuenta debe <b>estar a tu nombre para poder realizar el desembolso.</b> <br>
            Verifica los siguientes datos antes de continuar. 
            </p>
            <div class="description-items">
            <p>Medio seleccionado:</p>
                <p class="bolder">Cuenta bancaria</p>
            </div>
            <div class="description-items">
            <p>Banco</p>
                <p class="bolder">{{ data.data.nameBank }}</p>
            </div>
            <div class="description-items">
            <p>CLABE</p>
                <p class="bolder">{{ data.data.clabeOrDebitCard }}</p>
            </div>
            <div class="mo-button-container">
                <mo-button text="Confirmar datos" 
                    type="submit" 
                    (click)="confirmData()"
                > </mo-button>
              </div>
            
              <div class="mo-button-container">
                <mo-button 
                    text="Editar" 
                    [template]="BUTTON_TEMPLATES.OUTLINE_PRIMARY" 
                    type="submit" 
                    (click)="closeModal()"
                > </mo-button>
              </div>
        </div>
    </div>
</div>