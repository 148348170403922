<div>
  <menu-app
    (logout)="logOut()"
    [configuration]="configurationMenu"
    [showActionButton]="true"
    actionText="Cerrar Sesión"
  ></menu-app>
  <div class="container-layout-primary image-pattern">
    <layout-header
      (goHome)="onGotoHome()"
      style="z-index: 100"
      [showMenuIcon]="showMenuIcon$ | async"
      [showGoBackButton]="showGoBackButton$ | async"
    ></layout-header>
    <main class="box-layout-content">
      <div id="top-view"></div>
      <div>
        <router-outlet></router-outlet>
      </div>
    </main>

    <layout-footer></layout-footer>
  </div>
</div>
