import { Component, Input, OnInit } from "@angular/core";
import { User } from "@app/core/models/user.model";

@Component({
  selector: "logged-user",
  templateUrl: "./logged-user.component.html",
  styleUrls: ["./logged-user.component.scss"],
})
export class LoggedUserComponent implements OnInit {
  @Input() user!: User;
  constructor() {}

  ngOnInit() {}
}
