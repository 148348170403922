import { DISBURSEMENT_STATUS, MOVEMENT_TYPE } from '@app/core/models/loan.model';

export const LOAN_DOCUMENTS = {
  urls: {
    BORROWER_NOTE_CONTRACT: {
      url: 'https://www.orimi.com/pdf-test.pdf',
    },
    CREDIT_CONTRACT: {
      url: 'app/presentation/assets/documents/contrato-credito.pdf',
    },
    PAYMENTS_PLAN: {
      url: 'https://www.orimi.com/pdf-test.pdf',
    },
    ACCOUNT_STATUS: {
      url: 'app/presentation/assets/documents/contrato-credito.pdf',
    },
  },
};
export const LOAN_MATH = {
  interest_percentage: 100,
  quota_amount: '1519',
  taxes_amount: '15',
  total_amount: '6045',
  quotas: 6,
  url_payment_plan: {
    PAYMENT_PLAN_SIMULATOR: {
      url: 'http://localhost:4200/app/presentation/assets/documents/contrato-credito.pdf',
    },
  },
};

export const LOAN_DETAILS = {
  quota_amount: 75.0,
  installment_date: '2022-01-01',
  contract_number: '3189898',
  loan_date: '2021-01-01',
  taxes_annual_amount: 100,
  total_amount: 6000,
  paid_amount: 6045.0,
  pending_amount: 1000,
  collection: 50,
  loan_duration: '1',
  movements: [
    {
      type: MOVEMENT_TYPE.PAYMENT,
      date: '2022-03-15',
      amount: 75,
    },
    {
      type: MOVEMENT_TYPE.DISBURSEMENT,
      date: '2022-03-16',
      amount: 85,
    },
    {
      type: MOVEMENT_TYPE.REFUND,
      date: '2022-03-18',
      amount: 100,
    },
    {
      type: MOVEMENT_TYPE.PAYMENT,
      date: '2022-4-21',
      amount: 2001,
    },
    {
      type: MOVEMENT_TYPE.DISBURSEMENT,
      date: '2022-05-15',
      amount: 710,
    },
    {
      type: MOVEMENT_TYPE.REFUND,
      date: '2022-06-15',
      amount: 200,
    },
    {
      type: MOVEMENT_TYPE.DISBURSEMENT,
      date: '2022-03-16',
      amount: 99000,
    },
    {
      type: MOVEMENT_TYPE.REFUND,
      date: '2022-03-18',
      amount: 100,
    },
    {
      type: MOVEMENT_TYPE.PAYMENT,
      date: '2022-4-21',
      amount: 2001,
    },
  ],
  url: [
    {
      type: 'barcode-img',
      url: 'https://promujer-alpha-data-transfer-partner.s3.amazonaws.com/barcodes/customer%3D4159/loan%3D2476/barcode-980000000000024765.png?AWSAccessKeyId=ASIA4DMEXJ65SNXWZBGW&Signature=AsB3WD3iRVOI3LVlgxreE%2B%2Fn0iM%3D&x-amz-security-token=FwoGZXIvYXdzEOL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDGB%2B%2FGn%2BOf%2Bvs7kvBCKqAZVuk5sg2Xegm7P%2FdV7YISleqiv7jKp8zoNDIkCMosPz1v3rcLujo3TBNisesmd9TqFXarAee8fOxd005vkvwJGNG0Y%2BOmEUj46ORUvJsue7Ex79jb3r1Y6%2FtaOzH1YPrCorQ9cKXUnhU4%2B5GXaidwsRBSk9Oqv%2B282x7ubQQ1WIUzxMdJTuPi1AdhZvxdHhDMRAHlhbDxydbiBBauVQWGwjS8oUIIvWu9TFKIzu7pwGMi0T5y0sMwBU5hxPBWJWwbiYxxnnVqMa2xBR8HbEN3ZlXZ09MXreBHQbzwzbguc%3D&Expires=1671149968',
    },

    {
      type: 'barcode-pdf',
      url: 'https://promujer-alpha-data-transfer-partner.s3.amazonaws.com/barcodes/customer%3D4159/loan%3D2476/barcode-980000000000024765.pdf?AWSAccessKeyId=ASIA4DMEXJ65SNXWZBGW&Signature=5IDxwqEA5D%2BPBtklcwJZPHK3k%2F8%3D&x-amz-security-token=FwoGZXIvYXdzEOL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDGB%2B%2FGn%2BOf%2Bvs7kvBCKqAZVuk5sg2Xegm7P%2FdV7YISleqiv7jKp8zoNDIkCMosPz1v3rcLujo3TBNisesmd9TqFXarAee8fOxd005vkvwJGNG0Y%2BOmEUj46ORUvJsue7Ex79jb3r1Y6%2FtaOzH1YPrCorQ9cKXUnhU4%2B5GXaidwsRBSk9Oqv%2B282x7ubQQ1WIUzxMdJTuPi1AdhZvxdHhDMRAHlhbDxydbiBBauVQWGwjS8oUIIvWu9TFKIzu7pwGMi0T5y0sMwBU5hxPBWJWwbiYxxnnVqMa2xBR8HbEN3ZlXZ09MXreBHQbzwzbguc%3D&Expires=1671149970',
    },
  ],
};

export const DISBURSEMENT_LOAN_RESPONSE = {
  disburse_status: DISBURSEMENT_STATUS.FAILED,
};
