import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ISignUpRequest } from '@app/core/models/signup.model';
import { IForgotRequest, LoginData } from '@app/core/models/auth.model';
import { URLS } from './url.constants';
import { GLOBAL_CONSTANTS } from '@app/core/constants/global.constants';

@Injectable({ providedIn: 'root' })
export class AuthAPI {
  constructor(private _http: HttpClient) {}

  public sendOTP(phone: string): Observable<any> {
    return this._http.post(URLS.SEND_OTP_CODE, { phone_number: phone, type_otp: GLOBAL_CONSTANTS.OTP.SIGNUP});
  }

  public reSendOTP(phone: string): Observable<any> {
    return this._http.post(URLS.SEND_OTP_CODE, { phone_number: phone, type_otp: GLOBAL_CONSTANTS.OTP.SIGNUP});
  }

  public validateOTP({ phone, code }: { phone: string; code: string }): Observable<any> {
    return this._http.patch(URLS.VALIDATE_OTP_CODE, { phone_number: phone, code, type_otp: GLOBAL_CONSTANTS.OTP.SIGNUP });
  }

  public forgotValidateOTP({ phone, code }: { phone: string; code: string }): Observable<any> {
    return this._http.patch(URLS.VALIDATE_OTP_CODE, { phone_number: phone, code, type_otp: GLOBAL_CONSTANTS.OTP.FORGOT_PASSWROD });
  }

  public signup(signUpPayload: ISignUpRequest): Observable<any> {
    return this._http.post(URLS.SIGN_UP, signUpPayload);
  }

  public forgotSendOTP(phone: string): Observable<any> {
    return this._http.post(URLS.FORGOT_SEND_OTP_CODE, { phone_number: phone, type_otp: GLOBAL_CONSTANTS.OTP.FORGOT_PASSWROD });
  }

  public forgotSaveNewPassword({ phone, code, password }: IForgotRequest): Observable<any> {
    return this._http.post(URLS.FORGOT_SAVE_NEW_PASSWORD, {
      username: phone,
      code,
      password,
      re_password: password,
    });
  }

  public login({ phone, password }: LoginData): Observable<any> {
    return this._http.post(URLS.LOGIN, { username: phone, password });
  }
}
