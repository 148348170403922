import { LoanState } from './loan.state';
import { createReducer, Action, on } from '@ngrx/store';
import * as loanActions from './loan.actions';

export const initialLoanState: LoanState = {
  step: 1,
  url: undefined,
  dynamicDocuments: {},
  isLoadingDynamicDocuments: false,
  isLoadingGetStatusDisburse: false,
};

const _loanReducer = createReducer(
  initialLoanState,
  on(loanActions.getDynamicDocuments, (state) => {
    return {
      ...state,
      isLoadingDynamicDocuments: true,
    };
  }),
  on(loanActions.successGetDynamicDocuments, (state, { dynamicDocuments }) => {
    return {
      ...state,
      step: state.step + 1,
      dynamicDocuments,
      isLoadingDynamicDocuments: false,
    };
  }),
  on(loanActions.failureGetDynamicDocuments, (state) => {
    return {
      ...state,
      isLoadingDynamicDocuments: false,
    };
  }),
  on(loanActions.acceptLoan, (state) => {
    return {
      ...state,
      step: state.step + 1,
    };
  }),
  on(loanActions.setPreviousStep, (state) => {
    return {
      ...state,
      step: state.step - 1,
    };
  }),
  on(loanActions.calculateLoanMath, (state, { loan }) => {
    return { ...state, isLoadingCalculateLoanMath: true, loanParams: loan };
  }),
  on(loanActions.successCalculateLoanMath, (state, { loanMath }) => {
    return { ...state, isLoadingCalculateLoanMath: false, loanMath };
  }),
  on(loanActions.failureCalculateLoanMath, (state) => {
    return { ...state, isLoadingCalculateLoanMath: false };
  }),
  on(loanActions.successGetBankList, (state, { bankList }) => {
    return { ...state, bankList };
  }),
  on(loanActions.requestLoan, (state) => {
    return { ...state, isLoadingRequestLoan: true };
  }),
  on(loanActions.successRequestLoan, (state) => {
    return { ...state, isLoadingRequestLoan: false, step: 1 };
  }),
  on(loanActions.failureRequestLoan, (state) => {
    return { ...state, isLoadingRequestLoan: false };
  }),
  on(loanActions.disburseLoan, (state) => {
    return { ...state, isLoadingDisburseLoan: true };
  }),
  on(loanActions.blockFlowLoanDisbursment, (state) => {
    return { ...state, isLoadingDisburseLoan: false };
  }),
  on(loanActions.successRequestDisburseLoan, (state) => {
    return { ...state, isLoadingDisburseLoan: false };
  }),
  on(loanActions.failureRequestDisburseLoan, (state) => {
    return { ...state, isLoadingDisburseLoan: false };
  }),
  on(loanActions.getCurrentLoan, (state) => {
    return { ...state, isLoadingGetCurrentLoan: true };
  }),
  on(loanActions.successGetCurrentLoan, (state, { loan }) => {
    return { ...state, isLoadingGetCurrentLoan: false, currentLoan: loan };
  }),
  on(loanActions.failureGetCurrentLoan, (state) => {
    return { ...state, isLoadingGetCurrentLoan: false };
  }),
  on(loanActions.startGetStatusDisburseLoan, (status) => {
    return {
      ...status,
      isLoadingGetStatusDisburse: true,
    };
  }),
  on(loanActions.successStartGetStatusDisburseLoan, (status) => {
    return {
      ...status,
      isLoadingGetStatusDisburse: false,
    };
  }),
  on(loanActions.failureStartGetStatusDisburseLoan, (status) => {
    return {
      ...status,
      isLoadingGetStatusDisburse: false,
    };
  }),
  on(loanActions.finishGetStatusDisburseLoan, (state, { disburseStatus }) => {
    return {
      ...state,
      disbursementState: disburseStatus,
    };
  }),
  on(loanActions.successDownloadAccountStatus, (state, { url }) => {
    return { ...state, url };
  })
);

export function LoanReducers(state: LoanState | undefined, action: Action) {
  return _loanReducer(state, action);
}
