import { Component } from '@angular/core';
import {
  CATEGORIES_EVENT,
  ACTION_EVENT,
} from '@app/core/constants/analytics.constants';
import { GoogleAnalyticsService } from '@app/domain/services/google-analytics.service';
import { AuthFacade } from '@app/facades/auth.facade';
import { LoanFacade } from '@app/facades/loan.facade';

@Component({
  selector: 'success-request-manual-disbursement',
  templateUrl: './success-request-manual-disbursement.component.html',
  styleUrls: ['./success-request-manual-disbursement.component.scss'],
})
export class SuccessRequestManualDisbursementComponent {
  public userId: string | undefined;

  constructor(
    private _facade: LoanFacade,
    private _authFacade: AuthFacade,
    private _gaService: GoogleAnalyticsService
  ) {}

  onFinish() {
    this._facade.logoutUser();
    this._authFacade.user$.subscribe((user) => {
      return (this.userId = user?.externalId);
    });
    this._gaService.sendEvent(
      CATEGORIES_EVENT.NEW_USER,
      ACTION_EVENT.CLICK,
      `Solicitud de Desembolso Exitoso, ID: ${this.userId}`
    );
  }
}
