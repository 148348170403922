<div class="loan-details">
  <div class="description">
    <h3 class="title">Detalles del crédito</h3>
    <div>
      <div class="description-items">
        <p>Fecha de solicitud</p>
        <p class="bolder">{{ loan.loanDate | MODate }}</p>
      </div>
      <div class="description-items">
        <p>Número de contrato</p>
        <p class="bolder">{{ loan.contractNumber }}</p>
      </div>
      <div class="description-items">
        <p>Valor total pagado</p>
        <p class="bolder">{{ loan.paidAmount | MOCurrency }}</p>
      </div>
      <div class="description-items">
        <p>Saldo pendiente por pagar</p>
        <p class="bolder">{{ loan.pendingAmount | MOCurrency }}</p>
      </div>
      <div class="description-items">
        <p>Comisión</p>
        <p class="bolder">{{ loan.collection | MOCurrency }}</p>
      </div>
      <div class="description-items">
        <div>
          <p>Tasa de interés anual</p>
          <p class="iva">IVA incluido</p>
        </div>
        <p class="bolder">{{ loan.taxesAnnualAmount }}%</p>
      </div>
      <div class="description-items">
        <p>Plazo</p>
        <p class="bolder">{{ loan.loanDuration }} meses</p>
      </div>
      <p class="downloadable-text">
        Descargar <a tabindex="1" (click)="onDownloadContract()">contrato</a>
      </p>
      <p class="downloadable-text">
        Descargar
        <a tabindex="2" (click)="onDownloadPromissoryNote()">pagaré</a>
      </p>

      <p class="bolder documents-comment">Documentos disponibles:</p>

      <div class="button-container">
        <mo-button
          text="Plan de pagos"
          (click)="onDownloadPaymentPlan()"
          [isDisabled]="false"
          [size]="BUTTON_SIZE.SM"
          [template]="BUTTON_TEMPLATES.OUTLINE_PRIMARY"
        ></mo-button
        ><mo-button
          text="Estado de cuenta"
          (click)="onDownloadAccountStatus()"
          [isDisabled]="false"
          [size]="BUTTON_SIZE.SM"
          [template]="BUTTON_TEMPLATES.OUTLINE_PRIMARY"
        ></mo-button>
      </div>

      <div class="description-items">
        <mo-button class="mo-button" text="Cerrar" (click)="closeModal()">
        </mo-button>
      </div>
    </div>
  </div>
</div>
