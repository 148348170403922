import { PipeTransform, Pipe } from "@angular/core";
import { formatDate } from "@angular/common";
import { GLOBAL_CONSTANTS } from "@app/core/constants/global.constants";

@Pipe({
  name: "MODate",
})
export class MODatePipe implements PipeTransform {
  transform(value: any, ...args: any[]): string {
    const format = args && args.length > 0 ? args.shift() : "dd/MM/yyyy";
    const date = formatDate(value, format, GLOBAL_CONSTANTS.DEFAULT_LANGUAGE);
    return this._titleCase(date);
  }

  private _titleCase(value: any) {
    return value.replace(/\w\S*/g, (txt: any) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}
