import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { environment } from '@environment';
import { GLOBAL_CONSTANTS as CONST } from '../constants/global.constants';

@Injectable({
  providedIn: 'root',
})
export class DevelopGuard implements CanActivate, CanActivateChild {
  constructor(private _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this._validateIsDevEnv();
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this._validateIsDevEnv();
  }

  private _validateIsDevEnv() {
    if (environment.production) {
      this._router.navigateByUrl(CONST.ROUTES.ROOT);
      return false;
    }
    return true;
  }
}
