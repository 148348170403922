import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  Router,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, first, map, Observable } from 'rxjs';
import * as authSelectors from '@store/auth/auth.selectors';
import { USER_STATUS } from '../models/user.model';
import { GLOBAL_CONSTANTS } from '../constants/global.constants';

@Injectable({
  providedIn: 'root',
})
export class DisbursementGuard implements CanActivate, CanActivateChild {
  constructor(private _router: Router, private _store: Store) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._validateIsInDisbursement();
  }
  canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._validateIsInDisbursement();
  }

  private _validateIsInDisbursement(): Observable< boolean | UrlTree> {
    return this._store.select(authSelectors.selectUser).pipe(
      filter(Boolean),
      first(),
      map((user) => {
        return (user?.status === USER_STATUS.DISBURSE_LOAN || user?.status === USER_STATUS.DISBURSE_ERROR) ? true: this._router.createUrlTree([GLOBAL_CONSTANTS.ROUTES.HOME]);
      })
    );
  }
}
