import { Injectable } from '@angular/core';
import { ONBOARDING_CONSTANTS } from '@app/core/constants/onboarding.constants';
import { UIService } from '@app/core/services/ui.service';
import { Store } from '@ngrx/store';
import { catchError, finalize, interval, of, skipWhile, switchMap, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(private _store: Store, private _uiService: UIService) {}

  public startPolling({
    interval: intervalTime = ONBOARDING_CONSTANTS.TIME_MS_TO_GET_VALIDATION_STATUS,
    attempts = ONBOARDING_CONSTANTS.ATTEMPTS_TO_GET_VALIDATION_STATUS,
    closeDialog = true,
    apiCall = () => of(true),
    validateFinished = () => true,
    actionSuccess,
    actionFailure,
  }: any) {
    return interval(intervalTime).pipe(
      take(attempts),
      switchMap(() => apiCall()),
      skipWhile((data) => !validateFinished(data)),
      take(1),
      finalize(() => {
        if (closeDialog) this._uiService.closeDialog();
        if (actionSuccess) this._store.dispatch(actionSuccess());
      }),
      catchError((error) => {
        this._uiService.closeDialog();
        if (actionFailure) this._store.dispatch(actionFailure());
        throw error;
      })
    );
  }
}
