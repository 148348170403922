import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GLOBAL_CONSTANTS as CONST } from '@app/core/constants/global.constants';
import { IModalParams } from '../modal-config.interface';
import { Modal } from '../modal.base';

@Component({
  selector: 'layout-modal-signup-error',
  templateUrl: './modal-signup-error.component.html',
  styleUrls: ['../modal.component.scss'],
})
export class ModalSignupErrorComponent extends Modal {
  public loginURL: string;

  constructor(
    public override modalRef: MatDialogRef<ModalSignupErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public override data: IModalParams
  ) {
    super(modalRef, data);
    this.loginURL = CONST.ROUTES.LOGIN;
  }

  onRedirect() {
    this.modalRef.close();
  }
}
