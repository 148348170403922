<section class="message-container box-content-shadow">
  <layout-message-status
    [type]="type"
    title="¡Tu desembolso no pudo ser procesado!"
    message="Lo sentimos, la transferencia ha presentado una falla, recomendamos que te comuniques con tu banco o comunícate con un asesor a la línea {{
      whatsappNumber
    }}"
  ></layout-message-status>

  <mo-button text="Reintentar desembolso" (click)="onFinish()"></mo-button>
</section>
