import { Component, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { AppFacade } from '../../../../facades/app.facade';
import { GLOBAL_CONSTANTS as CONST, GLOBAL_CONSTANTS } from '@app/core/constants/global.constants';
import { Menu } from '@app/core/models/menu.model';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { AuthFacade } from '@app/facades/auth.facade';
import { logoutGuard } from '../../../../domain/store/auth/auth.actions';

@Component({
  selector: 'layout-base-layout',
  templateUrl: './base-layout.component.html',
})
export class BaseLayoutComponent {
  public showMenuIcon$: Observable<boolean>;
  public configurationMenu!: Array<Menu>;
  public showGoBackButton$: Observable<boolean>;
  public currentRoute$: Observable<string | undefined>;
  public creditFormRoute: string = CONST.ROUTES.LOAN;

  constructor(
    private _appFacade: AppFacade,
    private _authFacade: AuthFacade,
    private _router: Router
  ) {
    this.configurationMenu = [
      { title: 'Inicio', url: CONST.ROUTES.HOME },
      { title: 'Mi Perfil', url: CONST.ROUTES.PROFILE },
      { title: 'Ayuda', url: CONST.ROUTES.SUPPORT },
    ];
    this.showGoBackButton$ = this._appFacade.showGoBackButton$;
    this.showMenuIcon$ = this._appFacade.showMenuIcon$;
    this.currentRoute$ = this._appFacade.currentRoute$;
  }
  public logOut() {
    this._authFacade.logOut();
  }
  public onGotoHome() {
    this._router.navigateByUrl(GLOBAL_CONSTANTS.ROUTES.HOME);
  }
}
