import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { defer, from, map, Observable } from 'rxjs';
import {
  ILoanLegalDocuments,
  LoanMath,
  LoanParams,
  Bank,
  BankInfo,
  LoanDetails,
  DisbursementStatusResponse,
  ILoanLegalUrlDocuments,
} from '../../core/models/loan.model';
import { URLS } from './url.constants';
import { formatDate } from '@angular/common';
import { GLOBAL_CONSTANTS } from '@app/core/constants/global.constants';

@Injectable({ providedIn: 'root' })
export class LoanAPI {
  constructor(private _http: HttpClient) {}

  public getDynamicDocuments(
    documents: Array<string>,
    loan: LoanParams | string
  ): Observable<ILoanLegalDocuments> {
    let params: any = { documents_name: documents };
    if (typeof loan === 'string') {
      params.loan_id = loan;
    } else {
      params = { ...params, ...loan };
    }

    return this._http
      .post<{ urls: ILoanLegalDocuments }>(URLS.LOAN_GET_DOCUMENTS, params)
      .pipe(map(({ urls }) => urls));
  }

  public getAccountStatus(): Observable<ILoanLegalUrlDocuments> {
    return this._http.get<ILoanLegalUrlDocuments>(URLS.ACCOUNT_STATUS);
  }
  public getPaymentPlan(): Observable<ILoanLegalUrlDocuments> {
    return this._http.get<ILoanLegalUrlDocuments>(URLS.LOAN_DETAILS);
  }

  public calculateLoanMath(loan: LoanParams): Observable<LoanMath> {
    return this._http.post<LoanMath>(URLS.LOAN_SIMULATOR, { ...loan });
  }

  public getBankList(): Observable<Bank[]> {
    return this._http.get<Bank[]>(URLS.GET_BANK_LIST);
  }

  public requestLoan(loan: LoanParams): Observable<null> {
    const firstPaymentDate = formatDate(
      loan.firstPaymentDate,
      'YYYY-MM-dd',
      GLOBAL_CONSTANTS.DEFAULT_LANGUAGE
    );
    const params = {
      ...loan,
      firstPaymentDate,
      termsAndConditions: true,
      creditContract: true,
      borrowerNoteContract: true,
    };
    return this._http.post<null>(URLS.LOAN_REQUEST, params);
  }

  public disburseLoan(bankInfo: BankInfo) {
    const data = { ...bankInfo, disbursementId: bankInfo.paymentMethod };
    return this._http.post<null>(URLS.LOAN_DISBURSE, data);
  }

  public getLoanDetails(loanId: string): Observable<LoanDetails> {
    return this._http.get<LoanDetails>(`${URLS.LOAN_DETAILS}${loanId}/`);
  }

  public getDisburseLoanStatus(): Observable<DisbursementStatusResponse> {
    return this._http.get<DisbursementStatusResponse>(`${URLS.LOAN_DISBURSE}`);
  }

  public restartDisbursementError(): Observable<null> {
    return this._http.patch<null>(`${URLS.LOAN_DISBURSE}`, {});
  }

  public validUrlContract(url: string): Observable<any> {
    return defer(() => from(this._getUrlContract(url)));
  }

  private _getUrlContract(url: string): Promise<any> {
    return fetch(url)
      .then((response) => response)
      .catch((error) => error);
  }
}
