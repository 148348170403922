<section class="message-container box-content-shadow">
    <layout-message-status
      [type]="type"
      title="¡Lo sentimos, tu desembolso no pudo ser procesado!"
      message= 'Tu solicitud de crédito ha sido rechazada por alguna de las siguientes razones:

                <ul>
                    <li>
                        No pudimos verificar tu identidad a través de la videollamada.
                    </li>

                    <li>
                        La cuenta registrada para la transferencia no está a tu nombre.
                    </li>
                </ul>
                
                Si tienes alguna inquietud, escribenos y uno de nuestros agentes te atenderá.'>
    </layout-message-status>
  
    <a [href]="urlWhatsapp" class="a-whatsapp" target="_blank">
        
        <div class="icon-position-right bc-green">
            <span>
                Ir a WhatsApp
            </span>
            <i class="mo-whatsapp"></i>
        </div>
        
    </a>
  </section>
  