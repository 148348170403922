import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';

import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { IconModule } from './components/icon/icon.module';
import { LoggedUserComponent } from './components/logged-user/logged-user.component';
import { MenuComponent } from './components/menu/menu.component';
import { MOCurrencyPipe } from './pipes/currency.pipe';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { MODatePipe } from './pipes/date.pipe';
import { TooltipDirective } from './directives/tooltip.directive';
import { ModalComponent } from './components/modal/modal.component';
import { UIService } from '@app/core/services/ui.service';
import { MoButtonModule } from '../mo-forms/components/mo-button/mo-button.module';
import { ModalSignupErrorComponent } from './components/modal/modal-signup-error/modal-signup-error.component';
import { MessageStatusComponent } from './components/message-status/message-status.component';
import { BaseLayoutComponent } from './components/base-layout/base-layout.component';
import { ModalDetailComponent } from './components/modal/modal-detail/modal-detail.component';
import { MOFilterPipe } from './pipes/mo-filter.pipe';
import { IMaskModule } from 'angular-imask';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PercentagePipe as MOPercentagePipe } from './pipes/percentage.pipe';
import { MessageErrorBankComponent } from './components/message-error-bank/message-error-bank.component';
import { RejectedComponent } from './components/message-loan-rejected/message-loan-rejected.component';
import { ErrorRequestManualDisbursementComponent } from './components/error-request-manual-disbursement/error-request-manual-disbursement.component';
import { SuccessRequestManualDisbursementComponent } from './components/success-request-manual-disbursement/success-request-manual-disbursement.component';
import { ModalConfirmationDataComponent } from './components/modal/modal-confirmation-data/modal-confirmation-data.component';

const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  MenuComponent,
  LoggedUserComponent,
  ProgressBarComponent,
  ModalComponent,
  ModalSignupErrorComponent,
  ModalDetailComponent,
  ModalConfirmationDataComponent,
  MessageStatusComponent,
  BaseLayoutComponent,
  MessageErrorBankComponent,
  RejectedComponent,
  ErrorRequestManualDisbursementComponent,
  SuccessRequestManualDisbursementComponent
];
const PIPES = [MODatePipe, MOCurrencyPipe, MOFilterPipe, MOPercentagePipe];
const DIRECTIVES = [TooltipDirective];

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    MoButtonModule,
    MatProgressBarModule,
    MatDialogModule,
    MatExpansionModule,
    MatSnackBarModule,
    RouterModule,
    IMaskModule,
  ],
  declarations: [...COMPONENTS, ...PIPES, DIRECTIVES],
  exports: [
    ...COMPONENTS,
    ...PIPES,
    IconModule,
    MatProgressBarModule,
    MatExpansionModule,
    IMaskModule,
    DIRECTIVES,
  ],
  providers: [UIService],
})
export class MOLayoutModule {}
