export const ERROR_CONSTANTS = {
  MESSAGES: {
    ERROR_EMAIL_CONFIRMATION: 'Confirma el correo electrónico',
    ERROR_CURRENT_EMAIL: 'Correo actual incorrecto',
    ERROR_STREET_PATTERN:
      'Detectamos que ingresaste caracteres no permitidos, por favor validar nuevamente.',
    ERROR_CURRENT_PASSWORD: 'Contraseña actual incorrecta',
    ERROR_LOGIN_INVALID: 'Usuario o contraseña incorrecta',
    ERROR_CELLPHONE_NUMBER_INCORRECT: 'Número de celular incorrecto',
    ERROR_CREATE_CUSTOMER_INCORRECT: 'Correo electrónico o número de celular inválido',
    ERROR_CODE_NUMBER_INCORRECT: 'Código inválido',
    ERROR_EMAIL_INVALID:
      'Ingresaste un formato de correo electrónico invalido. Valida la información y vuelve a intentar.',
    ERROR_LETTER_FIELD_INVALID: 'Ingresa tu {{name}} sin incluir caracteres especiales',
    ERROR_PASSWORD_CONFIRMATION: 'Las contraseñas deben ser iguales',
    ERROR_PASSWORD_INVALID: 'La contraseña debe cumplir con los elementos de seguridad',
    ERROR: 'Ha ocurrido un error vuelve a intentar de nuevo',
    ERROR_USER_ALREADY_EXISTS: 'Para ese código CND ya hay otro número de celular registrado',
    ERROR_USER_DOES_NOT_EXISTS:
      'Aún no tenemos disponible este servicio para ti, espéralo muy pronto.<br /> Te estaremos notificando',
    ERROR_USER_FIELDS: 'Alguno de los campos no coincide',
    ERROR_PAYMENT_TYPE_REQUIRED: 'Debes selecionar la opción de pago',
    ERROR_PAYMENT_AMOUNT_REQUIRED: 'Debes selecionar un tipo de pago',
    ERROR_FIELD_REQUIRED: 'Debes completar todos los campos para poder continuar.',
    ERROR_FIELD_NUMBER_MIN_18: 'El CURP debe contener 18 caracteres, intente nuevamente',
    ERROR_FIELD_NUMBER_MIN_10: 'Número de teléfono invalido',
    ERROR_FIELD_NUMBER_MIN_4: 'Tu código debe tener un mínimo de 4 dígitos',
    ERROR_WEBSITE_INVALID: 'Please fill in a valid website',
    ERROR_UPLOADING_FILE: 'Ocurrió un error mientras se subía el archivo, inténtalo nuevamente',
    ERROR_NOT_FOUND_RESOURCE: 'El recurso solicitado no está disponible, intente nuevamente',
    ERROR_FILE_EMPTY: 'El archivo subido no contiene información, inténtalo nuevamente.',
    ERROR_FILE_HEAVY: 'El archivo subido es demasiado grande, inténtalo nuevamente.',
    ERROR_FILE_EXTENSION:
      'Recuerda que el archivo debe ser un documento PDF o una imagen, inténtalo nuevamente.',
    ERROR_FILE_REQUIRED: 'Debe seleccionar un archivo',
    ERROR_UPLOADING: 'Ocurrió un error mientras se subía el archivo, inténtalo nuevamente',
    SESSION_FINISHED:
      'La sesión es inválida o ha finalizado! Por favor ingrese nuevamente desde el portal',
    CONFIRM_GO_OUT_ON_BOARDING:
      '¿Quieres continuar con la aplicación al <span class="no-wrap">préstamo KEO? </span>',
    TEXT_SUCCESS_APPLY:
      'Ya tenemos tus datos y serás de los primeros en saber de los préstamos KEO para clientes BEES.',
    WHATSAPP_MESSAGE: 'Hola, ¡Necesito ayuda con el crédito KEO para usuarios BEES!',
    INFO_KEO_ID:
      'Este es tu número de identificación con KEO, lo vas a necesitar para activar tu tarjeta y cuando llames a servicio al cliente.',
    INFO_CELLPHONE:
      'Tu número de Monedero PAYBACK está impreso al reverso, debajo del campo de firma',
    ERROR_TYC_REQUIRED: 'Debes aceptar Términos y Condiciones para continuar',
    ERROR_POLICY_PRIVACY_REQUIRED: 'Debes aceptar las Políticas de Privacidad para continuar',
    ERROR_LOAN_EXPIRED: 'No encontramos la información del préstamo que intentas solicitar',
    ERROR_SESSION: 'La sesión es inválida o ha finalizado! Por favor ingrese nuevamente!',
  },
};
