export enum SIZES {
  SM = 'size-sm',
  MD = 'size-md',
  LG = 'size-lg',
  LG_TOOGLE = 'size-lgt',
}

export enum BUTTON_TEMPLATE {
  DEFAULT = 'template-default',
  OUTLINE_DEFAULT = 'outline-default',
  OUTLINE_PRIMARY = 'outline-primary',
  PRIMARY = 'template-primary',
  ALTERNATIVE = 'template-alternative',
  ALTERNATIVE_ACTIVE = 'template-alternative active',
}

export enum BUTTON_TYPE {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset',
}

export enum ICON_POSITION {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum COLOR_TEMPLATE {
  DEFAULT = 'default',
  LIGHT = 'light',
  PRIMARY = 'primary',
}

export enum INPUT_TYPE {
  TEXT = 'text',
  NUMBER = 'number',
  PHONE = 'phone',
  EMAIL = 'email',
  PASSWORD = 'password',
  RE_PASSWORD = 're_password',
  DATE = 'date',
  CUSTOM = 'custom',
  LETTER = 'letter',
  CURRENCY = 'currency',
  SEARCH = 'search',
}
export enum FILE_TYPE {
  CSV = 'text/csv',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PNG = 'image/png',
  JPG = 'image/jpg',
  PDF = 'application/pdf',
}
