import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Menu } from '@app/core/models/menu.model';
import { AppFacade } from '@app/facades/app.facade';
import { BUTTON_TEMPLATE, SIZES } from '@app/presentation/mo-forms/enums/fields.type';

@Component({
  selector: 'menu-app',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @Input() configuration!: Array<Menu>;
  @Input() showActionButton!: boolean;
  @Input() actionText!: string;
  @Output() logout = new EventEmitter<boolean>();

  public styleBackground: string = 'animate__fadeIn';
  public styleMenu: string = 'animate__fadeInDown';
  public isOpenMenu: boolean = false;

  public BUTTON_TEMPLATES = BUTTON_TEMPLATE;
  public BUTTON_SIZE = SIZES;

  constructor(private _appFacade: AppFacade, private router: Router) {
    this._appFacade.isOpenMenu$.subscribe((isOpenMenu) => {
      this._toggleMenu(isOpenMenu);
    });
  }

  ngOnInit() {}

  private _toggleMenu(isOpenMenu: boolean) {
    if (isOpenMenu) {
      this.styleBackground = 'animate__fadeIn';
      this.styleMenu = 'animate__fadeInDown';
      this.isOpenMenu = isOpenMenu;
    } else {
      this.styleBackground = 'animate__fadeOut';
      this.styleMenu = 'animate__fadeOutUp';
      setTimeout(() => {
        this.isOpenMenu = isOpenMenu;
      }, 1000);
    }
  }

  public goToPath(path?: string) {
    this._appFacade.collapseMenu();
    this.router.navigate([path]);
  }

  public onLogout() {
    this.logout.emit(true);
    this._appFacade.collapseMenu();
  }
}
