<section class="mesage-status">
  <layout-icon
    *ngIf="type === 'success'"
    class="icon-main"
    icon="image/icon-success.svg"
    color="primary"
  ></layout-icon>

  <layout-icon
    *ngIf="type === 'alert'"
    class="icon-main"
    icon="image/icon-alert.svg"
    color="primary"
  ></layout-icon>
  <layout-icon
    *ngIf="type === 'danger'"
    class="icon-main"
    icon="image/icon-danger.svg"
    color="primary"
  ></layout-icon>
  <h1 class="title" *ngIf="title.length > 0" [innerHTML]="title"></h1>
  <p class="message" [innerHTML]="message"></p>
</section>
