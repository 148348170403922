import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { AUTH_CONSTANTS } from '@app/core/constants/auth.constants';
import { GLOBAL_CONSTANTS } from '@app/core/constants/global.constants';
import { REDIRECT_BY_STEP } from '@app/core/constants/redirect-by-step.constants';
import { HOME_TYPE, User, USER_STATUS } from '@app/core/models/user.model';
import { UIService } from '@app/core/services/ui.service';
import * as authActions from '../store/auth/auth.actions';
import * as onboardingActions from '../store/onboarding/onboarding.actions';
import { LOAN_CONSTANTS } from '@app/core/constants/loan.constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private _router: Router, private _uiService: UIService, private _store: Store) {}

  public redirectAfterLogin(user: User) {
    const STEPS = AUTH_CONSTANTS.ONBOARDING_STEPS;
    const step = (user?.step ?? 0) + 1;
   
    /**
     * si el susuario esta en el step 5 pero su estado es:
     * LOAD_CUSTOMER -> significa que no ha pasado la validación otp en onboarding.
     * KYC_... -> significa que ya paso la validación de otp y se encuentra en la vista de Mati o posterior
     */
    if(user?.step === STEPS.INCOME && user.status === USER_STATUS.LOAD_CUSTOMER) {
      this.redirectToCorrectFlow(user?.step);
    } else if (step && step >= STEPS.PERSONAL_INFO && (step <= STEPS.INCOME)) {
      this.redirectToCorrectFlow(step);
    } else if (
      user.status === USER_STATUS.KYC_NOT_FOUND ||
      user.status === USER_STATUS.KYC_PENDING ||
      user.status === USER_STATUS.KYC_IN_PROCESS
    ) {
      const stepRoute = REDIRECT_BY_STEP.REDIRECT_BY_STEP[STEPS.MATI];
      this._router.navigateByUrl(stepRoute);
    } else if (user.status === USER_STATUS.DISBURSE_LOAN) {
      this._router.navigateByUrl(GLOBAL_CONSTANTS.ROUTES.DISBURSEMENT);
    } else {
      this._router.navigateByUrl(GLOBAL_CONSTANTS.ROUTES.HOME);
    }
  }

  private redirectToCorrectFlow(toStep: number) {
    const stepRoute = REDIRECT_BY_STEP.REDIRECT_BY_STEP[toStep];
    this._router.navigateByUrl(stepRoute);
  }

  public getHomeType(user: User) {
    if (this.validateUserIsInOnboarding(user, true)) return HOME_TYPE.EMPTY;
    if ((user.score ?? 0) < LOAN_CONSTANTS.MIN_AMOUNT) return HOME_TYPE.REJECTED;

    switch (user.status) {
      case USER_STATUS.KYC_REJECTED:
      case USER_STATUS.SCORE_REJECTED:
      case USER_STATUS.CUSTOMER_REJECTED:
        return HOME_TYPE.REJECTED;
      case USER_STATUS.ACTIVATE_LOAN:
        return HOME_TYPE.ACTIVE_LOAN;
      // TODO: When the user has this status we should redirect to disbursement loan
      // case USER_STATUS.DISBURSE_LOAN:
      case USER_STATUS.DISBURSE_IN_PROCESS:
        return HOME_TYPE.DISBURSEMENT_IN_PROCESS;
      case USER_STATUS.DISBURSE_ERROR:
          return HOME_TYPE.DISBURSEMENT_ERROR;
      case USER_STATUS.REVIEW_LOAN:
        return HOME_TYPE.LOAN_IN_REVIEW;
      case USER_STATUS.CUSTOMER_WITH_SCORE:
        return HOME_TYPE.USER_WITH_SCORE;
    }
    return HOME_TYPE.EMPTY;
  }

  public validateUserIsInOnboarding(user: User, execFn = false) {
    const step = (user?.step ?? 0) + 1;
    const fnCustomerValidating = () => this._store.dispatch(onboardingActions.userIsValidating());
    const KYC_STATUS: any = {
      [USER_STATUS.KYC_NOT_FOUND]: () => {},
      [USER_STATUS.KYC_PENDING]: () => {},
      [USER_STATUS.KYC_MANUAL]: () => {
        this._showDialogVerificationManual();
      },
      [USER_STATUS.KYC_IN_PROCESS]: () => {
        this._store.dispatch(onboardingActions.userIsInKYC());
      },
      [USER_STATUS.KYC_ACCEPTED]: fnCustomerValidating,
      [USER_STATUS.CREDIT_CIRCLE]: fnCustomerValidating,
      [USER_STATUS.SEND_INFORMATION]: fnCustomerValidating,
    };

    if (step && (step <= AUTH_CONSTANTS.ONBOARDING_STEPS.INCOME || user.status === USER_STATUS.LOAD_CUSTOMER) ) {
      return true;
    } else if (Boolean(KYC_STATUS[user.status])) {
      const stepRoute = REDIRECT_BY_STEP.REDIRECT_BY_STEP[AUTH_CONSTANTS.ONBOARDING_STEPS.MATI];
      // this._router.navigateByUrl(stepRoute);
      if (execFn) KYC_STATUS[user.status]();

      return true;
    }
    return false;
  }

  private _showDialogVerificationManual() {
    this._router.navigateByUrl(GLOBAL_CONSTANTS.ROUTES.HOME);
    this._uiService.showDialog({
      title: '¡Tuvimos un problema!',
      message: ` No pudimos validar correctamente tu identidad, pero queremos informarte que tu solicitud se encuentra en revisión por uno de nuestros analistas. <br /> <br /> 
      Nos pondremos en contacto contigo una vez finalice.`,
      type: 'alert',
      infoButton: {
        showButtonClose: false,
        textOk: 'Aceptar y cerrar sesión',
      },
      callbacks: {
        ok: () => {
          this._store.dispatch(authActions.logoutUserStatus());
        },
      },
    });
  }
}
