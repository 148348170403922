import { Injectable } from '@angular/core';
import bankList from '@app/core/constants/bank-list.json';
import { Bank, DisbursementStatus, DisbursementStatusResponse, DISBURSEMENT_STATUS } from '@app/core/models/loan.model';
import { UIService } from '@app/core/services/ui.service';
import * as loanActions from '@store/loan/loan.actions';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { GLOBAL_CONSTANTS } from '@app/core/constants/global.constants';
@Injectable({
  providedIn: 'root',
})
export class LoanService {
  constructor(private _ui: UIService,
              private _store: Store,
              private _router: Router) {}
  
  public getBankList(): Bank[] {
    return bankList;
  }

  public showLoaderDisbursingLoan() {
    this._ui.showDialog({
      title: '',
      message: '¡Tu cuenta esta siendo registrada!',
      type: 'loading',
      infoButton: {
        showButtonOk: false,
        showButtonClose: true,
      },
      callbacks : {
        cancel: () => {
          this._router.navigateByUrl(GLOBAL_CONSTANTS.ROUTES.HOME);
        }
      }
    });
  }
  public showPopUpCongratulationConfirmation() {
    this._ui.showDialog({
      title: '¡Gracias por confiar en nosotros!',
      message: ``,
      type: 'success',
      infoButton: {
        showButtonOk: true,
        showButtonClose: true,
        textOk: 'Cerrar sesión',
      },
      callbacks: {
        ok: () => {
          this._router.navigateByUrl(GLOBAL_CONSTANTS.ROUTES.HOME);
        },
      }
    });
  }

  public validateGetStatusDisbursementFinished( {disburseStatus}: DisbursementStatusResponse) {
    switch(disburseStatus) {
      case DISBURSEMENT_STATUS.PROCESSED:
      case DISBURSEMENT_STATUS.FAILED:
      case DISBURSEMENT_STATUS.RETURNED:
      case DISBURSEMENT_STATUS.CANCELLED:
      case DISBURSEMENT_STATUS.BAD_REQUEST:
      case DISBURSEMENT_STATUS.CANCEL_FAILED:
      case DISBURSEMENT_STATUS.WRONG_PROCESSED:
        this._store.dispatch(loanActions.finishGetStatusDisburseLoan( {disburseStatus} ));
        return true;
      case DISBURSEMENT_STATUS.PENDING:
      case DISBURSEMENT_STATUS.PENDING_PROVIDER:
      case DISBURSEMENT_STATUS.SENT:
        this._store.dispatch(loanActions.finishGetStatusDisburseLoan( {disburseStatus} ));
        return false;
      default:
        return false;
    };
  }

}