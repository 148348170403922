import { PAYMENT_CONSTANTS } from '../../core/constants/payment.constants';
export const URLS = {
  GET_USER: '/customers/',
  GET_BANK_LIST: '/bank-list/',
  SEND_OTP_CODE: '/customers/code/',
  SEND_OTP_CODE_ONBOARDING: '/customers/code/',
  VALIDATE_OTP_CODE: '/customers/code/',
  SIGN_UP: '/customers/sign-up/',
  LOGIN: '/customers/login/',
  ONBOARDING_STEP_1: '/customers/steps/1/',
  ONBOARDING_STEP_2: '/customers/steps/2/',
  ONBOARDING_STEP_3: '/customers/steps/3/',
  ONBOARDING_STEP_4: '/customers/steps/4/',
  ONBOARDING_STEP_5: '/customers/steps/5/',
  ONBOARDING_STEP_6: '/customers/steps/6/',
  FORGOT_SEND_OTP_CODE: '/customers/code/',
  // FORGOT_VALIDATE_OTP_CODE: '/customers/forgot-password/',
  FORGOT_SAVE_NEW_PASSWORD: '/customers/forgot-password/',
  ONBOARDING_IDENTITY_VALIDATION_STATUS: '/kyc/',
  ONBOARDING_CUSTOMER_VALIDATIONS_STATUS: '/customers/validation/',
  LOAN_DYNAMIC_DOCUMENTS: '/customers/documents/',
  ONBOARDING_GET_LOCATION_BY_ZIPCODE: '/zip-code/',
  PAYMENT: '/payments/',
  // Loan URLS
  LOAN_GET_DOCUMENTS: '/customers/documents/',
  LOAN_SIMULATOR: '/loans/simulator/',
  CHANGE_EMAIL: '/customers/email/',
  LOAN_REQUEST: '/loans/',
  LOAN_DISBURSE: '/loans/disbursement/',
  LOAN_DETAILS: '/loans/',
  CONFIRM_SEE_MODAL: '/customers/popup/',
  INTENTION_SIGNUP: '/customers/pre_customer/',
  ACCOUNT_STATUS: '/customers/account_status/',
  PAYMENT_PLAN: '/customers/account_status/',
  CANCEL_TRANSACTION: '/transactions/',
};
